import React from "react";
import ValidationError from "../ValidationError";
import { Field } from "react-final-form";

import "./index.scss";

function SelectField({ meta, options, name }) {
	return (
		<div className="input-field-wrapper">
			<Field name={name} component="select" className="input-field select-field">
				{options.map((option, i) => {
					return (
						<option key={i} value={option.value}>
							{option.text}
						</option>
					);
				})}
			</Field>
			<ValidationError meta={meta} />
		</div>
	);
}

export default SelectField;
