// -----------------------------------------------------------------------------
// Library
// -----------------------------------------------------------------------------
import React, { useState, useEffect } from "react";
import notification from "antd/lib/notification";
import { useSelector } from "react-redux";

// -----------------------------------------------------------------------------
// Store
// -----------------------------------------------------------------------------
import { selectSelectedWorkout } from "store/selectors";

// -----------------------------------------------------------------------------
// Components
// -----------------------------------------------------------------------------
import { Button } from "components/Common/Button";
import { Paragraph2 } from "themes/default/_typography";
import { FormErrorTag } from "components/Common/Form/FormErrorTag";
import ImageUpload from "components/ImageUpload";
import MuxUploader from "@mux/mux-uploader-react";

// -----------------------------------------------------------------------------
// Utils and Styles
// -----------------------------------------------------------------------------
import StyledUploadAssetsForm from "./styles";
import {
	useCreateWorkoutMutation,
	useGetDomainGymQuery,
	useGetGymWorkoutsQuery,
	useGetMuxSignedUrlQuery,
	useGetS3SignedUrlMutation,
	useUploadAssetToS3Mutation,
} from "store/api/api";
import axios from "axios";
import * as UpChunk from "@mux/upchunk";
import { useRecoilValue } from "recoil";
import { userAtom } from "globalAtoms";
import { useParams } from "react-router";
import { useEditWorkoutMutation } from "store/api/api";
import { MUXCreateDirectUploadURL } from "constants/urls";

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
const UploadAssetsForm = ({ nextStep }) => {
	const [videoFile, setVideoFile] = useState(null);
	const [thumbnailFile, setThumbnailFile] = useState(null);
	const [muxDirectUploadUrl, setMuxDirectUploadUrl] = useState("");
	const [muxDirectUploadId, setMuxDirectUploadId] = useState("");
	const user = useRecoilValue(userAtom);
	const { gym } = useParams();
	const domainGymResponse = useGetDomainGymQuery(gym);
	const domainGymId = domainGymResponse.data?.id;
	const getWorkoutsResponse = useGetGymWorkoutsQuery(domainGymId, { skip: !domainGymResponse.data });
	const [getS3SignedUrlTrigger, getS3UrlResponse] = useGetS3SignedUrlMutation();
	const { data: muxSignedUrl, isLoading: muxIsLoading, error: getMuxSignedUrlError } = useGetMuxSignedUrlQuery();
	const [uploadAssetToS3Trigger, uploadAssetToS3Response] = useUploadAssetToS3Mutation();
	const [createWorkoutTrigger, createWorkoutResponse] = useCreateWorkoutMutation();
	const [editWorkoutTrigger, editWorkoutResponse] = useEditWorkoutMutation();
	// Second call to the edit workout mutation to save the upload id since the call above uses the response in use effect
	const [editWorkoutUploadIdTrigger, editWorkoutUploadIdTriggerResponse] = useEditWorkoutMutation();

	const selectedWorkout = useSelector(selectSelectedWorkout);

	const [uploadReady, setUploadReady] = useState(false);

	useEffect(() => {
		if (editWorkoutResponse.data) {
			getWorkoutsResponse.refetch();
			nextStep();
		}
	}, [editWorkoutResponse]);

	// Function to make axios call to the lambda function to get Mux direct upload url
	const getMuxSignedUrl = async () => {
		try {
			// Get signed url from Mux Lambda function
			const muxUploadUrl =
				process.env.REACT_APP_API_ENV === "production" ? MUXCreateDirectUploadURL.prod : MUXCreateDirectUploadURL.dev;
			const { data } = await axios.get(MUXCreateDirectUploadURL.prod);
			setMuxDirectUploadUrl(data.url);
			setMuxDirectUploadId(data.id);
			// Make edit workout call to save the upload id so mux webhook can find and update workout
			editWorkoutUploadId(data.id);
		} catch (error) {
			console.debug("error", error);
		}
	};

	const editWorkoutUploadId = async (uploadId) => {
		try {
			const updatedWorkout = await editWorkoutUploadIdTrigger({
				workoutId: selectedWorkout?.id,
				gym_id: domainGymId,
				upload_id: uploadId,
			}).unwrap();
		} catch (error) {
			console.debug("error", error);
		}
	};

	// Use Effect to generate a signed direct upload url for mux component
	useEffect(() => {
		if (!muxDirectUploadUrl || muxDirectUploadUrl === "") {
			getMuxSignedUrl();
		}
	}, []);

	const onNextClick = async () => {
		if (!videoFile) {
			setVideoFile(undefined);
		}

		if (!thumbnailFile) {
			setThumbnailFile(undefined);
		}
		if (muxDirectUploadId && thumbnailFile) {
			let fullS3ThumbNail = await uploadImageToS3();
			const edited = await editWorkoutTrigger({
				workoutId: selectedWorkout?.id,
				gym_id: domainGymId,
				image_url: `${fullS3ThumbNail}`,
			});
			setThumbnailFile(null);
		}
	};

	const onSaveThumbnail = async (file) => {
		var blobToFile = new File([file], "workoutThumbnail.png", {
			type: "image/png",
		});
		const maxThumbSize = 2 * 1024 * 1024; // 2mb
		if (blobToFile.size > maxThumbSize) {
			notification.error({
				message: "Max Image Size is 2mb",
				description: "Limit the size of your image",
			});
		} else {
			setThumbnailFile(blobToFile);
		}
	};

	const uploadAsset = async (response, thumbnailFile) => {
		const formData = new FormData();

		const headers = {
			"Content-Type": "multipart/form-data",
		};

		for (const field in response.fields) {
			formData.append(field, response.fields[field]);
		}

		formData.append("file", thumbnailFile);

		try {
			const { data } = await axios.post(response.url, formData, { headers });
		} catch (error) {
			console.debug("error", error);
			notification.error({
				message: "Error Uploading Image",
				description: "Please try again",
			});
		}
	};

	const uploadImageToS3 = async () => {
		const mimeMap = {
			"image/jpeg": "jpg",
			"image/png": "png",
		};
		let fileType = mimeMap[thumbnailFile.type];
		let gymId = user?.gym?.id;
		const s3KeyThumbnail = `assets/${gymId}/category-${new Date().getTime()}.${fileType}`;
		const s3SignedResponse = await getS3SignedUrlTrigger({ key: s3KeyThumbnail, type: "image" });
		await uploadAsset(s3SignedResponse.data.response, thumbnailFile);
		const fullS3ThumbNail = `${s3SignedResponse.data.response.url}/${s3KeyThumbnail}`;
		console.log(fullS3ThumbNail);
		return fullS3ThumbNail;
	};

	const muxUploader = document.querySelector("mux-uploader");
	muxUploader?.addEventListener("success", function (e) {
		setUploadReady(true);
	});

	return (
		<StyledUploadAssetsForm>
			<div className="sb-upload-assets-form">
				{muxDirectUploadUrl !== "" && <MuxUploader endpoint={muxDirectUploadUrl} />}
				{muxDirectUploadUrl === "" && <div className="sb-upload-assets-form__upload__input">Loading...</div>}
				<div className="sb-upload-assets-form__upload">
					<ImageUpload
						className="sb-upload-assets-form__upload__input"
						onSave={onSaveThumbnail}
						onCancel={() => setThumbnailFile(null)}
						type="image"
						title="Upload Thumbnail"
						aspectRatio={300 / 180}
					/>
					{thumbnailFile === undefined && <FormErrorTag text="Thumbnail required" />}
				</div>
				{thumbnailFile && uploadReady && (
					<Button
						type="submit"
						onClick={onNextClick}
						className="sb-upload-assets-form__form__button primary-filled"
						saveIcon
						uppercase
					>
						<Paragraph2>Next</Paragraph2>
					</Button>
				)}
			</div>
		</StyledUploadAssetsForm>
	);
};

export default UploadAssetsForm;

