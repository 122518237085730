// -------------------------------------------------------------------------------
// Libraries
// -------------------------------------------------------------------------------
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// -------------------------------------------------------------------------------
// Component, selectors and others
// -------------------------------------------------------------------------------
import { Heading2, Paragraph12 } from "themes/default/_typography";
import { MembershipSelection } from "components/Payments/MembershipSelection";
import { selectSelectedProduct } from "store/selectors";

// -------------------------------------------------------------------------------
// Utils, Styles and Assets
// -------------------------------------------------------------------------------
import StyledMembershipSettingsPage from "./styles";
import axios from "axios";
import { updateSelectedProduct, updateCurrentMembershipDetails } from "store/slices";
import { useGetCurrentUserQuery, useGetDomainGymQuery } from "store/api/api";
import { useRecoilValue } from "recoil";
import { userAtom } from "globalAtoms";
import { paymentLevels, paymentLevelsProd } from "helpers/constants";
import { StripeGetCustomerSubscriptions, StripeGetSubscriptionsForGym } from "constants/urls";

// -------------------------------------------------------------------------------
// Component
// -------------------------------------------------------------------------------
const MembershipSettingsPage = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const selectedProduct = useSelector(selectSelectedProduct);
	const [hasMembership, setHasMembership] = useState(false);
	const user = useRecoilValue(userAtom);
	const currentUserData = useGetCurrentUserQuery({ userId: user.id });
	const { gym } = useParams();
	const { data: domainGymData, isLoading: domainGymisLoading, error: domainGymError } = useGetDomainGymQuery(gym);
	const [gymCurrentSubscription, setGymCurrentSubscription] = useState();

	const [currentPricing, setCurrentPricing] = useState(0);
	const [planDetails, setPlanDetails] = useState();
	const fetchCustomerSubscriptions = async () => {
		try {
			const getCustomerSubscriptionsUrl =
				process.env.REACT_APP_API_ENV === "production"
					? StripeGetCustomerSubscriptions.prod
					: StripeGetCustomerSubscriptions.dev;
			const subscriptionsResponse = await axios.post(getCustomerSubscriptionsUrl, {
				customerId: user.customer_id,
			});
			if (subscriptionsResponse.data.data.length > 0) {
				var data = subscriptionsResponse.data.data[0].items.data[0];
				// data = [
				// 	{
				// 		type: "pro",
				// 		price: "99.00",
				// 	},
				// ];
				setGymCurrentSubscription(data);
				const planDetails =
					process.env.REACT_APP_API_ENV === "production"
						? paymentLevelsProd[data?.plan?.id]
						: paymentLevels[data?.plan?.id];
				setPlanDetails(planDetails);
			}
		} catch (error) {
			console.error("ERROR: ", error);
		}
	};

	const fetchProductForGym = async () => {
		try {
			if (domainGymData?.name) {
				const getSubscriptionsForGymUrl =
					process.env.REACT_APP_API_ENV === "production"
						? StripeGetSubscriptionsForGym.prod
						: StripeGetSubscriptionsForGym.dev;
				const gymProductResponse = await axios.post(getSubscriptionsForGymUrl, {
					// TODO UPDATE TO CURRENT GYM NAME
					gymName: domainGymData.name,
				});
				const data = gymProductResponse.data;
				// TODO Should Fetch Current Product In Stripe
				const product = {
					name: "Pro",
					price: "$99.00",
					type: "pro",
				};

				const currentMembershipDetails = {
					id: data.id,
					product: data.id,
					unit_amount: data.unit_amount,
					type: data.type,
					membersPay: data.unit_amount / 100,
					default_price: data.default_price,
				};
				setCurrentPricing(data.unit_amount);
				dispatch(updateCurrentMembershipDetails({ currentMembershipDetails }));
				dispatch(updateSelectedProduct({ product }));
				setHasMembership(true);
			}
		} catch (error) {
			console.debug("ERROR: ", error);
			return null;
		}
	};

	useEffect(() => {
		if (!hasMembership) {
			fetchCustomerSubscriptions();
			fetchProductForGym();
		}
	}, [navigate, hasMembership, domainGymData]);

	return (
		<StyledMembershipSettingsPage className="sb-membership-settings-page">
			<div className="sb-membership-settings-page__inner">
				<div className="sb-membership-settings-page__inner__heading">
					<Heading2>Membership Settings</Heading2>
					<Paragraph12>
						Membership is what your members pay to access your content. Workouts can be set to free or membership based.
					</Paragraph12>
				</div>
				{selectedProduct && planDetails && (
					<MembershipSelection product={selectedProduct} planDetails={planDetails} currentPricing={currentPricing} />
				)}
				{!selectedProduct && planDetails && <div>Please complete Step 1 and subscribe to a 'SWEATBASE PLAN' first</div>}
				{!selectedProduct && !planDetails && (
					<div>Please complete Step 1 and subscribe to a 'SWEATBASE PLAN' first</div>
				)}
				{selectedProduct && !planDetails && <div>Please complete Step 1 and subscribe to a 'SWEATBASE PLAN' first</div>}
			</div>
		</StyledMembershipSettingsPage>
	);
};

export default MembershipSettingsPage;

