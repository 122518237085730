// -----------------------------------------------------------------------------
// Library
// -----------------------------------------------------------------------------
import React, { useCallback, useState, useEffect } from "react";
import { Form, Switch, Tooltip, notification } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router";
import moment from "moment";
import axios from "axios";
import MuxPlayer from "@mux/mux-player-react";
import MuxUploader from "@mux/mux-uploader-react";
import { UndoOutlined } from "@ant-design/icons";

// -----------------------------------------------------------------------------
// Store
// -----------------------------------------------------------------------------
import { selectAssetUploadId, selectSelectedWorkout } from "store/selectors";
import { uploadAssetToMux, editWorkout } from "store/slices";
import {
	useDeleteWorkoutMutation,
	useEditScheduledStreamMutation,
	useEditWorkoutMutation,
	useGetCategoriesQuery,
	useGetDomainGymQuery,
	useGetGymWorkoutsQuery,
	useGetScheduledStreamsQuery,
} from "store/api/api";

// -----------------------------------------------------------------------------
// Components
// -----------------------------------------------------------------------------
import { Button } from "components/Common/Button";
import { Button as AntButton } from "antd";
import { Heading4, Paragraph2, Span4 } from "themes/default/_typography";
import { FormInput } from "components/Common/Form/FormInput";
import { FormTextArea } from "components/Common/Form/FormTextArea";
import { FormErrorTag } from "components/Common/Form/FormErrorTag";
import { FormTagSelect } from "components/Common/Form/FormTagSelect";
import { GenreSelector } from "components/Common/GenreSelector";
import ImageUpload from "components/ImageUpload";
import { FormDateSelect } from "components/Common/Form/FormDateSelect";
import { FormTimeSelect } from "components/Common/Form/FormTimeSelect";
import { FormSelect } from "components/Common/Form/FormSelect";

// -----------------------------------------------------------------------------
// Utils and Styles
// -----------------------------------------------------------------------------
import { getVideoMetadata } from "helpers/generic";
import { getApiHost } from "helpers/constants";
import ajaxRoutes from "utils/ajaxRoutes";
import Ajax from "utils/api";
import { MUXCreateDirectUploadURL } from "constants/urls";
import StyledEditWorkoutForm from "./styles";

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
const EditWorkoutForm = ({ isEditingLive, liveStream, handleCloseModal }) => {
	const dispatch = useDispatch();
	const [videoFile, setVideoFile] = useState(null);
	const [thumbnailFile, setThumbnailFile] = useState(null);
	const [workoutData, setWorkoutData] = useState(null);
	const [selectedGenre, setSelectedGenre] = useState({});
	const [editingWorkout, setEditingWorkout] = useState(false);
	const [muxDirectUploadUrl, setMuxDirectUploadUrl] = useState(null);
	const [addingNewVideo, setAddingNewVideo] = useState(false);
	const uploadId = useSelector(selectAssetUploadId);
	const selectedWorkout = useSelector(selectSelectedWorkout);
	const [signedMuxPreviewUrl, setSignedMuxPreviewUrl] = useState(null);
	const { gym } = useParams();
	const domainGymResponse = useGetDomainGymQuery(gym);
	const domainGymId = domainGymResponse.data?.id;
	const { data: categoriesData } = useGetCategoriesQuery(domainGymId);
	const getWorkoutsResponse = useGetGymWorkoutsQuery(domainGymId, { skip: !domainGymResponse.data });
	const [editWorkoutTrigger, editWorkoutResponse] = useEditWorkoutMutation();
	const [editWorkoutSaveTrigger, editWorkoutSaveResponse] = useEditWorkoutMutation();
	const [deleteWorkoutTrigger, deleteWorkoutResponse] = useDeleteWorkoutMutation();
	const [editScheduledStreamTrigger] = useEditScheduledStreamMutation();
	const { refetch: refetchStreams } = useGetScheduledStreamsQuery(domainGymId, { skip: !domainGymResponse.data });
	const [cancellingScheduledRelease, setCancellingScheduledRelease] = useState(false);
	const [playbackToken, setPlaybackToken] = useState(null);
	const [thumbnailToken, setThumbnailToken] = useState(null);

	useEffect(() => {
		if (workoutData && editingWorkout && Object.keys(workoutData).length) {
			const dataToSave = { ...workoutData };

			if (workoutData.duration && uploadId) {
				dataToSave.upload_id = uploadId;
			}

			// Edit Workout
			editWorkoutSaveTrigger({ workoutId: selectedWorkout.id, ...dataToSave });
			dispatch(
				editWorkout({
					workoutId: selectedWorkout.id,
					...dataToSave,
				})
			);

			setEditingWorkout(false);
			if (isEditingLive) {
				refetchStreams();
			}
			handleCloseModal();
		}
	}, [handleCloseModal, dispatch, domainGymId, uploadId, editingWorkout, workoutData, selectedWorkout]);

	const onFinish = useCallback(
		async ({
			workoutCoach,
			workoutDescription,
			workoutTitle,
			workoutCategories,
			workoutEquipment,
			workoutIsFree,
			workoutDate,
			workoutTime,
			workoutTimezone,
			workoutDuration,
		}) => {
			// this destructuring crap is impossible to read and follow
			let workoutIsFreeInt = workoutIsFree ? 1 : 0;
			const dataToUpdate = {};

			if (videoFile && videoFile !== selectedWorkout.id) {
				try {
					const { duration } = await getVideoMetadata(videoFile.original);
					dispatch(uploadAssetToMux({ file: videoFile }));

					dataToUpdate.duration = duration;
				} catch (error) {
					console.error("error uploading video to mux", error);
				}
			}

			if (thumbnailFile && thumbnailFile !== selectedWorkout.id) {
				try {
					const s3KeyThumbnail = await uploadImageToS3();
					dataToUpdate.image_url = `https://s3.amazonaws.com/${s3KeyThumbnail}`;
				} catch (error) {
					console.error("error uploading thumbnail to s3", error);
				}
			}

			if (workoutCoach && workoutCoach !== selectedWorkout.coach) {
				dataToUpdate.coach = workoutCoach;
			}

			if (workoutIsFreeInt !== selectedWorkout.is_free) {
				dataToUpdate.is_free = workoutIsFreeInt;
			}

			if (workoutDescription && workoutDescription !== selectedWorkout.description) {
				dataToUpdate.description = workoutDescription;
			}

			if (workoutTitle && workoutTitle !== selectedWorkout.name) {
				dataToUpdate.name = workoutTitle;
			}

			if (workoutCategories) {
				const stringCategories = workoutCategories.join(",");
				const selectedWorkoutCategories = selectedWorkout.categories.map((c) => c.id).join(",");

				if (stringCategories !== selectedWorkoutCategories) {
					dataToUpdate.categories = stringCategories;
				}
			}

			if (workoutEquipment && workoutEquipment !== selectedWorkout.equipment) {
				dataToUpdate.equipment = workoutEquipment;
			}

			if (selectedGenre.id) {
				dataToUpdate.musicGenre = selectedGenre.title;
				dataToUpdate.playlistId = selectedGenre.id;
			}

			if (workoutDate && workoutTime && workoutTimezone && isEditingLive) {
				const day = moment(workoutDate).startOf("day").format("MM/DD/YYYY");
				const time = moment(workoutTime).format("HH:mm");
				const dayTime = moment(day + " " + time, "MM/DD/YYYY HH:mm");
				const iso = dayTime.format("YYYY-MM-DDTHH:mm:ss");
				await editScheduledStreamTrigger({
					workoutId: selectedWorkout.id,
					gym_id: domainGymId,
					start_time: iso,
					time_zone: workoutTimezone,
					currently_streaming: false,
				}).unwrap();
			}

			if (workoutDate && workoutTime && workoutTimezone && selectedWorkout.start_time) {
				const day = moment(workoutDate).startOf("day").format("MM/DD/YYYY");
				const time = moment(workoutTime).format("HH:mm");
				const dayTime = moment(day + " " + time, "MM/DD/YYYY HH:mm");
				const iso = dayTime.format("YYYY-MM-DDTHH:mm:ss");
				dataToUpdate.start_time = iso;
			}

			if (selectedWorkout.start_time && cancellingScheduledRelease) {
				//since we cant set start_time to null, we need to set it to a date in the past
				dataToUpdate.start_time = moment().format("YYYY-MM-DDTHH:mm:ss");
			}

			if (workoutDuration && workoutDuration !== selectedWorkout.duration) {
				dataToUpdate.duration = workoutDuration;
			}

			if (Object.keys(dataToUpdate).length) {
				setWorkoutData(dataToUpdate);
				setEditingWorkout(true);
			}
			//Check if only streaming time has changes, and if so, close Modal and refetch streams
			if (isEditingLive && workoutDate && workoutTime && workoutTimezone) {
				handleCloseModal();
				refetchStreams();
			}
		},
		[dispatch, videoFile, thumbnailFile, domainGymId, selectedWorkout, selectedGenre, cancellingScheduledRelease]
	);

	const uploadImageToS3 = async () => {
		const jwt = localStorage.getItem("creator_jwt_token");
		const apiHost = getApiHost(process.env.REACT_APP_API_ENV);

		const assetsUrl = `${apiHost}/workouts/assetsnew`;

		const formData = new FormData();
		formData.append("image", thumbnailFile);
		formData.append("workout_id", selectedWorkout?.id);
		formData.append("gymId", domainGymId);
		const headers = {
			Authorization: jwt,
			"Content-Type": "multipart/form-data",
		};
		const response = await axios.post(assetsUrl, formData, { headers });
		const location = response.data.workout_asset.location;
		const index = location.lastIndexOf("/");
		const s3KeyThumbnail = location.substring(index + 1);
		setThumbnailFile(null);
		return `${domainGymId}/${selectedWorkout?.id}/${s3KeyThumbnail}`;
	};

	const onApply = useCallback(() => {
		if (!videoFile) {
			setVideoFile(null);
		}
		if (!thumbnailFile) {
			setThumbnailFile(null);
		}
	}, [videoFile, thumbnailFile]);

	const onSaveThumbnail = useCallback((file) => {
		var blobToFile = new File([file], "workoutThumbnail.png", {
			type: "image/png",
		});
		setThumbnailFile(blobToFile);
	}, []);

	const onDeleteWorkout = async () => {
		await deleteWorkoutTrigger({
			workoutID: selectedWorkout.id,
			gymID: selectedWorkout.gym_id,
		}).unwrap();

		handleCloseModal();
	};

	const onCancelScheduledReleased = async () => {
		setCancellingScheduledRelease(true);
	};

	const handleEditWorkoutActive = async (active) => {
		try {
			await editWorkoutTrigger({
				workoutId: selectedWorkout.id,
				gym_id: selectedWorkout.gym_id,
				draft: active ? 0 : 1,
			}).unwrap();
		} catch (error) {
			console.error("handleEditWorkoutActive error: ", error);
		}
	};

	useEffect(() => {
		if (deleteWorkoutResponse.isSuccess) {
			getWorkoutsResponse.refetch();
		}
	}, [deleteWorkoutResponse]);

	useEffect(() => {
		if (editWorkoutSaveResponse.isSuccess) {
			getWorkoutsResponse.refetch();
			notification.success({
				message: "Workout updated",
			});
			handleCloseModal();
		}
	}, [editWorkoutSaveResponse]);

	useEffect(() => {
		if (selectedWorkout?.playback_id && selectedWorkout?.playback_id !== "null") {
			console.log("selectedWorkout?.playback_id: ", selectedWorkout?.playback_id);
			setPreviewVideo(selectedWorkout?.playback_id);
		}
	}, [selectedWorkout]);

	const setPreviewVideo = async (playbackId) => {
		try {
			const videoUrlResponse = await Ajax.call(ajaxRoutes.GET_MUX_VIDEO_URL(playbackId));
			const { url, token, thumbnailToken } = videoUrlResponse;
			setPlaybackToken(token);
			setThumbnailToken(thumbnailToken);
			setSignedMuxPreviewUrl(url);
		} catch (error) {
			console.error("error: ", error);
		}
	};

	const getMuxSignedUrl = async () => {
		try {
			// Get signed url from Mux Lambda function
			const muxUploadUrl =
				process.env.REACT_APP_API_ENV === "production" ? MUXCreateDirectUploadURL.prod : MUXCreateDirectUploadURL.dev;
			const { data } = await axios.get(MUXCreateDirectUploadURL.prod);
			setMuxDirectUploadUrl(data.url);
			// Make edit workout call to save the upload id so mux webhook can find and update workout
			editWorkoutUploadId(data.id);
		} catch (error) {
			console.error("error", error);
		}
	};

	const editWorkoutUploadId = async (uploadId) => {
		try {
			await editWorkoutTrigger({
				workoutId: selectedWorkout.id,
				gym_id: domainGymId,
				upload_id: uploadId,
			}).unwrap();
		} catch (error) {
			console.error("error", error);
		}
	};

	const handleClickNewVideo = () => {
		setAddingNewVideo(true);
		getMuxSignedUrl();
	};

	return (
		<StyledEditWorkoutForm>
			{selectedWorkout?.id && (
				<div className="sb-edit-workout-form">
					{!isEditingLive && (
						<div className="sb-edit-workout-form__upload">
							{/*
						Should make this a mux uploader component that loads if there is no video or if user selects something like "change video"
						*/}
							{signedMuxPreviewUrl && !addingNewVideo && (
								<MuxPlayer
									streamType="on-demand"
									playbackId={selectedWorkout?.playback_id}
									metadata={{
										video_id: selectedWorkout?.playback_id,
										video_title: selectedWorkout.name || "No Title Found",
										viewer_user_id: "Trainer Edit",
									}}
									tokens={{
										playback: playbackToken,
										thumbnail: thumbnailToken,
									}}
								/>
							)}
							{!addingNewVideo && (
								<div style={{ display: "flex", justifyContent: "center" }}>
									<Button autoRenewIcon className="primary-outlined--delete" uppercase onClick={handleClickNewVideo}>
										<Paragraph2>Change Workout Video</Paragraph2>
									</Button>
								</div>
							)}
							{muxDirectUploadUrl && addingNewVideo && <MuxUploader endpoint={muxDirectUploadUrl} />}
							{videoFile === undefined && <FormErrorTag text="This field is required" />}
						</div>
					)}
					{!isEditingLive && (
						<div className="sb-edit-workout-form__upload">
							<ImageUpload
								className="sb-edit-workout-form__upload__input"
								onSave={onSaveThumbnail}
								onCancel={() => setThumbnailFile(null)}
								type="image"
								title="Upload Thumbnail"
								preImage={selectedWorkout.image_url}
								aspectRatio={300 / 180}
							/>
							{thumbnailFile === undefined && <FormErrorTag text="This field is required" />}
						</div>
					)}
					<div className="sb-edit-workout-form__form__genres" style={{ paddingBottom: "8px" }}>
						<Heading4>Active:</Heading4>
						<Switch
							disabled={selectedWorkout.duration === 0}
							defaultChecked={selectedWorkout?.draft === 0}
							onChange={(checked) => handleEditWorkoutActive(checked)}
						/>{" "}
					</div>
					<Form
						onKeyPress={(e) => {
							if (e.key === "Enter") {
								//Makes sure text field can still add new lines
								if (e.target.type !== "textarea") {
									e.preventDefault();
								}
							}
						}}
						onFinish={onFinish}
						layout="vertical"
						className="sb-edit-workout-form__form"
						initialValues={{
							workoutTitle: selectedWorkout.name,
							workoutDescription: selectedWorkout.description,
							workoutCoach: selectedWorkout.coach,
							workoutIsFree: selectedWorkout.is_free ? true : false,
							workoutCategories: selectedWorkout.categories
								? selectedWorkout.categories.map((c) => c.name.toString())
								: [],
							workoutEquipment: selectedWorkout.equipment,
							workoutDate: selectedWorkout.start_time ? moment(selectedWorkout.start_time) : null,
							workoutTime: selectedWorkout.start_time ? moment(selectedWorkout.start_time) : null,
							workoutTimezone:
								isEditingLive && liveStream
									? liveStream.time_zone
									: selectedWorkout.start_time
									? "America/Chicago"
									: null,
							workoutDuration: isEditingLive && liveStream ? selectedWorkout.duration : null,
						}}
					>
						<FormInput
							name="workoutTitle"
							rules={[{ required: true, message: "This field is required" }]}
							label="Workout Title"
						/>
						<FormTextArea
							name="workoutDescription"
							label="Workout Description"
							rules={[{ required: true, message: "This field is required" }]}
							rows={4}
						/>
						<FormInput
							name="workoutCoach"
							rules={[{ required: true, message: "This field is required" }]}
							label="Workout Coach"
						/>
						{categoriesData?.categories?.length > 0 && (
							<FormTagSelect
								name="workoutCategories"
								label="Workout Categories"
								placeholder="Enter Categories"
								options={categoriesData?.categories?.map((cat) => ({
									value: cat.name,
									id: cat.id,
								}))}
							/>
						)}
						<FormInput
							name="workoutEquipment"
							label={
								<span className="sb-workout-details-form__form__field__label">
									<span className="sb-workout-details-form__form__field__label__main">Equipment</span>
									<Span4 className="sb-workout-details-form__form__field__label__extra">(Separate With Commas)</Span4>
								</span>
							}
						/>
						{!isEditingLive && selectedWorkout.start_time && moment(selectedWorkout.start_time).isAfter(moment()) && (
							<Span4 className="sb-workout-details-form__form__field__label__extra">Scheduled For Release</Span4>
						)}
						{(isEditingLive ||
							(selectedWorkout.start_time && moment(selectedWorkout.start_time).isAfter(moment()))) && (
							<div style={{ display: "flex", width: "100%", gap: "8px" }}>
								<FormDateSelect
									name="workoutDate"
									rules={[{ required: true, message: "This field is required" }]}
									label="Workout Date"
									disabled={cancellingScheduledRelease}
								/>
								<FormTimeSelect
									disabled={cancellingScheduledRelease}
									name="workoutTime"
									rules={[{ required: true, message: "This field is required" }]}
									label="Time"
								/>{" "}
								<FormSelect
									disabled={cancellingScheduledRelease}
									name="workoutTimezone"
									placeholder="PST"
									rules={[{ required: true, message: "This field is required" }]}
									label="Timezone"
									options={[
										{ value: "America/New_York", label: "EST (Eastern)" },
										{ value: "America/Los_Angeles", label: "PST (Pacific)" },
										{ value: "America/Chicago", label: "CST (Central)" },
									]}
								/>
								{isEditingLive && (
									<FormTagSelect
										name="workoutDuration"
										placeholder="Duration"
										rules={[{ required: true, message: "This field is required" }]}
										label="Duration"
										postfix=" Minutes"
										options={[
											{
												value: 30,
												id: 30,
											},
											{
												value: 45,
												id: 45,
											},
											{
												value: 60,
												id: 60,
											},
											{
												value: 90,
												id: 90,
											},
											{
												value: 120,
												id: 120,
											},
										]}
									/>
								)}
							</div>
						)}
						{!isEditingLive && selectedWorkout.start_time && moment(selectedWorkout.start_time).isAfter(moment()) && (
							<div style={{ width: "100%" }}>
								{!cancellingScheduledRelease && (
									<Tooltip placement="right" title="Your workout's active status will be applied immediately">
										<Button className="primary-outlined--delete" uppercase onClick={onCancelScheduledReleased}>
											<Paragraph2>Cancel Scheduled Release</Paragraph2>
										</Button>
									</Tooltip>
								)}
								{cancellingScheduledRelease && (
									<div style={{ gap: "16px", display: "flex", alignItems: "center" }}>
										<Span4>Your workout will be updated once changes are applied</Span4>
										<Tooltip title="Undo Schedule Cancellation">
											<AntButton
												onClick={() => setCancellingScheduledRelease(false)}
												shape="circle"
												icon={<UndoOutlined />}
											/>
										</Tooltip>
									</div>
								)}
							</div>
						)}
						<div className="sb-edit-workout-form__form__genres">
							<div className="sb-edit-workout-form__form__genres__title">
								<Heading4>Choose a Playlist</Heading4>
							</div>
							<GenreSelector setSelectedGenre={setSelectedGenre} />
						</div>
						<div className="sb-edit-workout-form__form__actions">
							<Button className="primary-outlined--delete" uppercase onClick={onDeleteWorkout}>
								<Paragraph2>Delete Workout</Paragraph2>
							</Button>
							<Button type="submit" className="primary-filled" saveIcon uppercase onClick={onApply}>
								<Paragraph2>Apply Changes</Paragraph2>
							</Button>
						</div>
					</Form>
				</div>
			)}
		</StyledEditWorkoutForm>
	);
};

export default EditWorkoutForm;

