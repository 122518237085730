import React, { useState, useEffect, useCallback, useRef } from "react";
import { useSetRecoilState } from "recoil";
import Modal from "antd/lib/modal";
import Input from "antd/lib/input";
import { Button } from "components/Common/Button";
import { Paragraph3 } from "themes/default/_typography";
import { useDispatch, useSelector } from "react-redux";
import { CloseIcon } from "components/Icons";

import { useParams } from "react-router";
import { selectImageModalOpen, selectImageCropAspectRatio } from "store/selectors";
import { updateImageModalOpen, updateImageCropAspectRatio } from "store/slices";

import { Cropper } from "react-cropper";
import "cropperjs/dist/cropper.css";

const CropModal = (props) => {
	const { localFile, onSave, onCancel, updateFileAddedLocally } = props;
	const cropperRef = useRef(null);
	const [croppedBlob, setCroppedBlob] = useState(null);
	const onCrop = () => {
		const imageElement = cropperRef?.current;
		const cropper = imageElement?.cropper;
		const canvas = cropper.getCroppedCanvas();
		canvas.toBlob((blob) => {
			if (blob === null) {
				return;
			} else {
				setCroppedBlob(blob);
			}
		}, "image/jpeg");
	};
	const dispatch = useDispatch();
	const open = useSelector(selectImageModalOpen);
	const aspectRatio = useSelector(selectImageCropAspectRatio);

	const handleCancel = () => {
		//Should dispatch action to close modal
		dispatch(updateImageModalOpen(false));
		dispatch(updateImageCropAspectRatio(null));
		if (onCancel) {
			onCancel();
		}
	};

	const handleSave = () => {
		const preview = URL.createObjectURL(croppedBlob);
		updateFileAddedLocally(preview);
		onSave(croppedBlob);
		dispatch(updateImageModalOpen(false));
	};

	const handleAfterClose = () => {
		// TODO: Reset cropper and clear image etc
	};

	return (
		<Modal
			afterClose={handleAfterClose}
			destroyOnClose={true}
			className="sb-modal"
			title="Crop Your Image"
			visible={open}
			closeIcon={<CloseIcon data-cy="close-category-modal" />}
			maskClosable={false}
			onCancel={handleCancel}
			footer={[
				<div key="create" className="sb-create-category-modal__footer">
					<Button className="primary-outlined primary-outlined--delete" onClick={handleCancel}>
						<Paragraph3>Cancel</Paragraph3>
					</Button>

					<Button className="primary-filled" onClick={handleSave}>
						Save
					</Button>
				</div>,
			]}
		>
			<div className="sb-create-category-modal" data-cy="category-modal">
				<Cropper
					src={localFile}
					crop={onCrop}
					ref={cropperRef}
					aspectRatio={aspectRatio}
					style={{
						height: 300,
						width: "100%",
						backgroundColor: "white",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				/>
			</div>
		</Modal>
	);
};

export default CropModal;

