import React from "react";
import { PlayCircleFilled } from "@ant-design/icons";

import "./index.scss";
import { useGetDomainGymQuery, useGetGymBrandingQuery } from "store/api/api";
import { useParams } from "react-router";

function VideoPreviewContainer({ record }) {
	let { gym } = useParams();
	const { data: gymData } = useGetDomainGymQuery(gym);
	const replacementString =
		process.env.REACT_APP_API_ENV === "production" ? "cdn.sweatbase.com" : "sweatbase-staging.s3.amazonaws.com/assets";

	const renderVideoPreview = () => {
		if (record && record.image_url) {
			return (
				<div
					style={{
						backgroundImage: `url('${record.image_url.replace("cdn.sweatbase.com", replacementString)}')`,
						backgroundSize: "cover",
					}}
					className="video-preview-container"
				>
					<PlayCircleFilled />
				</div>
			);
		} else if (gymData && gymData.light_logo) {
			return (
				<div
					style={{
						backgroundImage: `url('${gymData?.light_logo}')`,
						backgroundSize: "cover",
						backgroundPosition: "center",
					}}
					className="video-preview-container"
				>
					<PlayCircleFilled />
				</div>
			);
		} else if (gymData && gymData.dark_logo) {
			return (
				<div
					style={{
						backgroundImage: `url('${gymData?.dark_logo}')`,
						backgroundSize: "cover",
						backgroundPosition: "center",
					}}
					className="video-preview-container"
				>
					<PlayCircleFilled />
				</div>
			);
		} else {
			return (
				<div
					style={{
						backgroundSize: "cover",
						backgroundPosition: "center",
					}}
					className="video-preview-container"
				>
					<PlayCircleFilled />
				</div>
			);
		}
	};

	return renderVideoPreview();
}

export default VideoPreviewContainer;
