// -----------------------------------------------------------------------------
// Libraries
// -----------------------------------------------------------------------------
import React, { useCallback, useState } from "react";
import Modal from "antd/lib/modal";

// -----------------------------------------------------------------------------
// Store
// -----------------------------------------------------------------------------

// -----------------------------------------------------------------------------
// Components
// -----------------------------------------------------------------------------
import { ScheduleLiveForm } from "components/Forms/Workout/ScheduleLiveForm";
import { WorkoutDetailsForm } from "components/Forms/Workout/WorkoutDetailsForm";
import { WorkoutMusicForm } from "components/Forms/Workout/WorkoutMusicForm";
import { ReleaseScheduleForm } from "components/Forms/Workout/ReleaseScheduleForm";
import { CloseIcon } from "components/Icons";
import { Steps } from "components/Common/Steps";
import { Heading10, Paragraph12 } from "themes/default/_typography";

// -----------------------------------------------------------------------------
// Styles and utils
// -----------------------------------------------------------------------------
import StyledScheduleLiveModal from "./styles";

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
const ScheduleLiveModal = ({ visible, setVisible }) => {
	const [currentStep, setCurrentStep] = useState(1);

	const handleCloseModal = useCallback(() => {
		setVisible(false);
		setCurrentStep(1);
	}, [setVisible]);

	const nextStep = useCallback(() => {
		if (currentStep <= 3) {
			setCurrentStep(currentStep + 1);
		} else {
			setCurrentStep(1);
			setVisible(false);
		}
	}, [setVisible, currentStep]);

	return (
		<Modal
			width={656}
			closeIcon={<CloseIcon data-cy="close-schedule-live-modal" />}
			visible={visible}
			onCancel={handleCloseModal}
			footer={null}
		>
			<StyledScheduleLiveModal data-cy="schedule-live-modal" className="sb-schedule-live-modal">
				<div className="sb-schedule-live-modal__header">
					<Steps
						className="sb-schedule-live-modal__header__steps"
						steps={[
							{ value: "", active: currentStep === 1 },
							{ value: "", active: currentStep === 2 },
							{ value: "", active: currentStep === 3 },
							{ value: "", active: currentStep === 4 },
						]}
					/>
					{currentStep === 1 && (
						<>
							<Heading10 className="sb-schedule-live-modal__header__title">Schedule Live Workout</Heading10>
							<Paragraph12>Fill out the information below to create a new workout.</Paragraph12>
						</>
					)}
					{currentStep === 2 && (
						<>
							<Heading10 className="sb-schedule-live-modal__header__title">Fill in the Details</Heading10>
							<Paragraph12>Add some extra details to help us organize your workout.</Paragraph12>
						</>
					)}
					{currentStep === 3 && (
						<>
							<Heading10 className="sb-schedule-live-modal__header__title">Select Playlist</Heading10>
							<Paragraph12>Spice up your video with our unique music mixes.</Paragraph12>
						</>
					)}
					{currentStep === 4 && (
						<>
							<Heading10 className="sb-schedule-live-modal__header__title">Finalize Release Schedule</Heading10>
							<Paragraph12>Select date and time for your live class</Paragraph12>
						</>
					)}
				</div>
				{currentStep === 1 && <ScheduleLiveForm nextStep={nextStep} />}
				{currentStep === 2 && <WorkoutDetailsForm nextStep={nextStep} live={true} />}
				{currentStep === 3 && <WorkoutMusicForm nextStep={nextStep} live={true} />}
				{currentStep === 4 && <ReleaseScheduleForm nextStep={nextStep} />}
			</StyledScheduleLiveModal>
		</Modal>
	);
};

export default ScheduleLiveModal;

