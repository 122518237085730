// -----------------------------------------------------------------------------
// Library
// -----------------------------------------------------------------------------
import React, { useEffect } from "react";
import { Layout } from "antd";

// -----------------------------------------------------------------------------
// Components and helpers

const { Footer } = Layout;

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------

const Privacy = (props) => {
	//Set Page Title
	useEffect(() => {
		// Update the document title using the browser API
		document.title = `SWEATBASE | Privacy`;
	});

	const renderPrivacyPage = () => {
		return (
			<>
				<div className="sb-landing" style={{ height: "100vh" }}>
					<iframe src={"/privacy.html"} width="100%" height="100%"></iframe>
				</div>
				<Footer className="sb-landing__footer">
					<a class="privacy-link" href="https://www.sweatbase.com/privacy.html" target="_blank" rel="noreferrer">
						Privacy
					</a>
					<a class="terms-link" href="https://www.sweatbase.com/tos.html" target="_blank" rel="noreferrer">
						Terms
					</a>
				</Footer>
			</>
		);
	};

	return renderPrivacyPage();
};

export default Privacy;

/*
<div>
						<h1>PRIVACY NOTICE</h1>
						<p>Last updated November 02, 2021</p>

						<p>
							Thank you for choosing to be part of our community at Social Study Media ("Company," "we," "us," or
							"our"). We are committed to protecting your personal information and your right to privacy. If you have
							any questions or concerns about this privacy notice or our practices with regard to your personal
							information, please contact us at team@fitradio.com.
						</p>

						<p>This privacy notice describes how we might use your information if you:</p>
						<ul>
							<li>Visit our website at http://fitradio.com</li>
							<li>Download and use our mobile application — FIT RADIO</li>
							<li>Engage with us in other related ways ― including any sales, marketing, or events</li>
						</ul>

						<p>In this privacy notice, if we refer to:</p>
						<ul>
							<li>"Website," we are referring to any website of ours that references or links to this policy</li>
							<li>
								"App," we are referring to any application of ours that references or links to this policy, including
								any listed above
							</li>
							<li>
								"Services," we are referring to our Website, App, and other related services, including any sales,
								marketing, or events
							</li>
						</ul>

						<p>
							The purpose of this privacy notice is to explain to you in the clearest way possible what information we
							collect, how we use it, and what rights you have in relation to it. If there are any terms in this privacy
							notice that you do not agree with, please discontinue use of our Services immediately.
						</p>

						<p>
							Please read this privacy notice carefully, as it will help you understand what we do with the information
							that we collect.
						</p>

						{/* Insert Table of Contents and subsequent sections similarly formatted }
						<h2>TABLE OF CONTENTS</h2>
						<ul>
							<li>1. WHAT INFORMATION DO WE COLLECT?</li>
							<li>2. HOW DO WE USE YOUR INFORMATION?</li>
							<li>3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</li>
							<li>4. WHO WILL YOUR INFORMATION BE SHARED WITH?</li>
							<li>5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</li>
							<li>6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</li>
							<li>7. HOW LONG DO WE KEEP YOUR INFORMATION?</li>
							<li>8. HOW DO WE KEEP YOUR INFORMATION SAFE?</li>
							<li>9. WHAT ARE YOUR PRIVACY RIGHTS?</li>
							<li>10. CONTROLS FOR DO-NOT-TRACK FEATURES</li>
							<li>11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</li>
							<li>12. DO WE MAKE UPDATES TO THIS NOTICE?</li>
							<li>13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</li>
							<li>14. HOW CAN YOU REVIEW, UPDATE OR DELETE THE DATA WE COLLECT FROM YOU?</li>
						</ul>

						<h1>1. WHAT INFORMATION DO WE COLLECT?</h1>
						<h2>Personal information you disclose to us</h2>
						<p>In Short: We collect personal information you disclose to us</p>
						<p>
							We collect personal information that you voluntarily provide to us when you register on the Services,
							express an interest in obtaining information about us or our products and Services, when you participate
							in activities on the Services or otherwise when you contact us.
						</p>
						<p>
							Personal Information Provided by You. We collect names; phone numbers; email addresses; passwords;
							usernames; and other similar information.
						</p>
						<p>
							Payment Data. We may collect data necessary to process your payment if you make purchases, such as your
							payment instrument number (such as a credit card number), and the security code associated with your
							payment instrument. All payment data is stored by STRIPE. You may find their privacy notice link(s) here:
							https://stripe.com/privacy.
						</p>
						<p>
							Social Media Login Data. We may provide you with the option to register with us using your existing social
							media account details, like your Facebook, Twitter or other social media account. If you choose to
							register in this way, we will collect the information described in the section called "HOW DO WE HANDLE
							YOUR SOCIAL LOGINS?" below.
						</p>
						<p>
							All personal information that you provide to us must be true, complete and accurate, and you must notify
							us of any changes to such personal information.
						</p>
						<h2>Information automatically collected</h2>
						<p>
							In Short: Some information — such as your Internet Protocol (IP) address and/or browser and device
							characteristics — is collected automatically when you visit our Services.
						</p>
						<p>
							We automatically collect certain information when you visit, use or navigate the Services. This
							information does not reveal your specific identity (like your name or contact information) but may include
							device and usage information, such as your IP address, browser and device characteristics, operating
							system, language preferences, referring URLs, device name, country, location, information about how and
							when you use our Services and other technical information. This information is primarily needed to
							maintain the security and operation of our Services, and for our internal analytics and reporting
							purposes.
						</p>
						<p>Like many businesses, we also collect information through cookies and similar technologies.</p>

						<p>The information we collect includes:</p>
						<ul>
							<li>
								Log and Usage Data. Log and usage data is service-related, diagnostic, usage and performance information
								our servers automatically collect when you access or use our Services and which we record in log files.
								Depending on how you interact with us, this log data may include your IP address, device information,
								browser type and settings and information about your activity in the Services (such as the date/time
								stamps associated with your usage, pages and files viewed, searches and other actions you take such as
								which features you use), device event information (such as system activity, error reports (sometimes
								called 'crash dumps') and hardware settings).
							</li>
							<li>
								Device Data. We collect device data such as information about your computer, phone, tablet or other
								device you use to access the Services. Depending on the device used, this device data may include
								information such as your IP address (or proxy server), device and application identification numbers,
								location, browser type, hardware model Internet service provider and/or mobile carrier, operating system
								and system configuration information.
							</li>
							<li>
								Location Data. We collect location data such as information about your device's location, which can be
								either precise or imprecise. How much information we collect depends on the type and settings of the
								device you use to access the Services. For example, we may use GPS and other technologies to collect
								geolocation data that tells us your current location (based on your IP address). You can opt out of
								allowing us to collect this information either by refusing access to the information or by disabling
								your Location setting on your device. Note however, if you choose to opt out, you may not be able to use
								certain aspects of the Services.
							</li>
						</ul>

						<h2>Information collected from through our App</h2>
						<p>
							In Short: We collect information regarding your geolocation, mobile device, push notifications, when you
							use our App.
						</p>
						<p>If you use our App, we also collect the following information:</p>
						{/* Content for each section}

						<h2>2. HOW DO WE USE YOUR INFORMATION?</h2>

						{/* And so on for each section }

						{/* Ensure to properly format and include all sections for completeness }
					</div>
*/

