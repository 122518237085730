// -------------------------------------------------------------------------------
// Libraries
// -------------------------------------------------------------------------------
import React from "react";
import { Select, Form, Tooltip } from "antd";
import { DatePicker } from "antd";
import dayjs from "dayjs";

// -------------------------------------------------------------------------------
// Utils, Styles and Assets
// -------------------------------------------------------------------------------
import StyledFormDateSelect from "./styles";
import { ClockIcon } from "components/Icons/ClockIcon";
import { InfoCircleOutlined } from "@ant-design/icons";

// -------------------------------------------------------------------------------
// Component
// -------------------------------------------------------------------------------

const FormDateSelect = ({ name, label, placeholder, rules, options, className, disabled }) => {
	const { Option } = Select;
	const dateFormat = "ddd, MMM D";
	return (
		<StyledFormDateSelect className={`${className} sb-form-select`}>
			<Form.Item name={name} label={label} rules={rules}>
				<DatePicker
					style={{ width: "100%", height: "46px", borderRadius: "100px" }}
					format={dateFormat}
					disabled={disabled}
				/>
			</Form.Item>
		</StyledFormDateSelect>
	);
};

export default FormDateSelect;

