import styled from "styled-components";

const StyledMembers = styled.div`
	padding: 40px;

	.sb-members-page {
		&__subscribers {
			&__heading {
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin-bottom: 20px;

				&__date-filter {
					.ant-picker-input {
						&:first-child {
							input {
								text-align: right;
							}
						}
					}
				}
			}

			&__charts {
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 40px;

				&__donut {
					width: 50%;
				}

				&__stacked-bar-chart {
					width: 50%;
				}
			}

			&__table {
				position: relative;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-top: 40px;
			}
		}
	}
`;

export default StyledMembers;
