import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Ajax from "utils/api";
import ajaxRoutes from "utils/ajaxRoutes.js";
import LandingPage from "components/Microsites/LandingExample/Landing";
import { LoadingMask } from "components/Common/LoadingMask";

function Landing(props) {
	const { gym } = useParams();
	const [gymId, setGymId] = useState(null);

	useEffect(() => {
		async function getDomain() {
			if (gym) {
				const res = await Ajax.call(ajaxRoutes.DOMAINS_LOOKUP(gym));

				setGymId(res.id);
			} else {
				const res = await Ajax.call(ajaxRoutes.DOMAINS_LOOKUP(window.location.hostname));

				setGymId(res.id);
			}
		}
		getDomain();
	}, []);

	if (gymId) {
		return (
			<div>
				<LandingPage gymId={gymId} preview={props?.preview} />
			</div>
		);
	} else {
		return <LoadingMask />;
	}
}

export default Landing;
