// -----------------------------------------------------------------------------
// Library
// -----------------------------------------------------------------------------
import React from "react";
import { useSetRecoilState } from "recoil";
import Card from "antd/lib/card";

// -----------------------------------------------------------------------------
// Components and helpers
// -----------------------------------------------------------------------------
import { landingPageImageAtom, signUpPageImageAtom } from "./atoms";
import ImageUpload from "../../../components/ImageUpload";
import returnIfNotNull from "../../../utils/checkNull";

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
const HeaderImage = ({ type, user, pageData }) => {
	const setLandingPageImage = useSetRecoilState(landingPageImageAtom);
	const setSignUpPageImage = useSetRecoilState(signUpPageImageAtom);

	const onSave = (fileRead) => {
		if (fileRead?.file) {
			if (type === "landing") {
				setLandingPageImage(fileRead);
			}

			if (type === "sign_up") {
				setSignUpPageImage(fileRead);
			}
		} else {
			if (type === "landing") {
				var landingBlobToFile = new File([fileRead], "landing.png", {
					type: "image/png",
				});
				setLandingPageImage(landingBlobToFile);
			}

			if (type === "sign_up") {
				var signUpBlobToFile = new File([fileRead], "signup.png", { type: "image/png" });
				setSignUpPageImage(signUpBlobToFile);
			}
		}
	};

	const onCancel = () => {
		if (type === "landing") return setLandingPageImage(null);
		if (type === "sign_up") return setSignUpPageImage(null);
	};

	return (
		<Card className="sb-members-page-form__card" hoverable>
			<h1 className="sb-members-page-form__card__title">Header Image</h1>
			<p className="sb-members-page-form__card__subtitle">
				Upload a <b>PNG</b> or <b>JPEG</b> | Recommended 1900x1080 px | Max 4 MB
			</p>
			<div className="sb-members-page-form__card__upload">
				<ImageUpload
					type="image"
					title={`Upload ${type === "landing" ? "Landing" : "Signup"} Page Image`}
					dimensions={type === "landing" ? { height: "150px", width: "375px" } : { height: "320px", width: "180px" }}
					preImage={returnIfNotNull(pageData?.header_image)}
					noLoader={true}
					onCancel={onCancel}
					onSave={onSave}
					aspectRatio={type === "landing" ? 25 / 10 : 9 / 16}
				/>
			</div>
		</Card>
	);
};

export default HeaderImage;
