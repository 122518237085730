// -----------------------------------------------------------------------------
// Importing libraries
// -----------------------------------------------------------------------------
import { create } from "apisauce";

// -----------------------------------------------------------------------------
// Importing helpers
// -----------------------------------------------------------------------------
import { getStringFromLocalStorage } from "helpers/localStorage";
import { getApiHost } from "helpers/constants";

// -----------------------------------------------------------------------------
// Creating the base api object with the needed headers and url
// -----------------------------------------------------------------------------
export default create({
	baseURL: getApiHost(process.env.REACT_APP_API_ENV),
	headers: { Authorization: getStringFromLocalStorage("creator_jwt_token") },
	transformRequest: [
		(data) => {
			if (data instanceof FormData) return data;
			return JSON.stringify(data);
		},
	],
});
