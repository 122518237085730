// -----------------------------------------------------------------------------
// Library
// -----------------------------------------------------------------------------
import React from "react";
import { Card } from "antd";
import { useDispatch } from "react-redux";

// -----------------------------------------------------------------------------
// Store
// -----------------------------------------------------------------------------
import { getOneTimeLoginLink } from "store/slices";
import { selectCreatorAccount, selectOneTimeLoginLink } from "store/selectors";

// -----------------------------------------------------------------------------
// Components
// -----------------------------------------------------------------------------
// import { Paragraph26, Heading12, Paragraph27, Paragraph28 } from "themes/default/_typography";
import { Button } from "components/Common/Button";
// import { ChangeIndicator } from "components/Common/ChangeIndicator";

// -----------------------------------------------------------------------------
// Utils and Styles
// -----------------------------------------------------------------------------
import StyledEarningsCard from "./styles";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useGetCurrentUserQuery } from "store/api/api";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useRecoilValue } from "recoil";
import { userAtom } from "globalAtoms";

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
const EarningsCard = (props) => {
	const navigate = useNavigate();
	return (
		<StyledEarningsCard>
			<Card className="sb-earnings-card">
				{/* <div className="sb-earnings-card__top-section">
					<div className="sb-earnings-card__top-section__left">
						<div className="sb-earnings-card__top-section__left__card">
							<Heading12>$1,221</Heading12>
							<Paragraph27>EARNINGS</Paragraph27>
						</div>
					</div>
					<div className="sb-earnings-card__top-section__right">
						<ChangeIndicator value="+32.84%" possitive={true} />
						<Paragraph28>$982 last month</Paragraph28>
					</div>
				</div>
				<div className="sb-earnings-card__bottom-section">
					<Paragraph26>$1,150.28 is scheduled to be withdrawn to your account on 12/05/2020</Paragraph26>
					<Button chevronRight className="primary-outlined" uppercase onClick={testingFunc}>
						View on Stripe
					</Button>
				</div> */}
				<div className="sb-earnings-card__bottom-section">
					<h1>Your Lifetime Earnings: ${(props.totalEarnings / 100).toFixed(2)}</h1>
					{/* <Button onClick={() => navigate("../membership-settings")}>Edit Pricing For Members</Button> */}

					{/* <Link to="/dashboard/membership-settings">Edit Pricing For Members</Link> */}
				</div>
				<div className="sb-earnings-card__bottom-section">
					<h1>Your Pending Balance: ${(props.pendingBalance / 100).toFixed(2)}</h1>
					{/* 					<Button onClick={() => navigate("../payment-settings")}>Edit Stripe Account & Payment Settings</Button>
					 */}{" "}
					{/* <Link to="/dashboard/payment-settings"></Link> */}
				</div>
				{/* <Button chevronRight className="primary-outlined" uppercase onClick={testingFunc}>
					Testing
				</Button> */}
			</Card>
		</StyledEarningsCard>
	);
};

export default EarningsCard;

