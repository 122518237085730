// -------------------------------------------------------------------------------
// Libraries
// -------------------------------------------------------------------------------
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card, Collapse, Form, message, Button as AntB } from "antd";
import { useDispatch, useSelector } from "react-redux";

// -------------------------------------------------------------------------------
// Component, selectors and others
// -------------------------------------------------------------------------------
import {
	Heading4,
	Heading6,
	Paragraph14,
	Paragraph16,
	Paragraph17,
	Paragraph18,
	Paragraph19,
	Paragraph20,
	Paragraph8,
} from "themes/default/_typography";
import { LoadingMask } from "components/Common/LoadingMask";
import { DividerHeader } from "components/Common/DividerHeader";
import { MembershipSelectionCard } from "components/Common/Cards/MembershipSelectionCard";
import { CalendarIcon, VideoVerticalIcon } from "components/Icons";
import { Button } from "components/Common/Button";

// -------------------------------------------------------------------------------
// Utils, Styles and Assets
// -------------------------------------------------------------------------------
import StyledMembershipSelection from "./styles";
import { paymentPlans } from "helpers/constants";

// -------------------------------------------------------------------------------
// Actions and selectors
// -------------------------------------------------------------------------------
import {
	updateSelectedMembership,
	createSubscription,
	createCreatorProduct,
	updateSelectedProduct,
} from "store/slices";
import { selectDomainGymId, selectCreatorMemberships, selectCurrentMembershipDetails } from "store/selectors";
import { FormInputNumber } from "components/Common/Form/FormInputNumber";
import {
	useGetCreatorAccountQuery,
	useGetCreatorMembershipsQuery,
	useGetDomainGymQuery,
	useGetProductsQuery,
} from "store/api/api";
import axios from "axios";
import { PlanCard } from "components/Common/Cards/PlanCard";
import { useRecoilValue } from "recoil";
import { userAtom } from "globalAtoms";
import { StripeCreateSubscriptionPrice, StripeUpdateMembershipPricing } from "constants/urls";

// -------------------------------------------------------------------------------
// Component
// -------------------------------------------------------------------------------

const MembershipSelection = ({ className, product, planDetails, currentPricing }) => {
	const [messageApi, contextHolder] = message.useMessage();

	const success = () => {
		messageApi.open({
			type: "success",
			duration: 7,
			content: "Your Membership Pricing Was Successfully Updated",

			style: {
				marginTop: "80vh",
			},
		});
	};

	const { Panel } = Collapse;
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const domainGymId = useSelector(selectDomainGymId);
	const currentMembershipDetails = useSelector(selectCurrentMembershipDetails);
	const user = useRecoilValue(userAtom);
	const { gym } = useParams();
	const domainGymResponse = useGetDomainGymQuery(gym);
	const creatorAcctRes = useGetCreatorAccountQuery(domainGymResponse.data?.id, { skip: !domainGymResponse.data });

	const creatorMemberships = useSelector(selectCreatorMemberships);

	const { data, isLoading, error } = useGetCreatorMembershipsQuery();

	//const [isLoading, setIsLoading] = useState(false);
	const membersPayRules = [];
	const [switchingPlan, setSwitchingPlan] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const [paymentData, setPaymentData] = useState({
		membersPay: 0,
		ownerCut: planDetails.share * 0,
	});
	const [selectedMembershipIndex, setSelectedMembershipIndex] = useState("monthly");

	useEffect(() => {
		if (currentMembershipDetails) {
			var membersPay = currentMembershipDetails.membersPay;
			setPaymentData({ membersPay, ownerCut: planDetails.share * membersPay });
		}
	}, [currentMembershipDetails]);
	useEffect(() => {
		dispatch(updateSelectedMembership({ membership: "monthly" }));
	}, [dispatch, domainGymResponse, creatorAcctRes]);

	useEffect(() => {
		if (creatorMemberships && creatorMemberships.length > 0) {
			//navigate("/dashboard/payment-settings");
		}
	}, [creatorMemberships, navigate]);

	const onSaveNewMembershipPrice = async () => {
		if (planDetails.title === "Starter" && paymentData.membersPay < 9.99) {
			messageApi.open({
				type: "error",
				duration: 7,
				content: "Min price is $9.99 on Starter Plan",
				style: {
					marginTop: "80vh",
				},
			});
			return;
		} else {
			setIsSaving(true);
			if (domainGymResponse.data?.id && product?.type) {
				// dispatch(
				// 	createSubscription({
				// 		product_type: product.type,
				// 		user_type: "creator",
				// 		gym_id: domainGymResponse.data.id,
				// 	})
				// );

				if (currentMembershipDetails.id) {
					// If the user is switching plans, we need to update the product
					try {
						const updatePricingUrl =
							process.env.REACT_APP_API_ENV === "production"
								? StripeUpdateMembershipPricing.prod
								: StripeUpdateMembershipPricing.dev;
						const response = await axios.post(updatePricingUrl, {
							productId: currentMembershipDetails.id,
							newPrice: selectedMembershipIndex === "free" ? 0 : (paymentData.membersPay * 100).toFixed(0),
						});
						setIsSaving(false);
						setSwitchingPlan(false);
						success();
					} catch (error) {
						console.error("update subscription ERROR: ", error);
						setIsSaving(false);
						setSwitchingPlan(false);
					}
				} else {
					// otherwise we need to create a new product
					try {
						const productName = `Monthly: ${domainGymResponse.data?.name}`;
						const createSubscriptionUrl =
							process.env.REACT_APP_API_ENV === "production"
								? StripeCreateSubscriptionPrice.prod
								: StripeCreateSubscriptionPrice.dev;
						const response = await axios.post(createSubscriptionUrl, {
							accountId: user.connected_account_id,
							productName,
							price: selectedMembershipIndex === "free" ? 0 : (paymentData.membersPay * 100).toFixed(0),
						});
						success();
						setIsSaving(false);
					} catch (error) {
						console.error("new subscription ERROR: ", error);
						setIsSaving(false);
					}
				}
			}
			//createNewSubscription();

			setIsSaving(false);
		}
	};

	const onValuesChange = useCallback(
		({ membersPay }) => {
			setPaymentData({
				membersPay,
				ownerCut: membersPay * planDetails.share,
			});
		},
		[product]
	);

	const onPrevious = useCallback(() => {
		navigate("/dashboard/earnings");
	}, [navigate]);

	const handleMembershipSelection = useCallback(
		(membership) => {
			setSelectedMembershipIndex(membership);
			dispatch(updateSelectedMembership({ membership }));
		},
		[dispatch]
	);

	const validateMinValueForStarter = (_, value) => {
		if (value < 9.99) {
			return Promise.reject();
		}

		return Promise.resolve();
	};

	if (selectedMembershipIndex !== "free") {
		membersPayRules.push({ required: true, message: "Field Required" });
	}

	if (planDetails.title === "Starter") {
		membersPayRules.push({ validator: validateMinValueForStarter, message: "Min price is $9.99 on Starter Plan" });
	}

	const handleSelectPlan = useCallback(
		(product) => {
			dispatch(updateSelectedProduct({ product }));
			// TODO: STRIPE should create payment/setup intent here?

			navigate("/dashboard/membership-settings");
		},
		[navigate, dispatch]
	);

	const handleSwitchPlan = (tier) => {
		dispatch(
			updateSelectedProduct({
				product: {
					type: tier,
					name: paymentPlans[tier].title,
					price: paymentPlans[tier].price,
				},
			})
		);
		// TODO: STRIPE should create payment/setup intent here?
		//navigate("/dashboard/membership-settings");
	};

	return (
		<StyledMembershipSelection className={`${className} sb-membership-selection`}>
			<Form onValuesChange={onValuesChange} layout="vertical">
				{switchingPlan && (
					<div className="sb-membership-selection__cards">
						<MembershipSelectionCard
							selected={selectedMembershipIndex === "free"}
							icon={<VideoVerticalIcon />}
							title="Free"
							readOnly={planDetails.title === "Starter"}
							subtitle="All your workouts are free for members."
							description="Free content for your members is only available in <b>Pro</b> or <b>Studio Pro</b>."
							onClick={() => handleMembershipSelection("free")}
						/>
						<MembershipSelectionCard
							selected={selectedMembershipIndex === "monthly"}
							icon={<CalendarIcon />}
							title="Monthly"
							subtitle="Members pay a monthly fee for your paid workouts. 3 free workouts are required."
							onClick={() => handleMembershipSelection("monthly")}
						/>
					</div>
				)}
				<div className="sb-membership-selection__details">
					<div className="sb-membership-selection__details__up">
						<div className="sb-membership-selection__details__up__left">
							<Heading4>Membership Price</Heading4>
							<Paragraph14>
								Paying this fee unlocks all workouts and collections for 30 days. If member doesn’t renew, they lose
								access to your paid content.
							</Paragraph14>
						</div>
						<div className="sb-membership-selection__details__up__right">
							<Paragraph14>Your members pay</Paragraph14>
							{/* {!paymentData.membersPay && (
								<FormInputNumber
									name="membersPay"
									//value={paymentData.membersPay}

									initialValues={0}
									type="money"
									addonRight="/month"
									rules={membersPayRules}
								/>
							)} */}
							{!switchingPlan && <Paragraph20>${parseFloat(paymentData.membersPay).toFixed(2) || 0}</Paragraph20>}
							{currentPricing > 0 && switchingPlan && (
								<FormInputNumber
									name="membersPay"
									defaultValue={parseFloat(currentPricing / 100).toFixed(2)}
									type="money"
									addonRight={`/month`}
									rules={membersPayRules}
								/>
							)}
							{switchingPlan && (currentPricing === 0 || currentPricing === undefined) && (
								<FormInputNumber
									disabled={!switchingPlan}
									name="membersPay"
									defaultValue={0}
									type="money"
									addonRight={`/month`}
									rules={membersPayRules}
								/>
							)}
						</div>
					</div>
					<div className="sb-membership-selection__details__down">
						<div className="sb-membership-selection__details__down__left">
							<div className="sb-membership-selection__details__down__left__icon">{planDetails.icon}</div>
							<div className="sb-membership-selection__details__down__left__text">
								<Collapse
									collapsible="diabled"
									ghost
									className="sb-membership-selection__details__down__left__collapse"
								>
									<Panel
										collapsible="disabled"
										showArrow={false}
										header={
											<>
												<Paragraph16>{planDetails.title} Plan</Paragraph16>
												<Paragraph17>Keep {planDetails.share * 100}% of sales</Paragraph17>
											</>
										}
										key="1"
									>
										<Paragraph14>
											You are currently on the <b>{planDetails.title} Plan</b>. Switching to a different plan will
											change your revenue share and pricing.
										</Paragraph14>
										<Paragraph14>
											<b>Are you sure you want to switch plans?</b>
										</Paragraph14>
										{isLoading ? (
											<LoadingMask />
										) : (
											<div
												style={{
													display: "flex",
													flexWrap: "wrap",
												}}
											>
												{["starter", "pro", "prostudio", "elite"].map((tier) =>
													paymentPlans[tier] ? (
														<Card
															onClick={() => {
																tier === "elite" ? console.debug("elite") : handleSwitchPlan(tier);
															}}
															hoverable
															key={paymentPlans[tier].title}
															style={{
																flexBasis: "50%",
																textAlign: "center",
																backgroundColor:
																	paymentPlans[product.type].title === paymentPlans[tier].title ? "coral" : null,
															}}
														>
															<Heading6>{paymentPlans[tier].title}</Heading6>
															{tier !== "elite" && <Paragraph8>{paymentPlans[tier].share * 100}% of sales</Paragraph8>}
															{tier !== "elite" && <Paragraph8>{paymentPlans[tier].priceDescription}</Paragraph8>}
															<div
																style={{
																	alignItems: "center",
																	width: "100%",
																	textAlign: "center",
																	borderRadius: 20,
																}}
															>
																<div>{paymentPlans[tier].icon}</div>
																{paymentPlans[tier].price}
															</div>
														</Card>
													) : null
												)}
											</div>
										)}
									</Panel>
								</Collapse>
							</div>
						</div>
						<div className="sb-membership-selection__details__down__right">
							<Paragraph19>You take home</Paragraph19>
							<Paragraph20>${Math.round((paymentData.ownerCut + Number.EPSILON) * 100) / 100 || 0}</Paragraph20>
						</div>
					</div>
					{selectedMembershipIndex === "free" && <div className="sb-membership-selection__details__mask" />}
				</div>

				<div className="sb-membership-selection__navigation">
					{contextHolder}
					{switchingPlan && (
						<Button className="secondary-outlined" onClick={() => setSwitchingPlan(false)} uppercase>
							Cancel
						</Button>
					)}
					{switchingPlan ? (
						<Button className="primary-filled" onClick={onSaveNewMembershipPrice} uppercase>
							Save Member Plan
						</Button>
					) : (
						<Button className="primary-filled" onClick={() => setSwitchingPlan(true)} uppercase>
							Set New Price
						</Button>
					)}

					{isSaving && <LoadingMask />}
				</div>
			</Form>
			{isLoading && <LoadingMask />}
		</StyledMembershipSelection>
	);
};

export default MembershipSelection;

