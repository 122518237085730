// -----------------------------------------------------------------------------
// Library
// -----------------------------------------------------------------------------
import Table from "antd/lib/table";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import moment from "moment";
import { Button, Dropdown, Input, Space, Switch, Tooltip } from "antd";
import { CaretDownFilled, ClockCircleOutlined, SearchOutlined } from "@ant-design/icons";

// -----------------------------------------------------------------------------
// Components
// -----------------------------------------------------------------------------
import { secondsToTime } from "helpers/generic";
import Categories from "components/UI/Categories";
import VideoPreview from "components/UI/Video/VideoPreview";
import VideoPlayerModal from "components/UI/VideoPlayer/VideoPlayerModal";
import { LiveIcon } from "components/Icons";

// -----------------------------------------------------------------------------
// Store
// -----------------------------------------------------------------------------
import { selectMembersWorkoutsSelectedCategories } from "store/selectors";
import { updateMembersWorkoutsSelectedCategories, updateSelectedWorkout } from "store/slices";
import {
	useEditWorkoutMutation,
	useGetCategoriesQuery,
	useGetDomainGymQuery,
	useGetGenresQuery,
	useGetGymWorkoutsQuery,
} from "store/api/api";

// -----------------------------------------------------------------------------
// Style, utils and assets
// -----------------------------------------------------------------------------
import PencilSVG from "images/pencil.svg";

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
const WorkoutsTable = ({ setShowEditWorkoutModal }) => {
	const dispatch = useDispatch();
	let { gym } = useParams();
	const domainGymResponse = useGetDomainGymQuery(gym);
	const domainGymId = domainGymResponse?.data?.id;

	const workoutsResponse = useGetGymWorkoutsQuery(domainGymId, { skip: !domainGymResponse.data });
	const [workouts, setWorkouts] = useState([]);
	const categoriesResponse = useGetCategoriesQuery(domainGymId, { skip: !domainGymResponse.data });
	const categories = categoriesResponse.data?.categories || [];
	const genresResponse = useGetGenresQuery();
	const genres = genresResponse.data?.genres || [];
	const selectedCategories = useSelector(selectMembersWorkoutsSelectedCategories);

	const [editWorkoutTrigger] = useEditWorkoutMutation();
	const [isShowingVideoPlayer, updateIsShowingVideoPlayer] = useState(false);
	const [fileSelected, updateFileSelected] = useState(null);
	const [searchText, setSearchText] = useState("");
	const categoriesFilters = useSelector(selectMembersWorkoutsSelectedCategories);
	const [equipmentFilters, setEquipmentFilters] = useState([]);
	const [trainerFilters, setTrainerFilters] = useState([]);
	const setWorkoutSelected = useCallback(
		(workout) => {
			dispatch(updateSelectedWorkout({ workout }));
			setShowEditWorkoutModal(true);
		},
		[dispatch, setShowEditWorkoutModal]
	);
	const [selectedDurations, setSelectedDurations] = useState([]);
	const [selectedGenres, setSelectedGenres] = useState([]);
	const [pendingEdits, setPendingEdits] = useState([]);

	const handleChangeSearchText = (e) => {
		setSearchText(e.target.value);
	};

	useEffect(() => {
		if (workoutsResponse.data) {
			setWorkouts(workoutsResponse.data.workouts);
		}
	}, [workoutsResponse]);

	const handleCategoryClick = (category) => {
		const category_id = parseInt(category.key);

		if (!selectedCategories.includes(category_id)) {
			if (category_id === 0) {
				dispatch(updateMembersWorkoutsSelectedCategories([0]));
			} else {
				if (selectedCategories.includes(0)) {
					dispatch(updateMembersWorkoutsSelectedCategories([category_id]));
				} else {
					dispatch(updateMembersWorkoutsSelectedCategories([...selectedCategories, category_id]));
				}
			}
		} else if (category_id !== 0) {
			const newCategories = [...selectedCategories];

			newCategories.splice(
				selectedCategories.findIndex((c) => c === category_id),
				1
			);

			dispatch(updateMembersWorkoutsSelectedCategories(newCategories.length > 0 ? newCategories : [0]));
		}
	};

	const handleEditWorkoutActive = async (workout, active) => {
		try {
			await editWorkoutTrigger({
				workoutId: workout.id,
				gym_id: workout.gym_id,
				draft: active ? 0 : 1,
			}).unwrap();
		} catch (error) {
			console.error("handleEditWorkoutActive error: ", error);
		}
	};

	const handleChangeActive = async (checked, event, record) => {
		setPendingEdits([...pendingEdits, record.id]);
		await handleEditWorkoutActive(record, checked);
		setPendingEdits([...pendingEdits.filter((id) => id !== record.id)]);
		workoutsResponse.refetch();
	};

	//Why is this showing double
	const categoryItems = categories.map((category) => {
		return {
			label: category.name,
			key: category.id,
			danger: categoriesFilters.includes(category.id) ? true : false,
		};
	});

	const categoriesMenu = {
		items: [
			{
				label: "All Categories",
				key: 0,
				danger: categoriesFilters.includes(0) ? true : false,
			},
			...categoryItems,
		],
		onClick: handleCategoryClick,
	};
	const allTrainers = workouts.map((workout) => workout.coach);
	const uniqueTrainers = [...new Set(allTrainers.flat())];

	const trainerItems = uniqueTrainers.map((item, index) => {
		return {
			label: item,
			key: index,
			danger: trainerFilters.includes(item) ? true : false,
		};
	});

	const handleTrainerClick = (trainer) => {
		const clickedTrainer = trainerItems[trainer.key].label;
		if (trainerFilters.includes(clickedTrainer)) {
			const newTrainers = trainerFilters.filter((g) => g !== clickedTrainer);
			setTrainerFilters(newTrainers);
		} else {
			setTrainerFilters([...trainerFilters, clickedTrainer]);
		}
	};

	const trainersMenu = {
		items: trainerItems,
		onClick: handleTrainerClick,
	};

	const allEquipment = workouts.map((workout) => workout.equipment.split(",").map((e) => e.trim()));
	const uniqueEquipment = [...new Set(allEquipment.flat())];

	const equipmentItems = uniqueEquipment.map((item, index) => {
		return {
			label: item,
			key: index,
			danger: equipmentFilters.includes(item) ? true : false,
		};
	});

	const handleEquipmentClick = (equipment) => {
		const clickedEquipment = equipmentItems[equipment.key].label;
		if (equipmentFilters.includes(clickedEquipment)) {
			const newEquipment = equipmentFilters.filter((g) => g !== clickedEquipment);
			setEquipmentFilters(newEquipment);
		} else {
			setEquipmentFilters([...equipmentFilters, clickedEquipment]);
		}
	};

	const equipmentMenu = {
		items: equipmentItems,
		onClick: handleEquipmentClick,
	};

	const handleGenreClick = (clicked) => {
		const clickedGenre = genres[clicked.key];
		const isAlreadySelected = selectedGenres.map((g) => g.title).includes(clickedGenre.title);
		if (isAlreadySelected) {
			const newGenres = selectedGenres.filter((g) => g.title !== clickedGenre.title);
			setSelectedGenres(newGenres);
		} else {
			setSelectedGenres([...selectedGenres, clickedGenre]);
		}
	};

	const genreItems = genres.map((item, index) => {
		return {
			label: item.title,
			key: index,
			danger: selectedGenres.map((g) => g.title).includes(genres[index].title) ? true : false,
		};
	});

	const genreMenu = {
		items: genreItems,
		onClick: handleGenreClick,
	};

	const durations = [
		{ min: 0, max: 15, label: "0-15 min" },
		{ min: 15, max: 30, label: "15-30 min" },
		{ min: 30, max: 45, label: "30-45 min" },
		{ min: 45, max: 60, label: "45-60 min" },
		{ min: 60, max: 90, label: "60-90 min" },
		{ min: 90, max: 120, label: "90-120 min" },
		{ min: 120, max: 1000, label: "120+ min" },
	];

	const handleDurationClick = (duration) => {
		const clicked = durations[duration.key];
		const selectedLabels = selectedDurations.map((d) => d.label);
		const isAlreadySelected = selectedLabels.includes(clicked.label);
		if (isAlreadySelected) {
			const newDurations = selectedDurations.filter((d) => d.label !== clicked.label);
			setSelectedDurations(newDurations);
		} else {
			setSelectedDurations([...selectedDurations, clicked]);
		}
	};

	const handleToggleAllActive = async (checked, event) => {
		const filteredWorkouts = workouts
			.filter((workout) => isWithinDuration(workout))
			.filter(
				(workout) =>
					categoriesFilters.includes(0) || workout.categories.some((c) => categoriesFilters.includes(c.category_id))
			)
			.filter((workout) => (trainerFilters.length > 0 ? trainerFilters.includes(workout.coach) : true))
			.filter((workout) =>
				equipmentFilters.length > 0 ? workout.equipment.split(",").some((e) => equipmentFilters.includes(e)) : true
			)
			.filter(
				(workout) => selectedGenres.map((g) => g.title).includes(workout.music_genre) || selectedGenres.length === 0
			)
			// .filter((workout) => workout.start_time === null)
			.filter((workout) => workout.duration !== 0)
			.filter(
				(workout) =>
					workout.name?.toLowerCase().includes(searchText.toLowerCase()) ||
					workout.description?.toLowerCase().includes(searchText.toLowerCase())
			)
			.sort((a, b) => new Date(b.created_date) - new Date(a.created_date));

		setPendingEdits(filteredWorkouts.map((workout) => workout.id));
		for (const workout of filteredWorkouts) {
			await handleEditWorkoutActive(workout, checked);
		}
		setPendingEdits([]);
		workoutsResponse.refetch();
	};

	const durationItems = durations.map((item, index) => {
		return {
			label: item.label,
			key: index,
			danger: selectedDurations.map((d) => d.label).includes(durations[index].label) ? true : false,
		};
	});

	const durationMenu = {
		items: durationItems,
		onClick: handleDurationClick,
	};

	const isWithinDuration = (workout) => {
		const duration = workout.duration / 60;
		const selectedDurationsLabels = selectedDurations.map((d) => d.label);
		var isWithin = false;

		if (selectedDurationsLabels.length === 0) {
			isWithin = true;
		} else if (selectedDurationsLabels.includes("120+ min") && duration >= 120) {
			isWithin = true;
		} else if (selectedDurationsLabels.includes("0-15 min") && duration >= 0 && duration <= 15) {
			isWithin = true;
		} else if (selectedDurationsLabels.includes("15-30 min") && duration >= 15 && duration <= 30) {
			isWithin = true;
		} else if (selectedDurationsLabels.includes("30-45 min") && duration >= 30 && duration <= 45) {
			isWithin = true;
		} else if (selectedDurationsLabels.includes("45-60 min") && duration >= 45 && duration <= 60) {
			isWithin = true;
		} else if (selectedDurationsLabels.includes("60-90 min") && duration >= 60 && duration <= 90) {
			isWithin = true;
		} else if (selectedDurationsLabels.includes("90-120 min") && duration >= 90 && duration <= 120) {
			isWithin = true;
		}
		return isWithin;
	};

	const renderSearchFilters = () => {
		return (
			<div
				className="sb-workouts-table__search-filters"
				style={{ gap: "8px", display: "flex", padding: "8px", flexWrap: "wrap" }}
			>
				<Space.Compact size="large">
					<Input
						placeholder="Search Workouts"
						prefix={<SearchOutlined style={{ color: "grey", paddingRight: "8px" }} />}
						onChange={handleChangeSearchText}
						style={{ borderRadius: "100px", minWidth: "296px" }}
					/>
				</Space.Compact>
				<Space.Compact size="large">
					<Dropdown menu={categoriesMenu}>
						<Button
							style={{
								borderColor: " #E3E5E7",
								textTransform: "none",
								color: "#4F5661",
								fontWeight: "700",
								fontSize: "14px",
								lineHeight: "24px",
								fontFamily: "DM Sans",
							}}
						>
							Workout Category{" "}
							{selectedCategories.length > 0 && selectedCategories[0] !== 0 && `(${selectedCategories.length})`}
							<CaretDownFilled
								style={{
									color: "#4F5661",

									fontSize: "12px",
									lineHeight: "0px",
								}}
							/>
						</Button>
					</Dropdown>
				</Space.Compact>
				<Space.Compact size="large">
					<Dropdown menu={trainersMenu}>
						<Button
							style={{
								borderColor: " #E3E5E7",
								textTransform: "none",
								color: "#4F5661",
								fontWeight: "700",
								fontSize: "14px",
								lineHeight: "24px",
								fontFamily: "DM Sans",
							}}
						>
							Trainers {trainerFilters.length > 0 && `(${trainerFilters.length})`}
							<CaretDownFilled
								style={{
									color: "#4F5661",

									fontSize: "12px",
									lineHeight: "0px",
								}}
							/>
						</Button>
					</Dropdown>
				</Space.Compact>
				<Space.Compact size="large">
					<Dropdown menu={equipmentMenu}>
						<Button
							style={{
								borderColor: " #E3E5E7",
								textTransform: "none",
								color: "#4F5661",
								fontWeight: "700",
								fontSize: "14px",
								lineHeight: "24px",
								fontFamily: "DM Sans",
							}}
						>
							Equipment {equipmentFilters.length > 0 && `(${equipmentFilters.length})`}
							<CaretDownFilled
								style={{
									color: "#4F5661",

									fontSize: "12px",
									lineHeight: "0px",
								}}
							/>
						</Button>
					</Dropdown>
				</Space.Compact>
				<Space.Compact size="large">
					<Dropdown menu={durationMenu}>
						<Button
							style={{
								borderColor: " #E3E5E7",
								textTransform: "none",
								color: "#4F5661",
								fontWeight: "700",
								fontSize: "14px",
								lineHeight: "24px",
								fontFamily: "DM Sans",
							}}
						>
							Duration {selectedDurations.length > 0 && `(${selectedDurations.length})`}
							<CaretDownFilled
								style={{
									color: "#4F5661",
									fontSize: "12px",
									lineHeight: "0px",
								}}
							/>
						</Button>
					</Dropdown>
				</Space.Compact>
				<Space.Compact size="large">
					<Dropdown menu={genreMenu}>
						<Button
							style={{
								borderColor: " #E3E5E7",
								textTransform: "none",
								color: "#4F5661",
								fontWeight: "700",
								fontSize: "14px",
								lineHeight: "24px",
								fontFamily: "DM Sans",
							}}
						>
							Music {selectedGenres.length > 0 && `(${selectedGenres.length})`}
							<CaretDownFilled
								style={{
									color: "#4F5661",

									fontSize: "12px",
									lineHeight: "0px",
								}}
							/>
						</Button>
					</Dropdown>
				</Space.Compact>
			</div>
		);
	};

	const columns = [
		{
			title: "Video",
			dataIndex: "video",
			key: "video",
			render: (_, record) => {
				return (
					<VideoPreview
						updateIsShowingVideoPlayer={updateIsShowingVideoPlayer}
						updateFileSelected={updateFileSelected}
						record={record}
					/>
				);
			},
		},
		{
			title: "Music",
			dataIndex: "music",
			key: "music",
			responsive: ["lg"],
			render: (_, record) => {
				return <span>{record.music_genre}</span>;
			},
		},
		{
			title: "Categories",
			dataIndex: "categories",
			key: "categories",
			responsive: ["lg"],
			render: (row) => (row ? <Categories values={row.map((r) => r.name)} /> : null),
		},

		{
			title: "Duration",
			dataIndex: "duration",
			key: "duration",
			responsive: ["lg"],
			render: (_, record) => {
				return record.duration === 0 ? <span>Processing...</span> : <span>{secondsToTime(record.duration)}</span>;
			},
		},
		{
			title: "Active ",
			dataIndex: "active",
			key: "active",
			align: "left",
			responsive: ["lg"],
			filtered: false,
			filterDropdown: <></>,
			filterDropdownOpen: false,
			filterIcon: (
				<Switch
					disabled={false}
					defaultChecked={false}
					onChange={(checked, event) => handleToggleAllActive(checked, event)}
				/>
			),
			render: (_, record) =>
				record.start_time && moment(record.start_time).isAfter(moment()) ? (
					<Tooltip
						title={
							record.playback_id !== "null"
								? `This workout will activate at ${moment(record.start_time).format("MM/DD h:mm a")}`
								: `Live streaming at ${moment(record.start_time).format("MM/DD h:mm a")}`
						}
					>
						{record.playback_id === "null" ? (
							<span>
								<LiveIcon color="#FF865C" />
							</span>
						) : (
							<ClockCircleOutlined style={{ color: "#FFC107", fontSize: "22px" }} />
						)}
					</Tooltip>
				) : (
					<Switch
						loading={pendingEdits.includes(record.id)}
						disabled={record.duration === 0 || pendingEdits.includes(record.id)}
						checked={record.draft === 0}
						onClick={(checked, event) => handleChangeActive(checked, event, record)}
					/>
				),
		},
		{
			title: "Actions",
			dataIndex: "actions",
			key: "actions",
			render: (_, record) => {
				return (
					<div
						className="sb-workouts-table__edit tag"
						data-cy={`edit-workout-button`}
						onClick={() => setWorkoutSelected(record)}
					>
						<img src={PencilSVG} alt="edit" /> <span style={{ marginLeft: 10 }}>Edit</span>
					</div>
				);
			},
		},
	];

	return (
		<div className="sb-workouts-table">
			{renderSearchFilters()}
			{workouts && (
				<Table
					columns={columns}
					dataSource={workouts
						.filter((workout) => isWithinDuration(workout))
						.filter(
							(workout) =>
								categoriesFilters.includes(0) ||
								workout.categories.some((c) => categoriesFilters.includes(c.category_id))
						)
						.filter((workout) => (trainerFilters.length > 0 ? trainerFilters.includes(workout.coach) : true))
						.filter((workout) =>
							equipmentFilters.length > 0
								? workout.equipment.split(",").some((e) => equipmentFilters.includes(e))
								: true
						)
						.filter(
							(workout) =>
								selectedGenres.map((g) => g.title).includes(workout.music_genre) || selectedGenres.length === 0
						)
						.filter(
							(workout) =>
								workout.name?.toLowerCase().includes(searchText.toLowerCase()) ||
								workout.description?.toLowerCase().includes(searchText.toLowerCase())
						)
						.sort((a, b) => new Date(b.created_date) - new Date(a.created_date))}
					rowKey="id"
					onRow={(record, index) => ({
						style: {
							background: record.duration === 0 ? "rgba(0,0,0,.1)" : "default",
							cursor: record.duration === 0 ? "null" : "pointer",
						},
					})}
				/>
			)}
			<VideoPlayerModal
				file={fileSelected}
				isShowing={isShowingVideoPlayer}
				updateIsShowingVideoPlayer={updateIsShowingVideoPlayer}
			/>
		</div>
	);
};

export default WorkoutsTable;
