import styled from "styled-components";

const replacementString =
	process.env.REACT_APP_API_ENV === "production" ? "cdn.sweatbase.com" : "sweatbase-staging.s3.amazonaws.com/assets";

const StyledWorkoutCard = styled.div`
	width: 302px;
	background-color: ${({ theme }) => theme.colors.white};
	border-radius: 12px;

	.sb-workout-card {
		&__inner {
			border-radius: 12px;

			&__header-section {
				color: red;
				height: 180px;
				background-size: cover;
				background-position: center;
				background-image: ${({ headerImage }) => `url(${headerImage.replace("cdn.sweatbase.com", replacementString)})`};
				border-top-left-radius: 12px;
				border-top-right-radius: 12px;
			}

			&__body-section {
				padding: 16px;
				display: flex;
				flex-direction: column;
				gap: 8px;

				p {
					margin-bottom: 0;
				}

				&__categories {
				}
			}
		}
	}

	.ant-card-body {
		padding: 0;
	}
`;

export default StyledWorkoutCard;
