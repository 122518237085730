// -------------------------------------------------------------------------------
// Libraries
// -------------------------------------------------------------------------------
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

// -------------------------------------------------------------------------------
// Store
// -------------------------------------------------------------------------------
import { selectCreatorMemberships, selectCreatorAccount } from "store/selectors";

// -------------------------------------------------------------------------------
// Components
// -------------------------------------------------------------------------------
import { PlanSelection } from "components/Payments/PlanSelection";
// import { EarningsStackedBarChart } from "components/Payments/EarningsStackedBarChart";
// import { SubscribersCard } from "components/Common/Cards/SubscribersCard";
import { EarningsCard } from "components/Common/Cards/EarningsCard";

// -------------------------------------------------------------------------------
// Utils, Styles and Assets
// -------------------------------------------------------------------------------
import StyledEarningsPage from "./styles";
import {
	useUpdateUserConnectedAccountMutation,
	useUpdateUserCustomerIdMutation,
	useGetCreatorAccountQuery,
	useGetCreatorMembershipsQuery,
	useGetCurrentUserQuery,
	useGetDomainGymQuery,
} from "store/api/api";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "antd";
import { useRecoilValue } from "recoil";
import { userAtom } from "../../../globalAtoms";
import { LoadingMask } from "components/Common/LoadingMask";
import { SubscribersCard } from "components/Common/Cards/SubscribersCard";
import { EarningsStackedBarChart } from "components/Payments/EarningsStackedBarChart";
import { StripeFetchTransfers, StripeGetCustomerSubscriptions, StripeSearchSubscriptions } from "constants/urls";
import { EarningsTable } from "components/Payments/EarningsTable";

// -------------------------------------------------------------------------------
// Component
// -------------------------------------------------------------------------------
const EarningsPage = () => {
	const navigate = useNavigate();
	const user = useRecoilValue(userAtom);
	const token = localStorage.getItem("creator_jwt_token");
	const split = token.split(".")[1];
	const formatted = JSON.parse(atob(split));
	const JWTID = formatted.id;
	const { data: currentUserData, isError } = useGetCurrentUserQuery({ userId: JWTID });
	const [loading, setLoading] = useState(true);

	const [subscribed, setSubscribed] = useState(false);
	const [transfers, setTransfers] = useState([]);
	const [pendingBalance, setPendingbalance] = useState(0);
	const [totalEarnings, setTotalEarnings] = useState(0);
	const creatorAccount = useSelector(selectCreatorAccount);
	const { gym } = useParams();
	const domainGymResponse = useGetDomainGymQuery(gym);
	const creatorAcctRes = useGetCreatorAccountQuery(domainGymResponse.data?.id, { skip: !domainGymResponse.data });

	const creatorMemberships = useSelector(selectCreatorMemberships);

	const creatorMembershipsResponse = useGetCreatorMembershipsQuery();

	const fetchCustomerSubscriptions = async () => {
		if (subscribed) {
			setLoading(false);
		} else {
			try {
				const getSubscriptionsUrl =
					process.env.REACT_APP_API_ENV === "production"
						? StripeGetCustomerSubscriptions.prod
						: StripeGetCustomerSubscriptions.dev;
				const subscriptionsResponse = await axios.post(getSubscriptionsUrl, {
					customerId: user.customer_id,
				});
				if (subscriptionsResponse.data.data.length > 0) {
					setSubscribed(true);
					// should navigate to the membership settings or the connected account page?
					//navigate("/dashboard/membership-settings")
					const data = subscriptionsResponse.data.data[0].items.data[0];
				}
				setLoading(false);
			} catch (error) {
				console.debug("ERROR: ", error);
				setLoading(false);
			}
		}
	};

	//TODO: Now that we have fetched subscriber data, we still need to format to pull out earnings, and dates
	const fetchSubscriberData = async () => {
		try {
			const searchSubscriptionsUrl =
				process.env.REACT_APP_API_ENV === "production" ? StripeSearchSubscriptions.prod : StripeSearchSubscriptions.dev;
			const subscribersResponse = await axios.post(searchSubscriptionsUrl, {
				accountId: user.connected_account_id,
			});
		} catch (error) {
			console.debug("ERROR FETCHING SUBSCRIBER DATA: ", error);
		}
	};

	const fetchDetailedEarnings = async () => {
		try {
			const transfersUrl =
				process.env.REACT_APP_API_ENV === "production" ? StripeFetchTransfers.prod : StripeFetchTransfers.dev;
			const transfers = await axios.post(transfersUrl, {
				accountId: user.connected_account_id,
				details: true,
			});
			console.log("transfers?.data", transfers?.data);

			setTransfers(transfers?.data?.details || []);
			setPendingbalance(transfers?.data?.pendingBalance);
			setTotalEarnings(transfers?.data?.payoutsSum);
		} catch (error) {
			console.debug("ERROR FETCHING SUBSCRIBER DATA: ", error);
		}
	};

	useEffect(() => {
		if (creatorMembershipsResponse.data && !subscribed) {
			fetchCustomerSubscriptions();
			fetchSubscriberData();
			fetchDetailedEarnings();
		} else {
			console.debug("creatorMembershipsResponse.data is undefined");
		}
	}, [creatorMembershipsResponse, subscribed]);

	useEffect(() => {
		// call to retrieve specifically from creator_subscriptions by gym - there should only be one
		// loop over in case the membership was in a bad status and wasn't cleaned up

		if (creatorMembershipsResponse && creatorMembershipsResponse.data) {
			creatorMembershipsResponse.data.forEach((sub, index) => {
				// will this render more than once if there are two succeeded?
				if (sub.stripe_status === "succeeded") {
					setSubscribed(true);
					//setLoaded(true);
				}
			});
		}
	}, [creatorMembershipsResponse]);

	return (
		<StyledEarningsPage className="sb-earnings">
			{/* Should be subscribed to a plan to see earnings card otherwise show plan selection */}
			{loading ? (
				<LoadingMask />
			) : subscribed ? (
				<>
					<div className="sb-earnings__top-section">
						<EarningsCard pendingBalance={pendingBalance} totalEarnings={totalEarnings} />
						{/* <SubscribersCard /> */}
					</div>

					<EarningsStackedBarChart transfers={transfers} />
					<div style={{ padding: "8px", paddingTop: "24px" }}>
						<EarningsTable transfers={transfers} />
					</div>
				</>
			) : (
				<PlanSelection />
			)}
		</StyledEarningsPage>
	);
};

export default EarningsPage;
