export const StripeUpdateCustomerDefaultPaymentMethod = {
	dev: "https://iwa2qnhezok7xtdfdia74hmope0lfynz.lambda-url.us-east-2.on.aws/",
	prod: "https://fz6e6qte6asduwixrbjyss45fq0evkak.lambda-url.us-east-2.on.aws/",
};
export const StripeGetPaymentMethods = {
	dev: "https://srdit2rdnc6pwwsycogta5rtuy0rvhpy.lambda-url.us-east-2.on.aws/",
	prod: "https://463qeoauenbkc5z5rdaxwdzmwm0ttnfq.lambda-url.us-east-2.on.aws/",
};

export const StripeCreateSubscriptionToPlatform = {
	dev: "https://xjfhif57nmnlshe54l6qvde25i0obyju.lambda-url.us-east-2.on.aws/",
	prod: "https://vnwao4dixtvbnsmub3wk4s7quy0fftlv.lambda-url.us-east-2.on.aws/",
};

export const StripeCreateSubscriptionPrice = {
	dev: "https://ykyr35fs2bn6uwuery76w4nrhi0cxatc.lambda-url.us-east-2.on.aws/",
	prod: "https://nrv6flsovkl32lsb3daplsfxze0kkedv.lambda-url.us-east-2.on.aws/",
};

export const StripeCreateSubscription = {
	dev: "https://xjef44op6mvt2pfogcwdl6r4ou0psksd.lambda-url.us-east-2.on.aws/",
	prod: "https://obhcndff3wdd2xfy32mrkbq4ei0zqnvj.lambda-url.us-east-2.on.aws/",
};

export const StripeCreateConnectedAccount = {
	dev: "https://mqy6eqm7ec5aewp5odmq5kmi3y0vlqak.lambda-url.us-east-2.on.aws/",
	prod: "https://z2neqovtnsdbnlqnnx2sijkoru0fazqn.lambda-url.us-east-2.on.aws/",
};

export const StripeUpdateMembershipPricing = {
	dev: "https://rr6h43nk7shoc75leiqrtciahy0tntyn.lambda-url.us-east-2.on.aws/",
	prod: "https://jthsq5s4voj4q7ikuw752lirqq0qpvgg.lambda-url.us-east-2.on.aws/",
};

export const StripeSearchSubscriptions = {
	dev: "https://eyluvhng7uvhqsny7nzqkk5dom0jpzbj.lambda-url.us-east-2.on.aws/",
	prod: "https://cv77f7vsfleelqi5hfdxyjhbau0nwbug.lambda-url.us-east-2.on.aws/",
};
export const StripeGetCustomerSubscriptions = {
	dev: "https://bwmbtb3736lbk6vfsns7asefnu0cylea.lambda-url.us-east-2.on.aws/",
	prod: "https://t3tzb4vesgpadqq2p2tjhwozvi0twbtg.lambda-url.us-east-2.on.aws/",
};
export const StripeCreateSetupIntent = {
	dev: "https://kdqhohir2mpkrxqsbltl7vxey40mnkkj.lambda-url.us-east-2.on.aws/",
	prod: "https://l6ahev4nu7sz7b4wxb3bwjwkjy0ccyea.lambda-url.us-east-2.on.aws/",
};
export const StripeCreateOnboardingLink = {
	dev: "https://v5vuj7wtwg3prdyxraabxgmgbi0wubzh.lambda-url.us-east-2.on.aws/",
	prod: "https://pkewemq2ttn27arq2zhemom4540mboxt.lambda-url.us-east-2.on.aws/",
};
export const StripeGetSubscriptionsForGym = {
	dev: "https://m76qm46xjv3xtcvgfbgtol7pgu0rhguc.lambda-url.us-east-2.on.aws/",
	prod: "https://q2xqjplxhqtha3lwfhngkt5wbm0mqufh.lambda-url.us-east-2.on.aws/",
};
export const StripeCreateCustomer = {
	dev: "https://aogw4cherwouuphzfxngp63exa0hmdyw.lambda-url.us-east-2.on.aws/",
	prod: "https://dai3wn7r7fuivk72rvkpdwxzue0yicja.lambda-url.us-east-2.on.aws/",
};
export const StripeGetCustomerConnectedExternalAccounts = {
	dev: "https://ismsx7n4t4vdlslbvb6q6bbit40mtvof.lambda-url.us-east-2.on.aws/",
	prod: "https://pl2calczyzkbuemqca256yax2m0iyosv.lambda-url.us-east-2.on.aws/",
};

export const MUXCreateLiveStream = {
	dev: "https://qwzsmokix6yrlrmfe7el2lv4iu0sczdl.lambda-url.us-east-2.on.aws/",
	prod: "https://tbglazo5bmdpaylhu5auagb54q0rosda.lambda-url.us-east-2.on.aws/",
};

export const MUXCreateDirectUploadURL = {
	dev: "https://rhu6bnuh7jgj7f2at5m6rawtri0goghh.lambda-url.us-east-2.on.aws/",
	prod: "https://monceukj3m4kppw55ba5wyvh5m0poldy.lambda-url.us-east-2.on.aws/",
};

export const StripeFetchTransfers = {
	dev: "https://l4a347orxa527jjodfzmngw5em0uzcjx.lambda-url.us-east-2.on.aws/",
	prod: "https://rtrrluwy5cfgc6f4ojyzl6q5li0xvfzu.lambda-url.us-east-2.on.aws/",
};

//POST - subscriptionId
export const StripeCheckSubscriptionStatus = {
	dev: "https://5vyfrqwvsmmjqgjl5ggqsu3qnm0alfjg.lambda-url.us-east-2.on.aws/",
	prod: "https://tkmblatfellwiei5zjtvoobs4m0jzbmc.lambda-url.us-east-2.on.aws/",
};
