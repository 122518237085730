export function readFiles(files) {
	let promises = [];

	for (let f of files) {
		promises.push(readFile(f));
	}

	function readFile(f) {
		return new Promise(async (res) => {
			let url = null;

			if (typeof URL.createObjectURL === "function") {
				url = URL.createObjectURL(f);
			}

			return res({
				file: url,
				original: f,
				type: f.type,
				size: f.size,
			});
		});
	}

	return Promise.all(promises);
}
