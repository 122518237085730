// -----------------------------------------------------------------------------
// Library
// -----------------------------------------------------------------------------
import { CheckCircleFilled, PlusOutlined } from "@ant-design/icons";
import { Card, notification } from "antd";
import Button from "antd/lib/button";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRecoilValue } from "recoil";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import relativeTime from "dayjs/plugin/relativeTime";
import customParseFormat from "dayjs/plugin/customParseFormat";

// -----------------------------------------------------------------------------
// Store
// -----------------------------------------------------------------------------
import { updateSelectedMuxVideoUrl, updateSelectedWorkout } from "store/slices";

// -----------------------------------------------------------------------------
// Components and helpers
// -----------------------------------------------------------------------------
import { ChangeIndicator } from "components/Common/ChangeIndicator";
import { CreateWorkoutModal } from "components/Modals/CreateWorkoutModal";
import { EditWorkoutModal } from "components/Modals/EditWorkoutModal";
import { ScheduleLiveModal } from "components/Modals/ScheduleLiveModal";
import { GoLiveModal } from "components/Modals/GoLiveModal";
import WorkoutsTable from "components/Workouts/WorkoutsTable";
import { userAtom } from "../../../globalAtoms";
import {
	useCreateConnectedAccountMutation,
	useCreateCustomerMutation,
	useGetCreatorMembershipsQuery,
	useGetDomainGymQuery,
	useGetGymMembersByDateQuery,
	useGetGymWorkoutsQuery,
	useGetScheduledStreamsQuery,
	useGetStatsForGymQuery,
	useUpdateUserCustomerIdMutation,
} from "store/api/api";
import { useNavigate, useParams } from "react-router";
import { UserEditIcon } from "components/Icons/UserEditIcon";
import { CircleSelection } from "components/Common/CircleSelection";
import { Steps } from "components/Common/Steps";
import { ChevronRightIcon, LiveIcon, PencilIcon } from "components/Icons";
import axios from "axios";
import { selectCurrentMembershipDetails } from "store/selectors";
import {
	StripeCreateCustomer,
	StripeFetchTransfers,
	StripeGetCustomerConnectedExternalAccounts,
	StripeGetCustomerSubscriptions,
	StripeGetSubscriptionsForGym,
} from "constants/urls";
import { LoadingMask } from "components/Common/LoadingMask";
import { Paragraph2 } from "themes/default/_typography";
import InviteBanner from "components/UI/InviteBanner";

dayjs.extend(advancedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);
dayjs.extend(customParseFormat);
// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
const Home = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const user = useRecoilValue(userAtom);
	const [showCreateWorkoutModal, setShowCreateWorkoutModal] = useState(false);
	const [showEditWorkoutModal, setShowEditWorkoutModal] = useState(false);
	const [selectedLiveStream, setSelectedLiveStream] = useState(null);
	const [isEditingLive, setIsEditingLive] = useState(false);
	const [showScheduleLiveModal, setShowScheduleLiveModal] = useState(false);
	const [showGoLiveModal, setShowGoLiveModal] = useState(false);
	const [transfers, setTransfers] = useState(null);
	let { gym } = useParams();
	const domainGymResponse = useGetDomainGymQuery(gym);
	const domainGymId = domainGymResponse?.data?.id;

	const workoutsResponse = useGetGymWorkoutsQuery(domainGymId, { skip: !domainGymResponse.data });
	const { data: scheduledStreams } = useGetScheduledStreamsQuery(domainGymId, { skip: !domainGymResponse.data });
	const liveWorkouts = workoutsResponse.data?.workouts?.filter((workout) => workout.start_time !== null) || [];
	const [createStripeCustomerTrigger, createCustomerObj] = useCreateCustomerMutation();
	const [createStripeConnectedAccountTrigger, createConnectedAccountObj] = useCreateConnectedAccountMutation();

	const { data: statsData } = useGetStatsForGymQuery(domainGymId, {
		skip: !domainGymResponse.data,
	});

	const creatorMembershipsResponse = useGetCreatorMembershipsQuery();
	const [dateRange, setDateRange] = useState({
		startDate: moment().startOf("year"),
		endDate: moment().endOf("year"),
	});
	const { data: gymMembersData, isLoading: gymMembersLoading } = useGetGymMembersByDateQuery({
		startDate: dateRange.startDate.format("YYYY-MM-DD"),
		endDate: dateRange.endDate.format("YYYY-MM-DD"),
	});

	const [userIsSubscribed, setUserIsSubscribed] = useState(undefined);
	//const { data: userHasPlan, isLoading, error } = useGetCreatorMembershipsQuery();
	//const userHasPlan = useSelector(selectCurrentMembershipDetails);
	const [userHasPlan, setUserHasPlan] = useState(undefined);
	//const userIsSubscribed = user?.domainGym?.isSubscribed;
	const [userPayoutsEnabled, setUserPayoutsEnabled] = useState(undefined);

	const fetchCustomerConnectedExternalAccounts = async () => {
		const accountId = user.connected_account_id || createConnectedAccountObj.data.response.connectedAccountId;
		try {
			const getExternalAccountsUrl =
				process.env.REACT_APP_API_ENV === "production"
					? StripeGetCustomerConnectedExternalAccounts.prod
					: StripeGetCustomerConnectedExternalAccounts.dev;
			const bankAccountsRes = await axios.post(getExternalAccountsUrl, { accountId: accountId });
			if (bankAccountsRes.data.data.length > 0) {
				setUserPayoutsEnabled(true);
			} else {
				setUserPayoutsEnabled(false);
			}
		} catch (error) {
			console.error("ERROR: ", error);
			setUserPayoutsEnabled(false);
		}
	};

	const fetchCustomerCurrentMembershipDetails = async () => {
		try {
			const getSubscriptionsForGymUrl =
				process.env.REACT_APP_API_ENV === "production"
					? StripeGetSubscriptionsForGym.prod
					: StripeGetSubscriptionsForGym.dev;
			const gymProductResponse = await axios.post(getSubscriptionsForGymUrl, {
				gymName: domainGymResponse?.data?.name,
			});
			const data = gymProductResponse.data;
			if (data?.id) {
				setUserHasPlan(true);
			} else {
				setUserHasPlan(false);
			}
		} catch (error) {
			console.error("Error Getting Current Pricing Plan: ", error);
			setUserHasPlan(false);
		}
	};

	const fetchCustomerSubscriptions = async () => {
		try {
			const getCustomerSubscriptionsUrl =
				process.env.REACT_APP_API_ENV === "production"
					? StripeGetCustomerSubscriptions.prod
					: StripeGetCustomerSubscriptions.dev;
			const customerId = user.customer_id || createCustomerObj.data.response.customerId;
			const subscriptionsResponse = await axios.post(getCustomerSubscriptionsUrl, {
				customerId: customerId,
			});
			if (subscriptionsResponse.data.data.length > 0) {
				setUserIsSubscribed(true);
				const data = subscriptionsResponse.data.data[0].items.data[0];
			} else {
				setUserIsSubscribed(false);
			}
		} catch (error) {
			console.debug("ERROR: ", error);
			setUserIsSubscribed(false);
		}
	};

	const fetchTransfers = async () => {
		try {
			const fetchTransfersUrl =
				process.env.REACT_APP_API_ENV === "production" ? StripeFetchTransfers.prod : StripeFetchTransfers.dev;
			const transfersResponse = await axios.post(fetchTransfersUrl, {
				accountId: user?.connected_account_id || createConnectedAccountObj.data.response.connectedAccountId,
			});
			setTransfers(transfersResponse?.data || 0);
		} catch (error) {
			console.debug("ERROR: ", error);
		}
	};

	const addStripeCustomerId = async () => {
		try {
			await createStripeCustomerTrigger().unwrap();
		} catch (error) {
			console.error("Error Creating Customer: ");
		}
	};

	useEffect(() => {
		if (createCustomerObj.isSuccess) {
			notification.success({
				message: "Customer Created",
			});
			fetchCustomerSubscriptions();
		} else if (createCustomerObj.isError) {
			notification.error({
				message: "Error Creating Customer",
			});
		}
	}, [createCustomerObj]);

	const addStripeConnectedAccountId = async () => {
		try {
			await createStripeConnectedAccountTrigger().unwrap();
		} catch (error) {
			console.error("Error Creating Customer: ");
		}
	};

	useEffect(() => {
		if (createConnectedAccountObj.isSuccess) {
			notification.success({
				message: "Connected Account Created",
			});
			fetchCustomerConnectedExternalAccounts();
		} else if (createConnectedAccountObj.isError) {
			notification.error({
				message: "Error Creating Connected Account",
			});
		}
	}, [createConnectedAccountObj]);

	useEffect(() => {
		if (user) {
			if (user.customer_id) {
				fetchCustomerSubscriptions();
			} else {
				addStripeCustomerId();
			}
			if (user.connected_account_id) {
				fetchCustomerConnectedExternalAccounts();
			} else {
				addStripeConnectedAccountId();
			}
			fetchCustomerCurrentMembershipDetails();
		}
	}, [user]);

	useEffect(() => {
		if (!transfers && user?.connected_account_id) {
			fetchTransfers();
		}
	}, [transfers, user]);

	const openWorkoutCreationModal = useCallback(() => {
		// TODO: Update these with redux rtkquery
		dispatch(updateSelectedWorkout({ workout: null }));
		dispatch(updateSelectedMuxVideoUrl({ url: null }));
		setShowCreateWorkoutModal(true);
	}, [dispatch]);

	const openScheduleLiveModal = useCallback(() => {
		// TODO: Update these with redux rtkquery
		//dispatch(updateSelectedWorkout({ workout: null }));
		//dispatch(updateSelectedMuxVideoUrl({ url: null }));
		setShowScheduleLiveModal(true);
	}, [dispatch]);

	const openGoLiveModal = useCallback(
		(workout, stream) => {
			// TODO: Update these with redux rtkquery
			dispatch(updateSelectedWorkout({ workout }));
			//dispatch(updateSelectedMuxVideoUrl({ url: null }));
			setShowGoLiveModal(true);
			setSelectedLiveStream(stream);
		},
		[dispatch]
	);

	const renderOverviewCard = (title, value, timeValue, percent) => {
		return (
			<Card hoverable className="sb-creator-home__section__card">
				<p className="sb-creator-home__section__card__title">{title}</p>
				<p className="sb-creator-home__section__card__value">{value}</p>
				{/* <p className="sb-creator-home__section__card__time-value">{timeValue}</p>
				<ChangeIndicator value={percent} possitive={percent >= 0} /> */}
			</Card>
		);
	};

	const renderGoLiveCard = (workout, stream) => {
		const { name, coach, duration, categories, equipment } = workout;
		const { start_time, time_zone, currently_streaming } = stream;
		const equipmentArray = equipment.split(",");
		const handleClickEditLive = () => {
			setSelectedLiveStream(stream);
			dispatch(updateSelectedWorkout({ workout }));
			setIsEditingLive(true);
			setShowEditWorkoutModal(true);
		};
		return (
			<Card hoverable className="sb-creator-home__section__golive">
				<p className="sb-creator-home__section__golive__caption">
					live in {dayjs.tz(start_time, time_zone).toNow(true)}
				</p>
				<div className="sb-creator-home__section__golive__edit" onClick={handleClickEditLive}>
					<PencilIcon />
				</div>

				<div className="sb-creator-home__section__golive__info">
					<p className="sb-creator-home__section__golive__title">{name}</p>
					<div className="sb-creator-home__section__golive__time">
						<p className="sb-creator-home__section__golive__time__caption">
							{dayjs.tz(start_time, time_zone).format("MMMM D, YYYY h:mm A z")}{" "}
							{dayjs.tz.guess() !== time_zone
								? "(Local : " + dayjs.tz(start_time, time_zone).tz(dayjs.tz.guess()).format("h:mm A") + ") "
								: null}
							| {duration}
							mins
						</p>
					</div>
					<div className="sb-creator-home__section__golive__tags">
						<div className="sb-creator-home__section__golive__tags__tag">
							<p className="sb-creator-home__section__golive__tags__tag__caption">{coach}</p>
						</div>
						{categories.map((category) => {
							return (
								<div key={category.id} className="sb-creator-home__section__golive__tags__tag">
									<p className="sb-creator-home__section__golive__tags__tag__caption">{category.name}</p>
								</div>
							);
						})}
						{equipmentArray.map((item) => {
							return (
								<div className="sb-creator-home__section__golive__tags__tag">
									<p className="sb-creator-home__section__golive__tags__tag__caption">{item}</p>
								</div>
							);
						})}
					</div>
				</div>
				{currently_streaming === 0 ? (
					<div
						data-cy="go-live-button"
						className="sb-creator-home__section__golive__button"
						onClick={() => openGoLiveModal(workout, stream)}
					>
						Go Live
					</div>
				) : (
					<div className="sb-creator-home__section__golive__islive" onClick={() => openGoLiveModal(workout, stream)}>
						<LiveIcon fill="#FFFFFF" />
						<Paragraph2 style={{ marginBottom: 0, color: "white" }}>BROADCASTING</Paragraph2>
					</div>
				)}

				<img
					className="sb-creator-home__section__golive__image"
					alt="thumbnail"
					style={{ width: "80px", height: "80px", objectFit: "cover" }}
					src={
						domainGymResponse?.data?.light_logo
							? domainGymResponse?.data?.light_logo
							: "https://via.placeholder.com/130x80"
					}
				/>
			</Card>
		);
	};

	const renderWorkoutsCount = () => {
		if (user && user.workouts) {
			return (
				<div className="sb-creator-home__section__head__title-container__count">
					<label>{user.workouts.length}</label>
				</div>
			);
		}
	};

	const getUserData = () => {
		const users = gymMembersData?.subscribers;
		const lastMonthDate = moment().subtract(1, "months");
		const lastMonth = users?.filter((u) => lastMonthDate.isBefore(moment(u?.last_login_at)));
		return {
			total: users?.length,
			lastMonth: lastMonth?.length,
			percent: users?.length > 0 ? ((lastMonth?.length / users?.length) * 100).toFixed(1) : 0,
		};

		// if (users) {
		// 	console.log("USER !: ", users);
		// 	const lastMonth = users.filter((u) => {
		// 		const createdDate = moment(u.createdDate);
		// 		const lastMonthDate = moment().subtract(1, "months").startOf("month");

		// 		return lastMonthDate.isBefore(createdDate);
		// 	});

		// 	return {
		// 		total: users.length,
		// 		lastMonth: lastMonth.length,
		// 		percent: users.length > 0 ? ((lastMonth.length / users.length) * 100).toFixed(1) : 0,
		// 	};
		// }
	};

	const getWorkoutsData = () => {
		const workouts = user?.domainWorkouts;
		if (workouts) {
			const lastMonth = workouts.filter((w) => {
				const createdDate = moment(w.createdDate);
				const lastMonthDate = moment().subtract(1, "months").startOf("month");

				return lastMonthDate.isBefore(createdDate);
			});

			return {
				total: workouts.length,
				lastMonth: lastMonth.length,
				percent: ((lastMonth.length / workouts.length) * 100).toFixed(1),
			};
		}

		return {
			total: 0,
			lastMonth: 0,
			percent: 0,
		};
	};

	const getWorkoutStats = () => {
		const workouts = user?.domainWorkouts;

		if (workouts) {
			const lastMonth = workouts.filter((w) => {
				const createdDate = moment(w.createdDate);
				const lastMonthDate = moment().subtract(1, "months").startOf("month");

				return lastMonthDate.isBefore(createdDate);
			});

			return {
				total: workouts.length,
				lastMonth: lastMonth.length,
				percent: ((lastMonth.length / workouts.length) * 100).toFixed(1),
			};
		}

		return {
			total: 0,
			lastMonth: 0,
			percent: 0,
		};
	};

	const userData = getUserData();
	const workoutsData = getWorkoutsData();

	// Current time in UTC
	const currentTime = new Date();

	// Function to convert duration from minutes to milliseconds
	const minutesToMilliseconds = (minutes) => minutes * 60 * 1000;

	const onEditSetVisible = (bool) => {
		setShowEditWorkoutModal(bool);
		if (!bool) {
			dispatch(updateSelectedWorkout({ workout: null }));
			dispatch(updateSelectedMuxVideoUrl({ url: null }));
		}
	};

	const checkRow = ({ text, fulfilled, navigationPath, num }) => {
		return (
			<div
				className="sb-creator-home__section__banner__setup-guide__checklist__checkrow"
				style={{ justifyContent: "space-between" }}
			>
				<h1 className="sb-creator-home__section__banner__setup-guide__checklist__checkrow__text__incomplete">
					{" "}
					<div
						style={{
							height: "24px",
							width: "24px",
							borderRadius: "50%",
							color: "#FF865C",

							border: "1px solid #FF865C",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",

							textAlign: "center",
						}}
					>
						<p style={{ marginBottom: 0 }}>{num}</p>
					</div>
					{text}
				</h1>

				<div
					className="sb-creator-home__section__banner__setup-guide__checklist__checkrow__test"
					onClick={() => navigate(navigationPath)}
				>
					<ChevronRightIcon style={{ height: "16px" }} />
				</div>
			</div>
		);
	};
	const showcard = !userIsSubscribed || !userHasPlan || !userPayoutsEnabled;
	const loading = userIsSubscribed === undefined || userHasPlan === undefined || userPayoutsEnabled === undefined;
	return (
		<div className="sb-creator-home">
			<div className="sb-creator-home__section">
				<InviteBanner />
				{showcard && (
					<div className="sb-creator-home__section__banner">
						{loading ? (
							<div className="sb-creator-home__section__banner__loading">
								<LoadingMask />
							</div>
						) : (
							<>
								<div className="sb-creator-home__section__banner__edit-circle">
									<UserEditIcon />
								</div>
								<div className="sb-creator-home__section__banner__text-container">
									<h1 className="sb-creator-home__section__banner__text-container__title">
										Let's Finish setting up your account
									</h1>
									<h1 className="sb-creator-home__section__banner__text-container__subtitle">
										In order to start receiving members and payments, finish setting up your member plan and payment
										settings.
									</h1>
									<h1 className="sb-creator-home__section__banner__text-container__notice">
										* it may take up to 24 hours for your account to be approved and connected
									</h1>
								</div>
								<div className="sb-creator-home__section__banner__setup-guide">
									<h1 className="sb-creator-home__section__banner__setup-guide__title">Setup Guide</h1>
									{checkRow({
										text: "Select Pricing Plan",
										fulfilled: userIsSubscribed,
										navigationPath: "pricing-plan",
										num: 1,
									})}
									{checkRow({
										text: "Create Member Plan",
										fulfilled: userHasPlan,
										navigationPath: "pricing-plan",
										num: 2,
									})}
									{checkRow({
										text: "Setup Earnings Account",
										fulfilled: userPayoutsEnabled,
										navigationPath: "pricing-plan",
										num: 3,
									})}
								</div>
							</>
						)}
					</div>
				)}
				{scheduledStreams?.length > 0 && (
					<div className="sb-creator-home__section__head">
						<div className="sb-creator-home__section__head__title-container">
							<h1 className="sb-creator-home__section__head__title-container__title">Starting Soon</h1>
						</div>
					</div>
				)}

				{scheduledStreams &&
					scheduledStreams
						.filter((stream) => {
							// filter out streams that have already ended
							const endTime = dayjs.tz(stream.start_time, stream.time_zone).add(120, "minutes");
							return endTime.isAfter(dayjs());
						})
						.sort((a, b) => {
							const isAfter = dayjs.tz(a.start_time, a.time_zone).isAfter(dayjs.tz(b.start_time, b.time_zone));
							if (isAfter) return 1;
							else return -1;
						})
						.map((stream) => {
							const match = liveWorkouts.find((workout) => workout.id === stream.workout_id);
							if (!match) return null;
							return renderGoLiveCard(match, stream);
						})}
				<div className="sb-creator-home__section__head">
					<div className="sb-creator-home__section__head__title-container">
						<h1
							className="sb-creator-home__section__head__title-container__title"
							onClick={() => console.log("earnings: ", transfers)}
						>
							Overview
						</h1>
					</div>
				</div>
				{renderOverviewCard("EARNINGS", `$ ${(transfers / 100 || 0).toFixed(2)}` || 0, "$0 last month", 0)}
				{renderOverviewCard(
					"TOTAL MEMBERS",
					gymMembersData?.subscribers?.length + gymMembersData?.members?.length || 0,
					`${userData.lastMonth} last month`,
					userData.percent
				)}
				{renderOverviewCard(
					"WORKOUTS STARTED",
					statsData?.filter((stat) => stat?.type === "workout_start" || stat?.type === "workout_user_start").length ||
						0,
					"0 last month",
					0
				)}
				{renderOverviewCard(
					"Monthly Active Users",
					userData?.lastMonth || 0,
					`${userData?.lastMonth || 0} last month`,
					userData?.percent
				)}
			</div>
			<div className="sb-creator-home__section">
				<div className="sb-creator-home__section__head">
					<div className="sb-creator-home__section__head__title-container">
						<h1 className="sb-creator-home__section__head__title-container__title">Workout Library</h1>
						{renderWorkoutsCount()}
					</div>
					<div>
						<Button
							style={{ marginRight: "10px" }}
							data-cy="schedule-live-button"
							icon={<PlusOutlined />}
							className="sb-creator-home__section__head__button"
							onClick={openScheduleLiveModal}
						>
							Schedule Live
						</Button>
						<Button
							data-cy="new-workout-button"
							icon={<PlusOutlined />}
							className="sb-creator-home__section__head__button"
							onClick={openWorkoutCreationModal}
						>
							New Workout
						</Button>
					</div>
				</div>
				<div className="sb-creator-home__section__table">
					<WorkoutsTable setShowEditWorkoutModal={setShowEditWorkoutModal} />
				</div>
			</div>
			<CreateWorkoutModal visible={showCreateWorkoutModal} setVisible={setShowCreateWorkoutModal} />
			<EditWorkoutModal
				visible={showEditWorkoutModal}
				setVisible={onEditSetVisible}
				isEditingLive={isEditingLive}
				setIsEditingLive={setIsEditingLive}
				liveStream={selectedLiveStream}
			/>
			<ScheduleLiveModal visible={showScheduleLiveModal} setVisible={setShowScheduleLiveModal} />
			<GoLiveModal visible={showGoLiveModal} setVisible={setShowGoLiveModal} liveStream={selectedLiveStream} />
		</div>
	);
};

export default Home;
