// -----------------------------------------------------------------------------
// Library
// -----------------------------------------------------------------------------
import React from "react";
import { useSelector } from "react-redux";

// -----------------------------------------------------------------------------
// Components, helpers, styles and selectors
// -----------------------------------------------------------------------------
import { WorkoutCard } from "components/Workouts/WorkoutCard";
import {
	selectMembersWorkoutsSearchTerm,
	selectMembersWorkoutsSelectedCategories,
	selectDomainWorkouts,
} from "store/selectors";
import StyledWorkoutsList from "./styles";
import { useGetDomainGymQuery, useGetGymWorkoutsQuery } from "store/api/api";
import { useNavigate, useParams } from "react-router";
import moment from "moment";

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
const WorkoutsList = () => {
	const navigate = useNavigate();
	const { gym } = useParams();
	const domainGymResponse = useGetDomainGymQuery(gym);
	const domainGymId = domainGymResponse?.data?.id;

	const workoutsResponse = useGetGymWorkoutsQuery(domainGymId, { skip: !domainGymResponse.data });
	const workouts = workoutsResponse.data?.workouts || [];

	const categoriesFilters = useSelector(selectMembersWorkoutsSelectedCategories);
	const searchTerm = useSelector(selectMembersWorkoutsSearchTerm);

	//const workouts = useSelector(selectDomainWorkouts);

	const handleFileSelected = (workoutId) => {
		navigate(`../view-workout/${workoutId}`);
	};

	if (!workouts || !workouts.length) {
		return <div>No Workouts</div>;
	}

	return (
		<StyledWorkoutsList className="sb-workouts">
			{workouts
				.filter(
					(workout) =>
						workout.draft === 0 &&
						(categoriesFilters.includes(0) || workout.categories.some((c) => categoriesFilters.includes(c.category_id)))
				)
				.filter((workout) => workout.duration !== null && workout.duration !== 0)
				.filter((workout) => workout.start_time === null || moment(workout.start_time).isBefore(moment()))
				.filter((workout) => workout.playback_id !== null)
				.filter((workout) => (searchTerm !== "" ? workout.name.toLowerCase().startsWith(searchTerm) : true))
				.sort((a, b) => new Date(b.created_date) - new Date(a.created_date))
				.map((workout) => (
					<div key={workout.id} onClick={() => handleFileSelected(workout.id)}>
						<WorkoutCard title={workout.name} categories={workout.categories} headerImage={workout.image_url} />
					</div>
				))}
		</StyledWorkoutsList>
	);
};

export default WorkoutsList;
