// -----------------------------------------------------------------------------
// Library
// -----------------------------------------------------------------------------
import React from "react";

// -----------------------------------------------------------------------------
// Utils and Styles
// -----------------------------------------------------------------------------
import StyledAnalytics from "./styles";
import { Button, Card, Dropdown, Space } from "antd";
import AnalyticsTable from "./AnalyticsTable";
import {
	useGetCategoriesQuery,
	useGetDomainGymQuery,
	useGetGymWorkoutsQuery,
	useGetStatsForGymQuery,
} from "store/api/api";
import { useParams } from "react-router";
import { CaretDownFilled } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { updateMembersWorkoutsSelectedCategories } from "store/slices";
import { selectMembersWorkoutsSelectedCategories } from "store/selectors";

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
function Analytics() {
	const dispatch = useDispatch();
	let { gym } = useParams();
	const domainGymResponse = useGetDomainGymQuery(gym);
	const domainGymId = domainGymResponse?.data?.id;
	const [selectedCategories, setSelectedCategories] = React.useState([]);
	const [timeFrame, setTimeFrame] = React.useState("All Time");
	const categoriesFilters = useSelector(selectMembersWorkoutsSelectedCategories);
	const categoriesResponse = useGetCategoriesQuery(domainGymId, { skip: !domainGymResponse.data });
	const categories = categoriesResponse.data?.categories || [];
	const [homeCardData, setHomeCardData] = React.useState({
		videoViews: 0,
		activeUsers: 0,
		songPlays: 0,
		avgViewsPerUser: 0,
	});

	const { data: statsData } = useGetStatsForGymQuery(domainGymId, {
		skip: !domainGymResponse.data,
	});

	const workoutsResponse = useGetGymWorkoutsQuery(domainGymId, { skip: !domainGymResponse.data });
	const [workouts, setWorkouts] = React.useState([]);

	const handleTimeClick = (timeframe) => {
		setTimeFrame(timeframe.key);
	};

	const handleCategoryClick = (category) => {
		const category_id = parseInt(category.key);

		if (!selectedCategories.includes(category_id)) {
			if (category_id === 0) {
				dispatch(updateMembersWorkoutsSelectedCategories([0]));
			} else {
				if (selectedCategories.includes(0)) {
					dispatch(updateMembersWorkoutsSelectedCategories([category_id]));
				} else {
					dispatch(updateMembersWorkoutsSelectedCategories([...selectedCategories, category_id]));
				}
			}
		} else if (category_id !== 0) {
			const newCategories = [...selectedCategories];

			newCategories.splice(
				selectedCategories.findIndex((c) => c === category_id),
				1
			);

			dispatch(updateMembersWorkoutsSelectedCategories(newCategories.length > 0 ? newCategories : [0]));
		}
	};

	const categoryItems = categories.map((category) => {
		return {
			label: category.name,
			key: category.id,
			danger: categoriesFilters.includes(category.id) ? true : false,
		};
	});

	const categoriesMenu = {
		items: [
			{
				label: "All Categories",
				key: 0,
				danger: categoriesFilters.includes(0) ? true : false,
			},
			...categoryItems,
		],
		onClick: handleCategoryClick,
	};

	const timeframeMenu = {
		items: [
			{
				label: "All Time",
				key: "All Time",
				danger: timeFrame === "All Time" ? true : false,
			},
			{
				label: "Last Week",
				key: "Last Week",
				danger: timeFrame === "Last Week" ? true : false,
			},
			{
				label: "Last 30 Days",
				key: "Last 30 Days",
				danger: timeFrame === "Last 30 Days" ? true : false,
			},
			{
				label: "Last 3 Months",
				key: "Last 3 Months",
				danger: timeFrame === "Last 3 Months" ? true : false,
			},
			{
				label: "Last 6 Months",
				key: "Last 6 Months",
				danger: timeFrame === "Last 6 Months" ? true : false,
			},
			{
				label: "Last Year",
				key: "Last Year",
				danger: timeFrame === "Last Year" ? true : false,
			},
		],
		onClick: handleTimeClick,
	};

	const renderOverviewCard = (title, value, timeValue, percent) => {
		return (
			<Card hoverable className="sb-creator-home__section__card">
				<p className="sb-creator-home__section__card__title">{title}</p>
				<p className="sb-creator-home__section__card__value">{value}</p>
				{/* <p className="sb-creator-home__section__card__time-value">{timeValue}</p>
				<ChangeIndicator value={percent} possitive={percent >= 0} /> */}
			</Card>
		);
	};

	const categoryName = (category_id) => {
		const category = categories.find((c) => c.id === category_id);
		return category ? category.name : "All Categories";
	};

	const renderCategoryDropDown = () => {
		return (
			<Space.Compact size="large">
				<Dropdown menu={categoriesMenu}>
					<Button
						style={{
							borderColor: " #E3E5E7",
							textTransform: "none",
							color: "#4F5661",
							fontWeight: "700",
							fontSize: "14px",
							lineHeight: "24px",
							fontFamily: "DM Sans",
						}}
					>
						{categoryName(categoriesFilters[0])}

						<CaretDownFilled
							style={{
								color: "#4F5661",

								fontSize: "12px",
								lineHeight: "0px",
							}}
						/>
					</Button>
				</Dropdown>
			</Space.Compact>
		);
	};

	const renderTimeFrameDropDown = () => {
		return (
			<Space.Compact size="large">
				<Dropdown menu={timeframeMenu}>
					<Button
						style={{
							borderColor: " #E3E5E7",
							textTransform: "none",
							color: "#4F5661",
							fontWeight: "700",
							fontSize: "14px",
							lineHeight: "24px",
							fontFamily: "DM Sans",
						}}
					>
						Time Frame: {timeFrame}
						<CaretDownFilled
							style={{
								color: "#4F5661",

								fontSize: "12px",
								lineHeight: "0px",
							}}
						/>
					</Button>
				</Dropdown>
			</Space.Compact>
		);
	};

	React.useEffect(() => {
		if (statsData) {
			// Filter Workouts Data By Category
			const filteredWorkouts = workouts.filter(
				(workout) =>
					categoriesFilters.includes(0) || workout.categories.some((c) => categoriesFilters.includes(c.category_id))
			);

			// Filter Stats Data By Time Frame
			const statsInTimeFrame = statsData.filter((stat) => {
				if (timeFrame === "Last 30 Days") {
					return new Date(stat.createdDate) > new Date(new Date().setDate(new Date().getDate() - 30));
				} else if (timeFrame === "Last Week") {
					return new Date(stat.createdDate) > new Date(new Date().setDate(new Date().getDate() - 7));
				} else if (timeFrame === "Last 3 Months") {
					return new Date(stat.createdDate) > new Date(new Date().setDate(new Date().getDate() - 90));
				} else if (timeFrame === "Last 6 Months") {
					return new Date(stat.createdDate) > new Date(new Date().setDate(new Date().getDate() - 180));
				} else if (timeFrame === "Last Year") {
					return new Date(stat.createdDate) > new Date(new Date().setDate(new Date().getDate() - 365));
				} else {
					return true;
				}
			});

			// Filter Stats Data By Workouts
			const statsInTimeFrameFiltered = statsInTimeFrame.filter((stat) =>
				filteredWorkouts.map((workout) => workout.key).includes(stat.target)
			);

			// Update Home Card Data
			const totalViews = statsInTimeFrameFiltered.filter(
				(stat) => stat.type === "workout_start" || stat.type === "workout_user_start"
			).length;

			const uniqueUserIds = new Set(statsInTimeFrameFiltered.map((stat) => stat.userId));

			// Count the number of unique user IDs
			const uniqueUserCount = uniqueUserIds.size;

			const toSet = {
				videoViews: totalViews,
				activeUsers: uniqueUserCount,
				songPlays: statsInTimeFrameFiltered.filter((stat) => stat.type === "song_play").length,
				avgViewsPerUser: (totalViews / uniqueUserCount).toFixed(2),
			};

			setHomeCardData(toSet);
		}
	}, [statsData, timeFrame, workouts, categoriesFilters]);

	React.useEffect(() => {
		if (workoutsResponse.data) {
			setWorkouts(workoutsResponse.data.workouts);
		}
	}, [workoutsResponse]);

	return (
		<StyledAnalytics className="sb-analytics">
			<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingBottom: "16px" }}>
				<h1>Analytics</h1>
				<div style={{ display: "flex", justifyContent: "space-between", gap: "16px" }}>
					{renderCategoryDropDown()}
					{renderTimeFrameDropDown()}
				</div>
			</div>
			<div
				style={{
					display: "flex",
					justifyContent: "left",
					//justifyContent: "space-between"
				}}
			>
				{renderOverviewCard(
					"VIDEO VIEWS",
					homeCardData["videoViews"]
					//"2000 last month",
					//32.84
				)}
				{renderOverviewCard("TOTAL ACTIVE USERS", homeCardData["activeUsers"], `98 last month`, 32.84)}
				{/* {renderOverviewCard("SONG PLAYS", "293", "223", 32.84)}
				{renderOverviewCard("AVG. VIEWS PER USER", "1.5", `10`, 32.84)} */}
				{renderOverviewCard("AVG. VIEWS PER USER", homeCardData["avgViewsPerUser"], `98 last month`, 32.84)}
			</div>
			<div style={{ display: "flex", justifyContent: "left", paddingTop: "16px" }}>
				<h1>Most Popular Workouts</h1>
			</div>
			<AnalyticsTable timeFrame={timeFrame} />
		</StyledAnalytics>
	);
}

export default Analytics;
