import styled from "styled-components";

const StyledSubscribersCard = styled.div`
	width: 100%;

	.sb-subscribers-card {
		border: 1px solid ${({ theme }) => theme.colors.iron};
		border-radius: 6px;

		.ant-card-body {
			display: flex;
			align-items: center;
			justify-content: center;
		}

		&__column {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			gap: 8px;
		}
	}
`;

export default StyledSubscribersCard;
