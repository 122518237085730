import React from "react";
import Button from "antd/lib/button";
import ajaxRoutes from "../../utils/ajaxRoutes";
import Ajax from "../../utils/api";
import notification from "antd/lib/notification";
import { getUser } from "../../useInit";
import { userAtom } from "../../globalAtoms";
import { useSetRecoilState } from "recoil";
import { useNavigate } from "react-router";

function TwoFaForm({ potentialUser, updatePotentialUser, updateLoggingIn, gymId }) {
	const [twoFaField, updateTwoFaField] = React.useState("");
	const [loading, updateLoading] = React.useState(false);
	const setUser = useSetRecoilState(userAtom);
	const navigate = useNavigate();

	const checkCode = async () => {
		updateLoading(true);

		const response = await Ajax.call(
			ajaxRoutes.CHECK_TWO_FACTOR_AUTHENTICATION,
			"post",
			{
				code: twoFaField,
				gym_id: gymId,
				user: potentialUser,
			},
			() => {
				updatePotentialUser(null);
				updateLoading(false);
				updateLoggingIn(false);
				notification.error({ message: "Incorrect code entered" });
			}
		);

		if (response?.auth_token) {
			localStorage.setItem("creator_jwt_token", response.auth_token);
			await getUser(setUser);
			updateLoggingIn(false);
			return navigate("/dashboard");
		}

		updateLoading(false);
	};

	let form = (
		<div>
			<h1>2-Step Verification</h1>
			<p>A text message with a verification code was just sent to {potentialUser.phoneNumber}</p>
			<input
				onChange={(e) => updateTwoFaField(e.target.value)}
				placeholder={"Enter the code"}
				className="input-field"
				type={"text"}
			/>
			<Button style={{ marginTop: 20 }} loading={loading} onClick={checkCode} type="primary">
				Done
			</Button>
		</div>
	);

	let loadingScreen = <div>Loading...</div>;

	return potentialUser.skip2fa ? loadingScreen : form;
}

export default TwoFaForm;
