import React from "react";

const CalendarLiveIcon = (params) => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M19 4H17V3C17 2.73478 16.8946 2.48043 16.7071 2.29289C16.5196 2.10536 16.2652 2 16 2C15.7348 2 15.4804 2.10536 15.2929 2.29289C15.1054 2.48043 15 2.73478 15 3V4H9V3C9 2.73478 8.89464 2.48043 8.70711 2.29289C8.51957 2.10536 8.26522 2 8 2C7.73478 2 7.48043 2.10536 7.29289 2.29289C7.10536 2.48043 7 2.73478 7 3V4H5C4.20435 4 3.44129 4.31607 2.87868 4.87868C2.31607 5.44129 2 6.20435 2 7V19C2 19.7957 2.31607 20.5587 2.87868 21.1213C3.44129 21.6839 4.20435 22 5 22H19C19.7957 22 20.5587 21.6839 21.1213 21.1213C21.6839 20.5587 22 19.7957 22 19V7C22 6.20435 21.6839 5.44129 21.1213 4.87868C20.5587 4.31607 19.7957 4 19 4ZM20 19C20 19.2652 19.8946 19.5196 19.7071 19.7071C19.5196 19.8946 19.2652 20 19 20H5C4.73478 20 4.48043 19.8946 4.29289 19.7071C4.10536 19.5196 4 19.2652 4 19V12H20V19ZM20 12H4V7C4 6.73478 4.10536 6.48043 4.29289 6.29289C4.48043 6.10536 4.73478 6 5 6H7V7C7 7.26522 7.10536 7.51957 7.29289 7.70711C7.48043 7.89464 7.73478 8 8 8C8.26522 8 8.51957 7.89464 8.70711 7.70711C8.89464 7.51957 9 7.26522 9 7V6H15V7C15 7.26522 15.1054 7.51957 15.2929 7.70711C15.4804 7.89464 15.7348 8 16 8C16.2652 8 16.5196 7.89464 16.7071 7.70711C16.8946 7.51957 17 7.26522 17 7V6H19C19.2652 6 19.5196 6.10536 19.7071 6.29289C19.8946 6.48043 20 6.73478 20 7V12Z"
			fill="#F2994A"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M15.2083 12.5417H12.9583V10.2917C12.9583 10.0375 12.8573 9.79374 12.6776 9.61402C12.4978 9.4343 12.2541 9.33333 11.9999 9.33333C11.7458 9.33333 11.502 9.4343 11.3223 9.61402C11.1426 9.79374 11.0416 10.0375 11.0416 10.2917V12.5417H8.79159C8.53742 12.5417 8.29366 12.6426 8.11394 12.8224C7.93422 13.0021 7.83325 13.2458 7.83325 13.5C7.83325 13.7542 7.93422 13.9979 8.11394 14.1776C8.29366 14.3574 8.53742 14.4583 8.79159 14.4583H11.0416V16.7083C11.0416 16.9625 11.1426 17.2063 11.3223 17.386C11.502 17.5657 11.7458 17.6667 11.9999 17.6667C12.2541 17.6667 12.4978 17.5657 12.6776 17.386C12.8573 17.2063 12.9583 16.9625 12.9583 16.7083V14.4583H15.2083C15.4624 14.4583 15.7062 14.3574 15.8859 14.1776C16.0656 13.9979 16.1666 13.7542 16.1666 13.5C16.1666 13.2458 16.0656 13.0021 15.8859 12.8224C15.7062 12.6426 15.4624 12.5417 15.2083 12.5417ZM15.5323 13.1759C15.4464 13.09 15.3298 13.0417 15.2083 13.0417H12.4583V10.2917C12.4583 10.1701 12.41 10.0535 12.324 9.96757C12.2381 9.88162 12.1215 9.83333 11.9999 9.83333C11.9923 9.83333 11.9847 9.83352 11.9772 9.83389C11.9847 9.83352 11.9923 9.83333 11.9999 9.83333C12.1215 9.83333 12.238 9.88162 12.324 9.96758C12.41 10.0535 12.4582 10.1701 12.4582 10.2917V13.0417H15.2082C15.3298 13.0417 15.4464 13.09 15.5323 13.1759C15.6183 13.2619 15.6666 13.3784 15.6666 13.5C15.6666 13.6216 15.6183 13.7381 15.5323 13.8241C15.5234 13.833 15.5141 13.8416 15.5046 13.8497C15.5141 13.8416 15.5234 13.833 15.5323 13.8241C15.6183 13.7381 15.6666 13.6216 15.6666 13.5C15.6666 13.3784 15.6183 13.2619 15.5323 13.1759ZM12.4583 13.9583H12.4582V16.7083C12.4582 16.8299 12.41 16.9465 12.324 17.0324C12.3171 17.0393 12.31 17.0459 12.3028 17.0523C12.31 17.0459 12.3171 17.0393 12.324 17.0324C12.41 16.9465 12.4583 16.8299 12.4583 16.7083V13.9583ZM8.65829 13.0615C8.70111 13.0485 8.746 13.0417 8.79158 13.0417H11.5416V13.0417H8.79159C8.746 13.0417 8.70112 13.0485 8.65829 13.0615Z"
			fill="#F2994A"
		/>
	</svg>
);

export default CalendarLiveIcon;

