// -------------------------------------------------------------------------------
// Libraries
// -------------------------------------------------------------------------------
import React from "react";

// -----------------------------------------------------------------------------
// Components
// -----------------------------------------------------------------------------
import { Paragraph31 } from "themes/default/_typography";
import { FullLogoIcon } from "components/Icons/FullLogoIcon";

// -----------------------------------------------------------------------------
// Styles
// -----------------------------------------------------------------------------
import StyledFooter from "./styles";
import { getClientHost } from "helpers/constants";

// -------------------------------------------------------------------------------
// Component
// -------------------------------------------------------------------------------

const host = getClientHost(process.env.REACT_APP_API_ENV);

const Footer = () => {
	return (
		<StyledFooter className="sb-footer">
			<div className="sb-footer__logo-section">
				<FullLogoIcon />
			</div>
			<div className="sb-footer__copyright-section">
				<Paragraph31 onClick={() => console.log("HOST: ", host, process.env.REACT_APP_API_ENV)}>
					© Copyright 2012 - 2022. All Rights Reserved.
				</Paragraph31>
			</div>
			<div style={{ display: "flex", justifyContent: "center", gap: "16px", padding: "16px", paddingTop: 0 }}>
				<a href={`${host}privacy`} target="_blank" rel="noreferrer">
					<Paragraph31>Privacy Policy</Paragraph31>
				</a>
				<a href={`${host}tos`} target="_blank" rel="noreferrer">
					<Paragraph31>Terms & Conditions</Paragraph31>
				</a>
			</div>
		</StyledFooter>
	);
};

export default Footer;

