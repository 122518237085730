// -----------------------------------------------------------------------------
// Library
// -----------------------------------------------------------------------------
import React, { useState, useEffect } from "react";
import { Redirect, redirect, useLocation, useNavigate, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { useDispatch, useSelector } from "react-redux";
import Ajax from "../../utils/api";
import ajaxRoutes from "../../utils/ajaxRoutes.js";

// -------------------------------------------------------------------------------
// Store
// -------------------------------------------------------------------------------
import {
	selectCreatorAccount,
	selectDomainGym,
	selectLoginLoading,
	selectAuthToken,
	selectCreatorProduct,
} from "store/selectors";
import { autoLogin } from "store/slices";

// -----------------------------------------------------------------------------
// Components and helpers
// -----------------------------------------------------------------------------
import getDashboardUrl from "../../utils/getDashboardUrl";
import { userAtom } from "../../globalAtoms";
import LoginForm from "./LoginForm";
import SignUpForm from "./SignUpForm";
import Logo from "../../images/logo.svg";
import axios from "axios";
import {
	useGetCreatorAccountQuery,
	useGetCreatorProductQuery,
	useGetDomainGymQuery,
	useGetPagesQuery,
	useLazyGetCreatorAccountQuery,
	useLazyGetCreatorProductQuery,
	useLazyGetPagesQuery,
} from "store/api/api";
import { StripeGetSubscriptionsForGym } from "constants/urls";

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
const Login = ({ preview, type }) => {
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch();
	const [pages, setPages] = useState(null);
	const [gymId, setGymId] = useState(null);
	const [paymentsReady, setPaymentsReady] = useState(null);
	let { gym } = useParams();
	const { data: domainGymData, isLoading: domainGymisLoading, error: domainGymError } = useGetDomainGymQuery(gym);
	const domainGym = useSelector(selectDomainGym);
	// const creatorAccountResponse = useGetCreatorAccountQuery(domainGymData?.id, {
	// 	skip: !domainGymData?.id,
	// });
	const [creatorAccountTrigger, creatorAccountResult, creatorAccountLastPromiseInfo] = useLazyGetCreatorAccountQuery(
		{}
	);
	// const creatorProductResponse = useGetCreatorProductQuery(domainGymData?.id, {
	// 	skip: !domainGymData?.id,
	// });
	const [creatorProductTrigger, creatorProductResult, creatorProductLastPromiseInfo] = useLazyGetCreatorProductQuery(
		{}
	);
	// const pagesQueryResponse = useGetLazyPagesQuery(domainGymData?.id, {
	// 	skip: !domainGymData?.id,
	// });
	const [trigger, result, lastPromiseInfo] = useLazyGetPagesQuery({});

	const loginLoading = useSelector(selectLoginLoading);
	const authToken = useSelector(selectAuthToken);
	const user = useRecoilValue(userAtom);
	const imageReplacementString =
		process.env.REACT_APP_API_ENV === "production" ? "cdn.sweatbase.com" : "sweatbase-staging.s3.amazonaws.com";

	useEffect(() => {
		async function getDomain() {
			if (gym) {
				const gymRes = await Ajax.call(ajaxRoutes.DOMAINS_LOOKUP(gym));
				// const pagesRes = await Ajax.call(ajaxRoutes.GET_PAGES(gymRes.gym_id), "get");
				// if (pagesRes?.length > 0) {
				// 	if (pagesRes[0].header_image?.includes("cdn.sweatbase.com")) {
				// 		pagesRes[0].header_image = pagesRes[0].header_image.replace("cdn.sweatbase.com", imageReplacementString);
				// 	}
				// }

				console.log("domaingymheader", domainGymData?.data);
				// setPages(pagesRes);
				// setGymId(gymRes.gym_id);
			} else {
				// var hostname = window.location.hostname;
				// const gymRes = await Ajax.call(ajaxRoutes.DOMAINS_LOOKUP(hostname));
				// const pagesRes = await Ajax.call(ajaxRoutes.GET_PAGES(gymRes.gym_id), "get");
				// setPages(pagesRes);
				// setGymId(gymRes.gym_id);
			}
		}

		if (!user) {
			//getDomain();
		}
	}, [user]);

	useEffect(() => {
		if (!user && domainGym?.autologin && !loginLoading) {
			dispatch(autoLogin({ gym_id: domainGym.id }));
		}
	}, [user, domainGym, dispatch, loginLoading]);

	useEffect(() => {
		if (result) {
			console.log("result: ", result);
			if (result?.data) {
				setPages(result?.data);
			}
		}
		// if (pagesQueryResponse?.data) {
		// 	console.log("pagesQueryResponse.data: ", pagesQueryResponse?.data);
		// }
		//setPages(pagesQueryResponse?.data);
		// } else if (pagesQueryResponse?.status === "uninitialized") {
		// 	console.log("pagesQueryResponse uninitialized: ", pagesQueryResponse, domainGymData?.id);
		// 	//pagesQueryResponse.refetch();
		// }
	}, [trigger, result, lastPromiseInfo, domainGymData?.id]);

	useEffect(() => {
		if (creatorProductResult) {
			console.log("creatorProductResult: ", creatorProductResult);
		}
	}, [creatorProductTrigger, creatorProductResult, creatorProductLastPromiseInfo, domainGymData?.id]);
	useEffect(() => {
		if (creatorAccountTrigger) {
			console.log("creatorAccountTrigger: ", creatorAccountTrigger);
		}
	}, [creatorAccountTrigger, creatorAccountResult, creatorAccountLastPromiseInfo, domainGymData?.id]);
	useEffect(() => {
		if (!loginLoading && authToken) {
			localStorage.setItem("creator_jwt_token", authToken);
			window.location = window.location.origin + "/dashboard";
		}
	}, [loginLoading, authToken]);

	// useEffect(() => {
	// 	if (
	// 		creatorAccountResponse?.data?.stripeAccount?.capabilities?.transfers === "active" ||
	// 		creatorProductResponse?.data?.price_id === "free"
	// 	) {
	// 		console.log("PAYMENTS READY");
	// 		setPaymentsReady(true);
	// 	} else {
	// 		console.log("Payments Not Ready: ", creatorAccountResponse, domainGymData);
	// 	}
	// }, [domainGymData, creatorAccountResponse, creatorProductResponse]);

	const fetchGymProduct = async () => {
		const getSubscriptionsForGymUrl =
			process.env.REACT_APP_API_ENV === "production"
				? StripeGetSubscriptionsForGym.prod
				: StripeGetSubscriptionsForGym.dev;
		console.log("CheckDomainGymData: ", domainGymData);
		const gymProductResponse = await axios.post(getSubscriptionsForGymUrl, {
			gymName: domainGymData.name,
		});
		if (gymProductResponse?.data) {
			if (gymProductResponse?.data?.unit_amount >= 0 && gymProductResponse?.data?.transfers_enabled) {
				setPaymentsReady(true);
			}
		} else if (gymProductResponse?.data === "No Gym Subscription Found") {
			setPaymentsReady(false);
		} else {
			console.debug("Error fetching gym product", gymProductResponse?.data);
			setPaymentsReady(false);
		}
	};

	const fetchPages = async (gymID) => {
		console.log("fetching pages:", gymID);
		const loadSearch = await trigger(gymID).unwrap();
	};
	const lazyFetchGymProduct = async (gymID) => {
		console.log("lazyFetchGymProduct:", gymID);
		const loadSearch = await creatorProductTrigger({ gymId: gymID }).unwrap();
	};
	const lazyFetchGymAccount = async (gymID) => {
		console.log("lazyFetchGymAccount:", gymID);
		const loadSearch = await creatorAccountTrigger({ gymId: gymID }).unwrap();
	};

	useEffect(() => {
		if (domainGymData?.id) {
			fetchGymProduct();
			console.log("refetching pages: ", domainGymData?.id);
			fetchPages(domainGymData?.id);
			console.log("refetching gym products: ", domainGymData?.id);
			lazyFetchGymProduct(domainGymData?.id);
			//pagesQueryResponse.refetch();
			console.log("refetching gym account: ", domainGymData?.id);
			lazyFetchGymAccount(domainGymData?.id);
		}
	}, [domainGymData]);

	const page = user ? user.pages?.find((p) => p.type === "sign_up") : pages?.find((p) => p.type === "sign_up");
	const headerImage = page?.header_image
		? {
				backgroundImage: `url(${page?.header_image.replace("cdn.sweatbase.com", imageReplacementString)})`,
		  }
		: null;

	if (location.search.includes("preview")) {
		preview = true;
	}

	useEffect(() => {
		if (user && !preview) {
			navigate(`/${gym}/dashboard`);
			//redirect(getDashboardUrl());
		}
	}, [user]);
	const renderLoginSection = () => {
		return (
			<div className="sb-login__container">
				<div className="sb-login__container__form-container">
					{renderFormHeader()}
					{type === "signup" ? (
						paymentsReady ? (
							<SignUpForm preview={preview} />
						) : (
							<SignUpForm preview={true} />
						)
					) : (
						<LoginForm preview={preview} gymId={gymId} />
					)}
					<div className="sb-login__container__form-container__notes">
						<span className="sb-login__container__form-container__notes__first">
							Are you a gym, trainer, or studio and want to offer content to your members?
						</span>
						<a href="/creator-creation" className="sb-login__container__form-container__notes__last">
							LEARN MORE
						</a>
					</div>
				</div>
				<div className="sb-login__container__image-container">
					<div className="sb-login__container__image-container__inner">
						<div className="sb-login__container__image-container__inner__image" style={headerImage} />
					</div>
				</div>
			</div>
		);
	};

	const renderFormHeader = () => {
		let headerText = type === "signup" ? "Create Your Account" : "Login";
		let subheaderText = type === "signup" ? "Enter your info below" : "Enter your login information below";

		if (page?.sub_text) {
			subheaderText = page.sub_text;
			headerText = page.header_text;
		}

		return (
			<div className="sb-login__container__form-container__header">
				<img className="sb-login__container__form-container__header__image" src={Logo} alt="logo" />
				<h1 className="sb-login__container__form-container__header__title">{headerText}</h1>
				<p className="sb-login__container__form-container__header__subtitle">{subheaderText}</p>
			</div>
		);
	};

	return <div className="sb-login">{renderLoginSection()}</div>;
};

export default Login;
