import styled from "styled-components";

const StyledPaymentSettingsPage = styled.div`
	position: relative;
	height: 100%;

	.sb-payment-settings-page {
		&__inner {
			padding: 80px;
			max-width: 700px;
			margin: 0 auto;
			height: 100%;

			&__heading {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				gap: 16px;
				margin-bottom: 60px;

				&__button {
					margin-top: 32px;
				}
			}
		}
	}
`;

export default StyledPaymentSettingsPage;
