import styled from "styled-components";

const StyledPlanInformationCard = styled.div`
	.sb-plan-information-card {
		display: flex;
		align-items: center;
		justify-content: center;

		&__up {
			display: flex;
			align-items: flex-end;
			justify-content: center;

			&__frecuency {
				padding-bottom: 6px;
				margin-left: 2px;
			}
		}

		&__down {
			padding-top: 6px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
`;

export default StyledPlanInformationCard;
