// -----------------------------------------------------------------------------
// Library
// -----------------------------------------------------------------------------
import React, { useEffect, useState } from "react";

// -----------------------------------------------------------------------------
// Components, utils and Styles
// -----------------------------------------------------------------------------
import { Button } from "components/Common/Button";
import { LoadingMask } from "components/Common/LoadingMask";
import { ImageInputCard } from "components/Common/Cards/ImageInputCard";
import { ColorPickerCard } from "components/Common/Cards/ColorPickerCard";
import { Paragraph2 } from "themes/default/_typography";
import StyledBrandingForm from "./styles";
import {
	useCreateGymAssetMutation,
	useCreateGymBrandingColorsMutation,
	useGetDomainGymQuery,
	useUpdateGymBrandingColorsMutation,
} from "store/api/api";
import { useParams } from "react-router";
import { notification } from "antd";

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
const BrandingForm = (props) => {
	const { gym } = useParams();
	const domainGymResponse = useGetDomainGymQuery(gym);
	const domainGym = domainGymResponse?.data;
	const gymSaving = domainGymResponse?.isLoading;
	const gymId = domainGym?.id;
	const [selectedColor, setSelectedColor] = useState(domainGym?.brand_color1);
	const [selectedImageDark, setSelectedImageDark] = useState(domainGym?.dark_logo);
	const [selectedImageLight, setSelectedImageLight] = useState(domainGym?.light_logo);

	const [updateGymBrandingColorsTrigger, updateGymBrandingColorsResponse] = useUpdateGymBrandingColorsMutation();
	const [createGymBrandingColorsTrigger, createGymBrandingColorsResponse] = useCreateGymBrandingColorsMutation();
	const [createGymAssetTrigger, createGymAssetResponse] = useCreateGymAssetMutation();

	useEffect(() => {
		setSelectedColor(domainGym?.brand_color1);
	}, [domainGym]);

	const updateGymBrandingColors = async (brandColor) => {
		// NOTE: USING GYMID & GYM_ID BECAUSE OF A BUG IN THE API
		// SEE GYMS_SERVICE EDIT BRANDING LINE 50 ("edit_gym_branding("${json.gymId}"")
		// VS GYMS_CONTROLLER EDIT BRANDING LINE 208 (return Promise.resolve({ response: { gym: result.gym_id }, status: 200 });)
		const update = await updateGymBrandingColorsTrigger({
			gymId: gymId,
			gym_id: gymId,
			color1: brandColor,
		}).unwrap();
	};

	const createGymBrandingColors = async (brandColor) => {
		// NOTE: USING GYMID & GYM_ID BECAUSE OF A BUG IN THE API
		// SEE GYMS_SERVICE EDIT BRANDING LINE 50 ("edit_gym_branding("${json.gymId}"")
		// VS GYMS_CONTROLLER EDIT BRANDING LINE 208 (return Promise.resolve({ response: { gym: result.gym_id }, status: 200 });)
		const create = await createGymBrandingColorsTrigger({
			gymId: gymId,
			gym_id: gymId,
			color1: brandColor,
		}).unwrap();
	};

	useEffect(() => {
		if (updateGymBrandingColorsResponse.isSuccess) {
			domainGymResponse.refetch();
			if (props.refetch) props.refetch();
			notification.success({
				message: "Brand Color updated successfully",
			});
		} else if (updateGymBrandingColorsResponse.isError) {
			notification.error({
				message: "Something went wrong updating your colors",
			});
		}
	}, [updateGymBrandingColorsResponse]);

	useEffect(() => {
		if (createGymBrandingColorsResponse.isSuccess) {
			domainGymResponse.refetch();
			if (props.refetch) props.refetch();
			notification.success({
				message: "Brand color saved successfully",
			});
		} else if (createGymBrandingColorsResponse.isError) {
			notification.error({
				message: "Something went wrong saving your colors",
			});
		}
	}, [createGymBrandingColorsResponse]);

	useEffect(() => {
		if (createGymAssetResponse.isSuccess) {
			notification.success({
				message: "Image saved successfully",
			});
		} else if (createGymAssetResponse.isError) {
			notification.error({
				message: "Something went wrong saving your image",
			});
		}
	}, [createGymAssetResponse]);

	const saveBrandingChanges = () => {
		const darkImage = typeof selectedImageDark === "object" ? selectedImageDark : null;
		if (darkImage) {
			// Save dark image
			saveBrandingImage(darkImage, "dark");
		}

		const lightImage = typeof selectedImageLight === "object" ? selectedImageLight : null;
		if (lightImage) {
			saveBrandingImage(lightImage, "light");
		}
		const brandColor = domainGym?.brand_color1 !== selectedColor ? selectedColor : null;
		// NO CURRENT BRAND COLOR MUST CREATE INSTEAD OF UPDATE:
		if (!domainGym?.brand_color1 && selectedColor) {
			createGymBrandingColors(selectedColor);
		}
		if (brandColor) {
			updateGymBrandingColors(brandColor);
		}
	};

	const saveBrandingImage = async (image, type) => {
		const jwt = localStorage.getItem("creator_jwt_token");
		const formData = new FormData();
		formData.append("image", image);
		formData.append("gym_id", gymId);
		formData.append("type", type);
		try {
			await createGymAssetTrigger({ formData, jwt }).unwrap();
		} catch (error) {
			console.error("error: ", error);
			notification.error({
				message: "Something went wrong saving your image",
			});
		}
	};

	return (
		<StyledBrandingForm>
			<div className="sb-branding-form">
				<ImageInputCard
					title="Logo dark version"
					subtitle="Your full logo appears on your website, app sign up pages, and more."
					description="PNG or JPEG Recommended size of 250x250 px | Max 1 MB"
					imageUrl={domainGym?.dark_logo || ""}
					onSaveImage={setSelectedImageDark}
				/>
				<ImageInputCard
					title="Logo light version"
					subtitle="Your full logo appears on your website, app sign up pages, and more."
					description="PNG or JPEG Recommended size of 250x250 px | Max 1 MB"
					imageUrl={domainGym?.light_logo || ""}
					onSaveImage={setSelectedImageLight}
				/>
				<ColorPickerCard
					title="Brand Color"
					subtitle="Your brand color will be the accent color on your app and website. Appears on buttons, callouts, etc."
					selectedColor={selectedColor}
					onChangeColor={setSelectedColor}
				/>
				<Button className="primary-filled" onClick={saveBrandingChanges} saveIcon uppercase>
					<Paragraph2>Save Changes</Paragraph2>
				</Button>
			</div>
			{gymSaving && <LoadingMask />}
		</StyledBrandingForm>
	);
};

export default BrandingForm;
