// -----------------------------------------------------------------------------
// Libraries
// -----------------------------------------------------------------------------
import React, { useCallback, useState } from "react";
import Modal from "antd/lib/modal";

// -----------------------------------------------------------------------------
// Store
// -----------------------------------------------------------------------------

// -----------------------------------------------------------------------------
// Components
// -----------------------------------------------------------------------------
import { CreateWorkoutForm } from "components/Forms/Workout/CreateWorkoutForm";
import { WorkoutDetailsForm } from "components/Forms/Workout/WorkoutDetailsForm";
import { WorkoutMusicForm } from "components/Forms/Workout/WorkoutMusicForm";
import { CloseIcon } from "components/Icons";
import { Steps } from "components/Common/Steps";
import { Heading10, Paragraph12 } from "themes/default/_typography";

// -----------------------------------------------------------------------------
// Styles and utils
// -----------------------------------------------------------------------------
import StyledCreateWorkoutModal from "./styles";
import { UploadAssetsForm } from "components/Forms/Workout/UploadAssetsForm.jsx";
import { useParams } from "react-router";
import { useGetDomainGymQuery, useGetGymWorkoutsQuery } from "store/api/api";

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
const CreateWorkoutModal = ({ visible, setVisible }) => {
	const [currentStep, setCurrentStep] = useState(1);
	const { gym } = useParams();
	const domainGymResponse = useGetDomainGymQuery(gym);
	const domainGymId = domainGymResponse.data?.id;
	const { data: gymWorkouts, refetch: refetchWorkouts } = useGetGymWorkoutsQuery(domainGymId, {
		skip: !domainGymResponse.data,
	});

	const handleCloseModal = () => {
		setVisible(false);
		setCurrentStep(1);
		refetchWorkouts();
	};

	const nextStep = useCallback(() => {
		if (currentStep <= 3) {
			setCurrentStep(currentStep + 1);
		} else {
			setVisible(false);
			setCurrentStep(1);
		}
	}, [setVisible, currentStep]);

	return (
		<Modal
			width={656}
			closeIcon={<CloseIcon data-cy="close-create-workout-modal" />}
			visible={visible}
			onCancel={handleCloseModal}
			footer={null}
		>
			<StyledCreateWorkoutModal data-cy="create-workout-modal" className="sb-create-workout-modal">
				<div className="sb-create-workout-modal__header">
					<Steps
						className="sb-create-workout-modal__header__steps"
						steps={[
							{ value: "", active: currentStep === 1 },
							{ value: "", active: currentStep === 2 },
							{ value: "", active: currentStep === 3 },
							{ value: "", active: currentStep === 4 },
						]}
					/>
					{currentStep === 1 && (
						<>
							<Heading10 className="sb-create-workout-modal__header__title">Upload Workout</Heading10>
							<Paragraph12>Fill out the information below to create a new workout.</Paragraph12>
						</>
					)}
					{currentStep === 2 && (
						<>
							<Heading10 className="sb-create-workout-modal__header__title">Upload Your Content</Heading10>
							<Paragraph12>Add your workout media below.</Paragraph12>
						</>
					)}
					{currentStep === 3 && (
						<>
							<Heading10 className="sb-create-workout-modal__header__title">Fill in the Details</Heading10>
							<Paragraph12>Add some extra details to help us organize your workout.</Paragraph12>
						</>
					)}
					{currentStep === 4 && (
						<>
							<Heading10 className="sb-create-workout-modal__header__title">Finalize with Music</Heading10>
							<Paragraph12>Spice up your video with our unique music mixes.</Paragraph12>
						</>
					)}
				</div>
				{currentStep === 1 && <CreateWorkoutForm nextStep={nextStep} />}
				{currentStep === 2 && <UploadAssetsForm nextStep={nextStep} />}
				{currentStep === 3 && <WorkoutDetailsForm nextStep={nextStep} />}
				{currentStep === 4 && <WorkoutMusicForm nextStep={nextStep} />}
			</StyledCreateWorkoutModal>
		</Modal>
	);
};

export default CreateWorkoutModal;

