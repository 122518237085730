// -----------------------------------------------------------------------------
// Library
// -----------------------------------------------------------------------------
import React, { useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import notification from "antd/lib/notification";
import { useRecoilState, useRecoilValue } from "recoil";
import { useLocation, useNavigate, useParams } from "react-router";

// -----------------------------------------------------------------------------
// Components and helpers
// -----------------------------------------------------------------------------
import { validateEmail, required } from "helpers/Form/commonFormValidations";
import { useGetDomainGymQuery } from "store/api/api";
import { userAtom } from "globalAtoms";
import states from "utils/states";
import Ajax from "utils/api";
import ajaxRoutes from "utils/ajaxRoutes";
import InputField from "components/UI/Form/InputField";
import Label from "components/UI/Form/label";
import SelectField from "components/UI/Form/SelectField";
import { CONTACT_INFORMATION_FORM } from "../constants";
import { useFormSubmissionHooks } from "../hooks";
import { creatorCreationFlowState } from "../atoms";
import { Button } from "components/Common/Button";

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
const ContactInformationForm = ({ update }) => {
	const { gym } = useParams();
	const location = useLocation();
	const navigate = useNavigate();
	const formSubmissionHooks = useFormSubmissionHooks();
	const [creatorCreationFlow, setCreatorCreationFlow] = useRecoilState(creatorCreationFlowState);
	const user = useRecoilValue(userAtom);
	const [initialValues, setInitialValues] = useState({});
	const { data: domainGymData } = useGetDomainGymQuery(gym, { skip: !gym });

	useEffect(() => {
		if (domainGymData) {
			setInitialValues({
				email: domainGymData?.email,
				businessPhoneNumber: domainGymData?.phone_number,
				city: domainGymData?.city,
				gymAddress: domainGymData?.address1,
				gymAddress2: domainGymData?.address2,
				zipCode: domainGymData?.zipcode,
				state: domainGymData?.state,
			});
		}
	}, [domainGymData]);

	const handleFormSubmit = async (formObj) => {
		await setCreatorCreationFlow((prev) => ({ ...prev, saving: true }));

		const { businessPhoneNumber, email, city, gymAddress, gymAddress2, zipCode, state } = formObj;
		const { userAssociations } = user;
		try {
			if (userAssociations && userAssociations?.length > 0) {
				const submissionObj = {
					gymId: userAssociations[0].gym_id,
					name: creatorCreationFlow.company || user?.gym?.name,
					email: email,
					description: creatorCreationFlow.description || user?.gym?.description,
					philosophy: creatorCreationFlow.fitnessPhilosophy || user?.gym?.philosophy,
					address1: gymAddress,
					address2: gymAddress2,
					city,
					state,
					zipcode: zipCode,
					phoneNumber: businessPhoneNumber,
				};
				await Ajax.call(ajaxRoutes.EDIT_GYM, "put", submissionObj);
			}

			await setCreatorCreationFlow((prev) => ({ ...prev, saving: false }));
			formSubmissionHooks.mountUpdatesAfterFormSubmission(formObj);
			if (update) {
				return notification.success({
					message: "Your contact details have been saved!",
				});
			} else {
				navigate("/creator-creation/member-pages");
			}
		} catch (e) {
			await setCreatorCreationFlow((prev) => ({ ...prev, saving: false }));
			return notification.error({
				message: "An error saving your information",
			});
		}
	};

	return (
		<div>
			<Form initialValues={initialValues} onSubmit={handleFormSubmit}>
				{({ handleSubmit }) => {
					return (
						<form id={CONTACT_INFORMATION_FORM} onSubmit={handleSubmit}>
							<Label>Business Email Address</Label>
							<Field name="email" validate={validateEmail}>
								{({ input, meta }) => <InputField type="text" input={input} meta={meta} />}
							</Field>
							<Label>Business Phone Number</Label>
							<Field name="businessPhoneNumber" validate={required}>
								{({ input, meta }) => <InputField type="text" input={input} meta={meta} />}
							</Field>
							{location.pathname.includes("creator-creation") && (
								<>
									<br />
									<div className="sb-creator-creation__inner__header">
										<p className="sb-creator-creation__inner__header__text">GYM ADDRESS (Optional)</p>
									</div>
								</>
							)}
							<Label>Gym Address</Label>
							<Field name="gymAddress">
								{({ input, meta }) => (
									<InputField placeholder="Enter your gym address..." type="text" input={input} meta={meta} />
								)}
							</Field>
							<Label>Gym Address 2 (Optional)</Label>
							<Field name="gymAddress2">
								{({ input, meta }) => (
									<InputField placeholder="Enter secondary gym address..." type="text" input={input} meta={meta} />
								)}
							</Field>
							<Label>City</Label>
							<Field name="city">
								{({ input, meta }) => <InputField placeholder="City" type="text" input={input} meta={meta} />}
							</Field>
							<Label>State</Label>
							<SelectField name="state" options={states} />
							<Label>Zip Code</Label>
							<Field name="zipCode">
								{({ input, meta }) => <InputField placeholder="Zip Code" type="text" input={input} meta={meta} />}
							</Field>
							<Button
								className="primary-filled"
								chevronRight={!update}
								saveIcon={update}
								uppercase
								disabled={false}
								loading={false}
								onClick={null}
							>
								{update ? "Save Changes" : "Continue"}
							</Button>
						</form>
					);
				}}
			</Form>
		</div>
	);
};

export default ContactInformationForm;
