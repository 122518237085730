// -----------------------------------------------------------------------------
// Library
// -----------------------------------------------------------------------------
import React from "react";
import { useSelector } from "react-redux";

// -----------------------------------------------------------------------------
// Store
// -----------------------------------------------------------------------------
import { selectDomainGym } from "store/selectors";

// -----------------------------------------------------------------------------
// Components
// -----------------------------------------------------------------------------
import { Heading3 } from "themes/default/_typography";
import { StackedBarChart } from "components/Common/Charts/StackedBarChart";

// -----------------------------------------------------------------------------
// Utils and Styles
// -----------------------------------------------------------------------------
import StyledEarningsStackedBarChart from "./styles";
import { variables } from "themes/default/_variables";
import moment from "moment";

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
const EarningsStackedBarChart = (props) => {
	const domainGym = useSelector(selectDomainGym);
	const stackedBarChartLabels = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

	const bars = [
		{
			name: "Earnings",
			backgroundColor: variables.colors.trout,
		},
	];
	/*
	TODO: Replace the random data with actual data from the API
	
	*/
	var barChartData = {
		Jan: { sum: 0 },
		Feb: { sum: 0 },
		Mar: { sum: 0 },
		Apr: { sum: 0 },
		May: { sum: 0 },
		Jun: { sum: 0 },
		Jul: { sum: 0 },
		Aug: { sum: 0 },
		Sep: { sum: 0 },
		Oct: { sum: 0 },
		Nov: { sum: 0 },
		Dec: { sum: 0 },
	};

	const formattedEarningsDetails = props.transfers.map((transfer) => {
		const earningMonth = moment.unix(transfer.arrival_date).format("MMM");
		barChartData[earningMonth].sum += transfer.amount;
	});

	const stackedBarChartData = stackedBarChartLabels.map((name) => ({
		name,
		Earnings: (barChartData[name].sum / 100).toFixed(2),
	}));

	return (
		<StyledEarningsStackedBarChart className="sb-earnings-stacked-bar-chart">
			<Heading3>Earnings</Heading3>
			<div className="sb-earnings-stacked-bar-chart__card">
				<StackedBarChart data={stackedBarChartData} bars={bars} formatted />
			</div>
		</StyledEarningsStackedBarChart>
	);
};

export default EarningsStackedBarChart;

