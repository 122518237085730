// -----------------------------------------------------------------------------
// Library
// -----------------------------------------------------------------------------
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";

// -----------------------------------------------------------------------------
// Components and helpers
// -----------------------------------------------------------------------------
import { userAtom } from "../../../globalAtoms";
import defaultHeader from "../../../images/default-header.jpeg";
import userIcon from "../../../images/user-icon.png";
// import broadcastSVG from '../../../images/broadcast.svg';
import playIcon from "../../../images/play-icon.svg";
import { LiveIcon } from "components/Icons";
import { useGetDomainGymQuery, useGetPagesQuery } from "store/api/api";

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
function GymBanner() {
	const { gym } = useParams();
	const { data: domainGymData, refetch: refetchDomainGymData } = useGetDomainGymQuery(gym, { skip: !gym });
	const gymId = domainGymData?.id || null;
	const { data: pageData, refetch: refetchPages } = useGetPagesQuery(gymId, { skip: !gymId });
	const navigate = useNavigate();
	const location = useLocation();
	const user = useRecoilValue(userAtom);
	//const headerImage = user?.pages?.[0]?.header_image;
	const [headerImage, setHeaderImage] = useState(defaultHeader);
	const gymName = user?.gym?.name;
	// TEMPORARY: Should switch to redux variable or route navigation
	// TODO: Add redux variable to track live status
	useEffect(() => {
		// Update the document title using the browser API
		document.title = `SWEATBASE${gymName?.length > 0 ? " - " + gymName : ""}`;
	});

	const handleOnDemandClick = () => {
		// if (location.pathname.includes("/dashboard/on-demand")) return;
		if (location.pathname.includes("/dashboard/consumer")) {
			navigate("../consumer/on-demand");
		} else {
			navigate("../on-demand");
		}
	};

	const handleLiveClick = () => {
		if (location.pathname.includes("/dashboard/consumer")) {
			navigate("../consumer/live");
		} else {
			navigate("../live");
		}
	};

	const replacementString =
		process.env.REACT_APP_API_ENV === "production" ? "cdn.sweatbase.com" : "sweatbase-staging.s3.amazonaws.com";

	useEffect(() => {
		if (pageData) {
			const landingPage = pageData.find((page) => page.type === "landing");
			if (landingPage) {
				const headerImage = landingPage.header_image;
				setHeaderImage(headerImage);
			}
		}
	}, [pageData]);

	useEffect(() => {
		if (
			!location.pathname.includes("on-demand") &&
			!location.pathname.includes("live") &&
			user.user_type === "consumer"
		) {
			navigate("./on-demand");
		}
	}, [location]);

	return (
		<div
			className="sb-gym-banner-container"
			style={{
				backgroundImage: `url("${headerImage}")`,
			}}
		>
			<div className="sb-gym-banner-container__inner">
				<div className="sb-gym-banner-container__inner__gym-profile">
					<div className="sb-gym-banner-container__inner__gym-profile__gym"></div>
					{!location.pathname.includes("consumer") && (
						<div className="sb-gym-banner-container__inner__gym-profile__profile">
							<img className="sb-gym-banner-container__inner__gym-profile__profile__icon" src={userIcon} alt="user" />
							<span
								className="sb-gym-banner-container__inner__gym-profile__profile__text"
								onClick={() => navigate("../profile")}
							>
								My Profile
							</span>
						</div>
					)}
				</div>
				<div className="sb-gym-banner-container__inner__welcome">
					<div className="sb-gym-banner-container__inner__welcome__container">
						<h3 className="sb-gym-banner-container__inner__welcome__container__sub-title">Welcome To</h3>
						<h1 className="sb-gym-banner-container__inner__welcome__container__title">{domainGymData?.name}</h1>
						<div className="sb-gym-banner-container__inner__welcome__container__actions">
							<button
								onClick={handleOnDemandClick}
								className={
									location.pathname.includes("/dashboard/on-demand") ||
									location.pathname.includes("/dashboard/consumer/on-demand") ||
									location.pathname === "/dashboard"
										? "sb-gym-banner-container__inner__welcome__container__actions__on-demand-selected"
										: "sb-gym-banner-container__inner__welcome__container__actions__on-demand"
								}
							>
								<img
									className="sb-gym-banner-container__inner__welcome__container__actions__on-demand__icon"
									src={playIcon}
									alt="play"
								/>
								<span className="sb-gym-banner-container__inner__welcome__container__actions__on-demand__text">
									On Demand
								</span>
							</button>
							<button
								onClick={handleLiveClick}
								className={
									location.pathname.includes("/dashboard/live") ||
									location.pathname.includes("/dashboard/consumer/live")
										? "sb-gym-banner-container__inner__welcome__container__actions__live-selected"
										: "sb-gym-banner-container__inner__welcome__container__actions__live"
								}
							>
								<LiveIcon
									style={{ marginRight: "20px" }}
									fill={location.pathname === "/dashboard/live" ? null : "#FFFFFF"}
								/>

								<span className="sb-gym-banner-container__inner__welcome__container__actions__live__text">Live</span>
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default GymBanner;
