import styled from "styled-components";

const StyledTag = styled.div`
	background: ${({ theme }) => theme.colors.coral};
	border-radius: 100px;
	height: 24px;
	width: fit-content;
	padding: 0 8px;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 4px;

	p {
		text-transform: uppercase;
	}

	span {
		svg {
			fill: white;
		}
	}

	.tag-filled-primary {
		background: #00008b;
	}

	&.tag-filled-paid {
		background: #00008b;
	}

	&.tag-filled-trial {
		background: #6fa2f2;
	}

	&.tag-filled-cancelled {
		background: #d3d3d3;
	}
`;

export default StyledTag;

