import React from "react";
import Switch from "antd/lib/switch";

import "./index.scss";

function Toggle({ updateToggles, name, initial, text }) {
	const onChange = (checked) => {
		updateToggles((prev) => ({ ...prev, [name]: checked }));
	};

	return (
		<div className="toggle-container">
			<h2>{text}</h2>
			<Switch defaultChecked={initial === 0 || initial === 1 ? initial !== 0 : false} onChange={onChange} />
		</div>
	);
}

export default Toggle;
