// -------------------------------------------------------------------------------
// Libraries
// -------------------------------------------------------------------------------
import React from "react";
import { Input, Form, Tooltip } from "antd";

// -------------------------------------------------------------------------------
// Components
// -------------------------------------------------------------------------------
import { Label2 } from "themes/default/_typography";

// -------------------------------------------------------------------------------
// Styles
// -------------------------------------------------------------------------------
import StyledFormInput from "./styles";
import { ClockIcon } from "components/Icons/ClockIcon";
import { InfoCircleOutlined } from "@ant-design/icons";

// -------------------------------------------------------------------------------
// Component
// -------------------------------------------------------------------------------
const FormInput = ({ name, label, rules, autoComplete, placeholder = "", maxLength = null, dark }) => {
	return (
		<StyledFormInput className="sb-form-input">
			<Form.Item name={name} label={<Label2 style={{ color: dark ? "white" : null }}>{label}</Label2>} rules={rules}>
				<Input
					className={dark ? "input-dark" : null}
					placeholder={placeholder}
					maxLength={maxLength}
					autoComplete={autoComplete}
				/>
			</Form.Item>
		</StyledFormInput>
	);
};

export default FormInput;

