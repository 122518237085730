// -----------------------------------------------------------------------------
// Library
// -----------------------------------------------------------------------------
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Redirect, useLocation, useParams } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { Button, Layout } from "antd";

// -----------------------------------------------------------------------------
// Store
// -----------------------------------------------------------------------------
import { selectMemberMembershipsLoading, selectIsMemberMembershipReady, selectCreatorProduct } from "store/selectors";

// -----------------------------------------------------------------------------
// Components and helpers
// -----------------------------------------------------------------------------
import { paths } from "./helpers";
import { isUserCreator } from "../../constants/ui";
import { navbarAtom } from "../../components/UI/Navbar/atoms";
import { userAtom } from "../../globalAtoms";
import SidePanel from "components/SidePanel";
import useLogout from "../../utils/useLogout";
import SwitchRoute from "./SwitchRoute";
import Navbar from "../../components/UI/Navbar";
import "./index.scss";
import {
	useGetCurrentUserQuery,
	useGetDomainGymQuery,
	useGetGymFromDomainQuery,
	useGetGymFromNameQuery,
	useLazyGetGymFromNameQuery,
} from "store/api/api";
import { Navigate, redirect, useNavigate } from "react-router";

const { Header, Content, Sider, Footer } = Layout;

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
const Dashboard = ({ match, history }) => {
	const navigate = useNavigate();
	const user = useRecoilValue(userAtom);
	const location = useLocation();
	const isMemberMembershipReady = useSelector(selectIsMemberMembershipReady);
	const memberMembershipsLoading = useSelector(selectMemberMembershipsLoading);
	const creatorProduct = useSelector(selectCreatorProduct);
	const setNavbarState = useSetRecoilState(navbarAtom);
	let { gym } = useParams();
	const logoutHook = useLogout(history, gym);
	const { data: gymData } = useGetDomainGymQuery(gym, { skip: !gym });
	const [userType, setUserType] = useState(null);

	const [selectedKey, updateSelectedKey] = useState("home");

	const onMenuItemClick = (e) => {
		if (e.key === "logout") {
			return logoutHook.logout();
		}

		if (e.key === "creator-creation") {
			const urlSplit = window.location.href.split("/");

			window.location.href = `${urlSplit[0]}//${urlSplit[2]}/${e.key}`;
		}

		updateSelectedKey(e.key);
		setNavbarState(e.key);

		//navigate("test");

		navigate(`${e.key === "home" ? "" : e.key}`);
	};

	useEffect(() => {
		const found = paths.filter((p) => location.pathname.includes(`/${p.id}`));

		if (found && found.length && selectedKey !== found[0].id) {
			updateSelectedKey(found[0].id);
		}
	}, [location, selectedKey]);

	useEffect(() => {
		if (gym && user && gymData) {
			//Check if the user's associations array includes the current gym
			const associationsGymIds = user?.userAssociations?.map((a) => a.gym_id);
			const association = user?.userAssociations?.find((a) => a.gym_id === gymData?.id);
			if (associationsGymIds?.includes(gymData?.id) || user?.user_type === "admin") {
				setUserType(association?.type);
			} else if (!associationsGymIds?.includes(gymData?.id) && user?.user_type !== "admin") {
				logoutHook.logout();
			}
		}
	}, [user, gym, gymData]);

	const renderHeader = () => {
		if (isUserCreator(userType)) {
			return (
				<Header className="sb-base-layout__header">
					<Navbar />
				</Header>
			);
		}
	};

	if (!user) {
		return <Navigate to="/" />;
	}

	return (
		<Layout className="sb-base-layout">
			{!user && redirect("/login")}
			{user &&
				!isUserCreator(userType) &&
				!isMemberMembershipReady &&
				!memberMembershipsLoading &&
				creatorProduct.price_id !== "free" &&
				redirect("/add-payment-method")}
			{isUserCreator(userType) && (
				<Sider breakpoint="lg" collapsedWidth="0" width="220" className="sb-base-layout__sider">
					<SidePanel options={paths} onClick={onMenuItemClick} defaultSelectedKeys={[selectedKey]} />
					<Footer className="sb-base-layout__sider__footer">
						<a href="https://www.sweatbase.com/privacy.html" target="_blank" rel="noreferrer">
							Privacy
						</a>
						<a href="https://www.sweatbase.com/tos.html" target="_blank" rel="noreferrer">
							Terms
						</a>
					</Footer>
				</Sider>
			)}
			<Layout>
				{renderHeader()}
				<Content>
					<SwitchRoute match={match} user={user} />
				</Content>
			</Layout>
		</Layout>
	);
};

export default Dashboard;
