// -------------------------------------------------------------------------------
// Libraries
// -------------------------------------------------------------------------------
import React, { useState, useCallback, useEffect } from "react";
import { PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";

// -------------------------------------------------------------------------------
// Components
// -------------------------------------------------------------------------------
import { Button } from "components/Common/Button";
import { LoadingMask } from "components/Common/LoadingMask";
import { Paragraph2 } from "themes/default/_typography";

// -------------------------------------------------------------------------------
// Utils, Styles and Assets
// -------------------------------------------------------------------------------
import StyledStripeForm from "./styles";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { userAtom } from "globalAtoms";
import { useSelector } from "react-redux";
import { selectSelectedProduct } from "store/selectors";
import { useCreateSubscriptionMutation, useGetCurrentUserQuery, useGetDomainGymQuery } from "store/api/api";
import {
	StripeCreateSubscription,
	StripeCreateSubscriptionToPlatform,
	StripeUpdateCustomerDefaultPaymentMethod,
} from "constants/urls";
import { InfoCircleOutlined } from "@ant-design/icons";

// -------------------------------------------------------------------------------
// Component
// -------------------------------------------------------------------------------
const StripeForm = ({
	origin,
	buttonText,
	empty,
	setupIntentId,
	clientSecret,
	subscribingToGym,
	currentCustomerId,
}) => {
	const navigate = useNavigate();
	//const navigate = useNavigate();
	const stripe = useStripe();
	const elements = useElements();
	const [message, setMessage] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const user = useRecoilValue(userAtom);
	const token = localStorage.getItem("creator_jwt_token");
	const split = token.split(".")[1];
	const formatted = JSON.parse(atob(split));
	const JWTID = formatted.id;
	const { data: currentUserData, isError, refetch: syncCurrentUser } = useGetCurrentUserQuery({ userId: JWTID });
	const { gym } = useParams();
	const domainGymResponse = useGetDomainGymQuery(gym);
	const selectedProduct = useSelector(selectSelectedProduct);
	const [subscribeToGymTrigger, subscribeToGymResponse] = useCreateSubscriptionMutation();

	useEffect(() => {
		if (subscribeToGymResponse?.data?.id) {
			window.location.reload();
		}
	}, [subscribeToGymResponse]);

	const handleSubmit = useCallback(
		async (event) => {
			event.preventDefault();
			if (!stripe || !elements) {
				return;
			}

			setIsLoading(true);

			const path = window.location.pathname;
			let redirectUrl = "";

			if (origin === "creator") {
				redirectUrl = "pricing-plan";
			} else if (path.includes("consumer-membership")) {
				redirectUrl = "consumer-membership";
			}

			try {
				const res = await stripe.confirmSetup({
					elements,
					redirect: "if_required",
					//TODO: Should we have a success page or redirect to payment settings?
					// confirmParams: {
					// 	return_url: `${window.location.protocol}//${window.location.host}/dashboard/${redirectUrl}`,
					// },
				});
				if (res.error) {
					setIsLoading(false);
					setMessage(res.error.message);
				} else {
					await handleGetSetup();
				}
			} catch (error) {
				console.debug("error: ", error);
				if (error.type === "card_error" || error.type === "validation_error") {
					setMessage("An unexpected error occurred. Please try again or contact support at ");
				} else {
					setMessage("An unexpected error occurred.");
				}
			}

			// if(setup intent goes goes through properly, update user default payment method)

			setIsLoading(false);
		},
		[origin, elements, stripe]
	);

	const handleGetSetup = async () => {
		try {
			syncCurrentUser();
			const res = await stripe.retrieveSetupIntent(clientSecret);
			// response should have the new payment method
			const paymentMethod = res.setupIntent.payment_method;
			// we then attatch this to the customer with this call

			const updateCustomerUrl =
				process.env.REACT_APP_API_ENV === "production"
					? StripeUpdateCustomerDefaultPaymentMethod.prod
					: StripeUpdateCustomerDefaultPaymentMethod.dev;

			const updateCustomerResponse = await axios.post(updateCustomerUrl, {
				customerId: currentCustomerId
					? currentCustomerId
					: subscribingToGym?.customerId
					? subscribingToGym?.customerId
					: user?.customer_id
					? user?.customer_id
					: currentUserData?.customer_id
					? currentUserData?.customer_id
					: "NULL",
				paymentMethodId: paymentMethod,
			});

			if (subscribingToGym) {
				const body = {
					customerId: user?.customer_id,
					priceId: subscribingToGym?.priceId,
					accountId: subscribingToGym?.accountId,
					feePercent: subscribingToGym?.feePercent ? subscribingToGym?.feePercent : 15,
					gymId: domainGymResponse?.data?.id,
				};
				await subscribeToGymTrigger(body);
			} else {
				// StripeCreateSubscriptionToPlatform-dev Lambda Call
				const subscribeUrl =
					process.env.REACT_APP_API_ENV === "production"
						? StripeCreateSubscriptionToPlatform.prod
						: StripeCreateSubscriptionToPlatform.dev;

				const subscribeResponse = await axios.post(subscribeUrl, {
					accountId: user.connected_account_id,
					customerId: currentCustomerId ? currentCustomerId : user.customer_id,
					priceId: selectedProduct.priceId,
				});
				//redirect to payments-settings page
				navigate(0);

				//navigate("/dashboard/payment-settings");
			}
		} catch (error) {
			console.error("error subscribing: ", error);
		}
	};

	return (
		<StyledStripeForm>
			{subscribingToGym && (
				<>
					<Paragraph2 style={{ fontSize: "28px", paddingBottom: "0px", marginBottom: "10px" }}>
						Membership Info
					</Paragraph2>
					<Paragraph2 style={{ fontSize: "14px", color: "#4f5661" }}>{domainGymResponse?.data?.description}</Paragraph2>
					<div
						style={{
							border: "1px solid #E3E5E7",
							borderRadius: "8px",
							justifyContent: "center",
							alignItems: "center",
							textAlign: "center",
							borderStyle: "solid",
							marginBottom: "10px",
							paddingTop: "20px",
						}}
					>
						<h1 style={{ fontWeight: 700, fontSize: "40px" }}>
							${(subscribingToGym.price / 100).toFixed(2)} <span style={{ fontSize: "16px" }}>/month</span>
						</h1>
					</div>
				</>
			)}
			<form onSubmit={handleSubmit}>
				{message && (
					<div>
						<h4 style={{ color: "red" }}>
							<InfoCircleOutlined style={{ paddingRight: "8px" }} />
							{message}
						</h4>
					</div>
				)}
				<PaymentElement />
				<Button type="submit" uppercase>
					<Paragraph2>{buttonText ? buttonText : "Submit"}</Paragraph2>
				</Button>
			</form>
			{isLoading && <LoadingMask />}
		</StyledStripeForm>
	);
};

export default StripeForm;

