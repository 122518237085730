// -----------------------------------------------------------------------------
// Library
// -----------------------------------------------------------------------------
import React, { useEffect, useState } from "react";
import { Input, Alert, notification } from "antd";
import { useSelector } from "react-redux";

// -----------------------------------------------------------------------------
// Actions and selectors
// -----------------------------------------------------------------------------
import { selectGymSaving } from "store/selectors";

// -----------------------------------------------------------------------------
// Components, utils and Styles
// -----------------------------------------------------------------------------
import { Button } from "components/Common/Button";
import { LoadingMask } from "components/Common/LoadingMask";
import { Paragraph2 } from "themes/default/_typography";
import StyledSubdomainForm from "./styles";
import { useGetDomainGymQuery, useUpdateGymSubdomainMutation } from "store/api/api";
import { useNavigate, useParams } from "react-router";

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
const SubdomainForm = () => {
	const gymSaving = useSelector(selectGymSaving);
	const { gym } = useParams();
	const domainGymResponse = useGetDomainGymQuery(gym);
	const gymData = domainGymResponse?.data || {};

	const [newSubdomain, setNewSubdomain] = useState("");

	const [updateGymTrigger, updateGymResponse] = useUpdateGymSubdomainMutation();
	const navigate = useNavigate();

	const handleSaveSubdomain = async () => {
		if (newSubdomain !== "") {
			const formattedSubdomain = newSubdomain.replace(/[^a-z0-9]/gi, "").toLowerCase();
			const updateDomainRequest = await updateGymTrigger({
				oldSubdomain: gym,
				newSubdomain: formattedSubdomain,
				gymId: gymData?.id,
			}).unwrap();
		}
	};

	useEffect(() => {
		if (updateGymResponse.isSuccess) {
			notification.success({
				message: "Subdomain Updated",
				description: "Your subdomain has been updated successfully",
			});
			// Navigate to new subdomain
			const formattedSubdomain = newSubdomain.replace(/[^a-z0-9]/gi, "").toLowerCase();
			navigate(`/../../${formattedSubdomain}/dashboard/settings`, { relative: "path" });
		} else if (updateGymResponse.isError) {
			if (updateGymResponse.error.status === 403) {
				notification.error({
					message: "Subdomain Error",
					description: "The requested subdomain is already taken",
				});
			} else {
				notification.error({
					message: "Something Went Wrong Updating Your Subdomain",
					description: "Pease Try again alter or message support@sweatbase.com",
				});
			}
		} else {
			console.debug("updateGymResponse", updateGymResponse);
		}
	}, [updateGymResponse]);

	return (
		<StyledSubdomainForm className="sb-subdomain-form">
			<Alert
				className="sb-subdomain-form__alert"
				message="Caution"
				description="This field will be used as your subdomain name, please choose carefully"
				type="warning"
				showIcon
			/>
			<label className="sb-subdomain-form__label">Current Subdomain</label>
			<Input
				disabled={true}
				placeholder={"Current Subdomain Value"}
				className="sb-subdomain-form__input"
				value={`https://www.sweatbase.com/${gym}`}
			/>
			<label className="sb-subdomain-form__label">New Subdomain</label>
			<Input
				placeholder={"New Subdomain Value"}
				className="sb-subdomain-form__input"
				value={newSubdomain}
				onChange={(e) => setNewSubdomain(e.target.value)}
			/>
			<label className="sb-subdomain-form__label">Preview New Subdomain</label>
			<Input
				disabled={true}
				placeholder={"New Subdomain Value"}
				className="sb-subdomain-form__input"
				value={`https://www.sweatbase.com/${newSubdomain}`}
			/>
			<Button className="primary-filled" onClick={handleSaveSubdomain} saveIcon uppercase>
				<Paragraph2>Save Changes</Paragraph2>
			</Button>
			{gymSaving && <LoadingMask />}
		</StyledSubdomainForm>
	);
};

export default SubdomainForm;
