// -------------------------------------------------------------------------------
// Libraries
// -------------------------------------------------------------------------------
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loadStripe } from "@stripe/stripe-js/pure";
import { Elements } from "@stripe/react-stripe-js";

// -----------------------------------------------------------------------------
// Store
// -----------------------------------------------------------------------------
import {
	selectCreatorProduct,
	selectDomainGym,
	selectMemberMemberships,
	selectIsMemberMembershipCreated,
	selectMemberMembershipsLoading,
} from "store/selectors";
import { createSubscription } from "store/slices";

// -------------------------------------------------------------------------------
// Components
// -------------------------------------------------------------------------------
import { ChevronLeftIcon } from "components/Icons";
import { PlanInformationCard } from "components/Common/Cards/PlanInformationCard";
import { PaymentMethodCard } from "components/Common/Cards/PaymentMethodCard";
import { StripeForm } from "components/Common/Form/StripeForm";
import { LoadingMask } from "components/Common/LoadingMask";
import { Heading10, Paragraph22 } from "themes/default/_typography";

// -------------------------------------------------------------------------------
// Utils, Styles and Assets
// -------------------------------------------------------------------------------
import StyledMemeberPayments from "./styles";
import { useCancelSubscriptionMutation, useGetCurrentUserQuery, useGetDomainGymQuery } from "store/api/api";
import { useRecoilValue } from "recoil";
import { userAtom } from "globalAtoms";
import { Button } from "components/Common/Button";

// -------------------------------------------------------------------------------
// Component
// -------------------------------------------------------------------------------
const MemeberPayments = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	// const STRIPE_KEY =
	// 	process.env.REACT_APP_API_ENV === "production"
	// 		? process.env.REACT_APP_STRIPE_PROD_PUBLISHABLE_KEY
	// 		: process.env.REACT_APP_STRIPE_STAG_PUBLISHABLE_KEY;
	// const stripePromise = loadStripe(STRIPE_KEY);
	// const creatorProduct = useSelector(selectCreatorProduct);
	// const domainGym = useSelector(selectDomainGym);
	// const memberMemberships = useSelector(selectMemberMemberships);
	// const memberMembershipsLoading = useSelector(selectMemberMembershipsLoading);
	// const isMemberMembershipCreated = useSelector(selectIsMemberMembershipCreated);
	// const [showAddPaymentMethod, setShowAddPaymentMethod] = useState(false);
	const token = localStorage.getItem("creator_jwt_token");
	const split = token.split(".")[1];
	const formatted = JSON.parse(atob(split));
	const JWTID = formatted.id;
	let { gym } = useParams();
	const domainGymResponse = useGetDomainGymQuery(gym);
	const domainGymId = domainGymResponse?.data?.id;
	const user = useRecoilValue(userAtom);
	const userAssociations = user?.userAssociations;
	const associationInUse = userAssociations?.find((a) => a.gym_id === domainGymId) || {};
	const { subscription_id, subscription_status } = associationInUse;
	const [cancelling, setCancelling] = useState(false);
	const [showConfirmation, setShowConfirmation] = useState(false);
	const {
		data: userData,
		isLoading: domainGymLoading,
		refetch: refetchUserData,
	} = useGetCurrentUserQuery({ userId: JWTID });

	const [cancelSubsctiptionTrigger, cancelSubscriptionResponse] = useCancelSubscriptionMutation();

	const goToLandingPage = useCallback(() => {
		navigate("/");
	}, [navigate]);

	const cancelSubscription = async () => {
		setShowConfirmation(true);
	};

	const confirmCancel = async () => {
		console.log("cancelling subscription");

		setCancelling(true);
		try {
			const cancelSubscriptionResponse = await cancelSubsctiptionTrigger({
				subscriptionId: subscription_id,
				customerId: userData?.customer_id,
				gymId: domainGymId,
			}).unwrap();

			console.log(cancelSubscriptionResponse);

			refetchUserData();
			window.location.reload();
		} catch (error) {
			console.log("unable to cancel");
		}
		setCancelling(false);
	};

	return (
		<StyledMemeberPayments className="sb-member-payments">
			<div className="sb-member-payments__header">
				<img className="sb-member-payments__header__image" src={domainGymResponse?.data?.light_logo} alt="logo" />
				<Heading10 className="sb-member-payments__header__title">Membership Info</Heading10>
				<Paragraph22 className="sb-member-payments__header__subtitle">
					Your Current Subscription Status: {subscription_status}
				</Paragraph22>
				{subscription_status == "ACTIVE" && !showConfirmation && (
					<Button onClick={cancelSubscription} uppercase style={{ backgroundColor: "#eb5757", fontWeight: 700 }}>
						Cancel Subscription
					</Button>
				)}
				{subscription_status == "ACTIVE" && showConfirmation && (
					<>
						<Heading10 className="sb-member-payments__header__subtitle">
							Are you sure you want to cancel your subscription?
						</Heading10>
						<Paragraph22 className="sb-member-payments__header__subtitle">
							You will lose acces to this gym's library of on-demand videos and live-streams
						</Paragraph22>
						<Button
							onClick={() => setShowConfirmation(false)}
							uppercase
							style={{ fontWeight: 700, marginRight: "16px" }}
						>
							No, Keep My Subscription
						</Button>
						<Button
							loading={cancelling}
							onClick={cancelling ? null : confirmCancel}
							uppercase
							style={{ backgroundColor: "#eb5757", fontWeight: 700, opacity: cancelling ? 0.5 : 1 }}
						>
							Yes, I want to Cancel
						</Button>
					</>
				)}
			</div>
			{/* {creatorProduct && (
						<>
							<PlanInformationCard type={creatorProduct.type} pricing={creatorProduct.price} />
							{creatorProduct.type !== "free" && (
								<div className="sb-member-payments__payment-section">
									<div className="sb-member-payments__payment-section__payment-method">
										{memberMemberships.map((mm) => {
											if (mm.paymentMethod && mm.stripe_user_id) {
												return <PaymentMethodCard key={mm.user_id} paymentMethod={mm.paymentMethod} />;
											} else if (mm.paymentIntent) {
												return (
													<Elements
														key={mm.user_id}
														stripe={stripePromise}
														options={{
															clientSecret: mm.paymentIntent.client_secret,
															appearance: {
																theme: "flat",
																labels: "floating",
															},
														}}
													>
														<StripeForm origin="member" buttonText="Start my Membership" />
													</Elements>
												);
											} else {
												return null;
											}
										})}
									</div>
								</div>
							)}
						</>
					)} */}
		</StyledMemeberPayments>
	);
};

export default MemeberPayments;

