// -----------------------------------------------------------------------------
// Libraries
// -----------------------------------------------------------------------------
import React from "react";
import Modal from "antd/lib/modal";

// -----------------------------------------------------------------------------
// Store
// -----------------------------------------------------------------------------

// -----------------------------------------------------------------------------
// Components
// -----------------------------------------------------------------------------
import { EditWorkoutForm } from "components/Forms/Workout/EditWorkoutForm";
import { Heading10, Paragraph12 } from "themes/default/_typography";
import { CloseIcon } from "components/Icons";

// -----------------------------------------------------------------------------
// Styles and utils
// -----------------------------------------------------------------------------
import StyledEditWorkoutModal from "./styles";

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
const EditWorkoutModal = ({ visible, setVisible, isEditingLive, setIsEditingLive, liveStream }) => {
	const handleCloseModal = () => {
		setVisible(false);
		if (setIsEditingLive) {
			setIsEditingLive(false);
		}
	};

	return (
		<Modal
			key={liveStream ? liveStream.id : null}
			width={656}
			closeIcon={<CloseIcon data-cy="close-edit-workout-modal" />}
			visible={visible}
			onCancel={handleCloseModal}
			footer={null}
		>
			<StyledEditWorkoutModal data-cy="edit-workout-modal" className="sb-edit-workout-modal">
				<div className="sb-edit-workout-modal__header">
					<Heading10 className="sb-edit-workout-modal__header__title">Edit Workout</Heading10>
					<Paragraph12>Update your workout information below</Paragraph12>
				</div>
				<EditWorkoutForm isEditingLive={isEditingLive} liveStream={liveStream} handleCloseModal={handleCloseModal} />
			</StyledEditWorkoutModal>
		</Modal>
	);
};

export default EditWorkoutModal;

