// -----------------------------------------------------------------------------
// Library
// -----------------------------------------------------------------------------
import React, { useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import notification from "antd/lib/notification";
import { Input } from "antd";
import { Alert } from "antd";
import Checkbox from "antd/lib/checkbox";
import { useRecoilState, useSetRecoilState, useRecoilValue } from "recoil";
import { redirect } from "react-router-dom";

// -----------------------------------------------------------------------------
// Components and helpers
// -----------------------------------------------------------------------------
import InputField from "components/UI/Form/InputField";
import Label from "components/UI/Form/label";
import { SETUP_PROFILE_FORM_ID } from "../constants";
import { required, validateSubdomain } from "helpers/Form/commonFormValidations";
import { userAtom } from "../../../globalAtoms";
import { creatorCreationFlowState } from "../atoms";
import { useNavigate } from "react-router";
import { getUser } from "useInit";
import {
	useCreateGymMutation,
	useEditGymMutation,
	useLazyCheckSubdomainQuery,
	useUpdateUserAssociationMutation,
} from "store/api/api";
import { Button } from "components/Common/Button";

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
const SetupProfileForm = (props) => {
	const getSubdomain = (value) => {
		return value ? value.replace(/[^a-z0-9]/gi, "").toLowerCase() : "";
	};
	const navigate = useNavigate();
	const [checkSubdomainTrigger] = useLazyCheckSubdomainQuery();
	const [editGymTrigger, editGymResponse] = useEditGymMutation();
	const [createGymTrigger, createGymResponse] = useCreateGymMutation();
	const [updateUserAssociationTrigger, updateUserAssociationResponse] = useUpdateUserAssociationMutation();

	const [creatorCreationFlow] = useRecoilState(creatorCreationFlowState);
	const user = useRecoilValue(userAtom);
	const [subdomain, setSubdomain] = useState(getSubdomain(creatorCreationFlow.subdomain || user?.gym?.subdomain));
	const setUser = useSetRecoilState(userAtom);
	let initialValues = {};

	const handleCompanyChange = (e) => {
		setSubdomain(getSubdomain(e.target.value));
	};

	const notify = (type, message, description) => {
		notification[type]({
			message,
			description,
		});
	};

	if (user?.gym) {
		initialValues = {
			company: creatorCreationFlow.company || user?.gym?.name,
			description: creatorCreationFlow.description || user?.gym?.description,
			fitnessPhilosophy: creatorCreationFlow.fitnessPhilosophy || user?.gym?.philosophy,
			pin: creatorCreationFlow.pin || user?.gym?.pin,
			is_franchise: creatorCreationFlow.is_franchise || user?.gym?.is_franchise,
			subdomain: creatorCreationFlow.subdomain || user?.gym?.subdomain,
		};
	}

	const handleFormSubmit = async (formObj) => {
		const subdomainCheckResp = await checkSubdomainTrigger(subdomain).unwrap();
		if (subdomainCheckResp && Object.keys(subdomainCheckResp).length !== 0) {
			notify("error", "Subdomain already exists", "Please choose a different subdomain");
			return;
		} else {
			// Subdomain is available
			if (user && user.userAssociations && user.userAssociations?.length > 0) {
				handleEditGym(formObj, user.userAssociations[0].gym_id);
			} else {
				//Create Gym
				console.log("Creating Gym");
				handleCreateGym(formObj);
			}
		}
	};

	const handleEditGym = async (formObj, gymId) => {
		const { company, description, fitnessPhilosophy, pin, is_franchise } = formObj;
		const editBody = {
			// CHECK THIS GYM ID
			gymId,
			name: company,
			subdomain,
			description,
			philosophy: fitnessPhilosophy,
			pin,
			is_franchise: is_franchise ? 1 : 0,
		};
		try {
			await editGymTrigger(editBody);
		} catch (e) {
			console.error("Error editing gym: ", e);
		}
	};

	const handleCreateGym = async (formObj) => {
		const { company, description, fitnessPhilosophy, pin, is_franchise } = formObj;
		const createBody = {
			name: company,
			subdomain,
			description,
			philosophy: fitnessPhilosophy,
			pin,
			is_franchise: is_franchise ? 1 : 0,
		};
		try {
			await createGymTrigger(createBody);
		} catch (e) {
			console.error("Error editing gym: ", e);
		}
	};

	useEffect(() => {
		if (editGymResponse.isSuccess) {
			notify("success", "Gym updated successfully!");
			navigate(`/${subdomain}/dashboard/settings`);
		} else if (editGymResponse.isError) {
			notify("error", "Error updating gym", "Please try again later.");
		}
	}, [editGymResponse]);

	useEffect(() => {
		if (createGymResponse.isSuccess) {
			notify("success", "Gym created successfully!");
			// Get Gym Id from response and set user association
			console.log("createGymResponse", createGymResponse);
			handleSetUserAssociation(createGymResponse.data.gym.id);
		} else if (createGymResponse.isError) {
			notify("error", "Error creating gym", "Please try again later.");
		}
	}, [createGymResponse]);

	const handleSetUserAssociation = async (gymId) => {
		console.log("handleSetUserAssociation", gymId);
		const auth_token = localStorage.getItem("creator_jwt_token");
		try {
			await updateUserAssociationTrigger({ gymId, auth_token, overrideType: "trainer" });
		} catch (e) {
			console.error("Error setting user association: ", e);
		}
	};

	useEffect(() => {
		if (updateUserAssociationResponse.isSuccess) {
			notify("success", "User association updated successfully!");
			// See react router useNavigate Docs for why we are using window replace instead of navigate
			window.location.replace(`/../../${subdomain}/dashboard/settings`);
		} else if (updateUserAssociationResponse.isError) {
			notify("error", "Error updating user association", "Please try again later.");
		}
	}, [updateUserAssociationResponse]);

	return (
		<Form initialValues={initialValues} onSubmit={(formObj) => handleFormSubmit(formObj)}>
			{({ handleSubmit }) => {
				return (
					<form id={SETUP_PROFILE_FORM_ID} onSubmit={handleSubmit}>
						<Label tooltip="Your members can search for your gym or company using this name.">COMPANY</Label>
						<Field component="input" name="company" validate={required}>
							{({ input, meta }) => <InputField type="text" input={input} meta={meta} />}
						</Field>
						<Label tooltip="Please use no spaces or special characters, the maximum number of characters is 25.">
							SUBDOMAIN
						</Label>
						<Alert
							className="sb-creator-creation__inner__alert"
							message="Caution"
							description="This field will be used as your subdomain name, please choose carefully"
							type="warning"
							showIcon
						/>
						<Field component="input" name="subdomain" validate={validateSubdomain} style={{ marginBottom: "20px" }}>
							{({ input, meta }) => (
								<InputField type="text" onKeyUp={(e) => handleCompanyChange(e)} input={input} meta={meta} />
							)}
						</Field>
						<div className="input-field-wrapper">
							<Label>Preview URL</Label>
							<Input
								disabled={true}
								placeholder={"Subdomain Preview"}
								className="input-field"
								value={`https://www.sweatbase.com/${subdomain}`}
							/>
						</div>
						<Label>DESCRIPTION</Label>
						<Field component="input" name="description" validate={required}>
							{({ input, meta }) => <InputField textarea={true} type="text" input={input} meta={meta} />}
						</Field>
						<Label>FITNESS PHILOSOPHY</Label>
						<Field component="input" name="fitnessPhilosophy" validate={required}>
							{({ input, meta }) => <InputField type="text" input={input} meta={meta} />}
						</Field>
						<Label>Is Franchisee (Do you belong to a larger gym group?)</Label>
						<Field component="checkbox" name="is_franchise" type="checkbox">
							{({ input }) => <Checkbox {...input} />}
						</Field>
						<div style={{ paddingTop: "24px" }}>
							<Button
								className="primary-filled"
								chevronRight
								uppercase
								loading={false}
								disabled={false}
								onClick={handleSubmit}
							>
								Continue
							</Button>
						</div>
					</form>
				);
			}}
		</Form>
	);
};

export default SetupProfileForm;
