import React from "react";
import Modal from "antd/lib/modal";
import { Button } from "antd";
import VideoPlayer from "./VideoPlayer";

const VideoPlayerModal = ({ isShowing, file, updateIsShowingVideoPlayer }) => {
	const videoJsOptions = {
		autoplay: false,
		controls: true,
		errorDisplay: false,
		sources: [
			{
				src: file,
				type: file && file.length > 0 ? file[0]?.type : "video/mp4",
			},
		],
	};

	const videoPlayerStyle = {
		objectFit: "cover",
		margin: "0 auto",
		height: 200,
		width: "100%",
	};

	const handleCancel = () => {
		updateIsShowingVideoPlayer(false);
	};

	if (!isShowing) {
		return null;
	}

	return (
		<Modal
			style={{ position: "relative" }}
			visible={isShowing}
			onCancel={handleCancel}
			footer={[
				<Button key={"video-player-close-key"} type="primary" onClick={() => handleCancel()}>
					Close
				</Button>,
			]}
		>
			<VideoPlayer styleConfig={videoPlayerStyle} options={videoJsOptions} />
		</Modal>
	);
};

export default VideoPlayerModal;
