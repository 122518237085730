// -------------------------------------------------------------------------------
// Libraries
// -------------------------------------------------------------------------------
import React, { useEffect, useState } from "react";
import moment from "moment";
import { useParams } from "react-router";
import axios from "axios";

// -------------------------------------------------------------------------------
// Store
// -------------------------------------------------------------------------------
import { useGetDomainGymQuery, useGetGymMembersByDateQuery } from "store/api/api";

// -------------------------------------------------------------------------------
// Components
// -------------------------------------------------------------------------------
import { StackedBarChart } from "components/Common/Charts/StackedBarChart";
import { LoadingMask } from "components/Common/LoadingMask";

// -------------------------------------------------------------------------------
// Utils, Styles and Assets
// -------------------------------------------------------------------------------
import { StripeCheckSubscriptionStatus } from "constants/urls";
import { variables } from "themes/default/_variables";
import StyledMembersSubscribersStackedBarChart from "./styles";

// -------------------------------------------------------------------------------
// Component
// -------------------------------------------------------------------------------
const MembersSubscribersStackedBarChart = ({ dateRange }) => {
	const { gym } = useParams();
	const domainGymResponse = useGetDomainGymQuery(gym);
	const domainGym = domainGymResponse?.data;

	const gymMembersResponse = useGetGymMembersByDateQuery({
		startDate: dateRange.startDate.format("YYYY-MM-DD"),
		endDate: dateRange.endDate.format("YYYY-MM-DD"),
	});
	const [chartData, setChartData] = useState([]);
	const [subscribers, setSubscribers] = useState([]);

	const bars = [
		{
			name: "Renewing Subs",
			backgroundColor: variables.colors.trout,
		},
		{
			name: "New Subs",
			backgroundColor: domainGym?.brand_color1 || variables.colors.coral,
		},
	];

	const loadSubscriptionInfo = async (subscription) => {
		var newItem = { ...subscription };
		const subscriptionId = subscription.subscription_id;
		if (subscriptionId) {
			try {
				const subscriptionsURL =
					process.env.REACT_APP_API_ENV === "production"
						? StripeCheckSubscriptionStatus.prod
						: StripeCheckSubscriptionStatus.dev;
				const subscriptionResponse = await axios.post(subscriptionsURL, {
					subscriptionId,
				});
				newItem.subscriptionDetails = subscriptionResponse.data;
			} catch (error) {
				console.debug("ERROR subscriptionId: ", subscriptionId, error);
			}
		}
		setSubscribers([...subscribers, newItem]);
	};

	useEffect(() => {
		if (dateRange && gymMembersResponse.data) {
			gymMembersResponse?.data?.subscribers?.forEach((subscriber) => {
				subscriber = loadSubscriptionInfo(subscriber);
			});
		}
	}, [dateRange, gymMembersResponse]);

	useEffect(() => {
		if (dateRange && gymMembersResponse?.data) {
			const labels = [];
			const startDate = dateRange.startDate.clone();
			const endDate = dateRange.endDate.clone();
			if (startDate && endDate) {
				while (endDate > startDate || startDate.format("M") === endDate.format("M")) {
					labels.push(startDate.clone());
					startDate.add(1, "month");
				}
				const tempChartData = labels.map((date, index) => {
					if (!chartData[index]) {
						console.log(" No Chart Data For Index: ", index);
						chartData[index] = { name: date.format("MMM"), "New Subs": 0, "Renewing Subs": 0 };
					}
					var currentNewSubs = chartData[index]["New Subs"] || 0;
					var currentRenewSubs = chartData[index]["Renewing Subs"] || 0;

					// For each month in the chart data, check the subscribers
					subscribers.forEach((subscriber) => {
						if (subscriber.subscriptionDetails) {
							const newItemStartDate = moment.unix(subscriber.subscriptionDetails.start_date);
							// If the newItems created_date is earlier than the current date, then add it to the renewals Subs
							if (newItemStartDate.isBefore(date) && !date.isAfter(moment())) {
								currentRenewSubs = currentRenewSubs + 1;
							}
							// If the newItems created_date is the same as the current date, then add it to the new Subs
							if (
								newItemStartDate.month() === date.month() &&
								newItemStartDate.year() === date.year() &&
								!date.isAfter(moment())
							) {
								currentNewSubs = currentNewSubs + 1;
							}
						}
					});

					return {
						name: date.format("MMM"),
						"Renewing Subs": currentRenewSubs,
						"New Subs": currentNewSubs,
					};
				});
				setChartData(tempChartData);
			}
		}
	}, [subscribers]);

	return (
		<StyledMembersSubscribersStackedBarChart className="sb-members-subscribers-donut-chart">
			{gymMembersResponse.isLoading && chartData.length ? (
				<LoadingMask />
			) : (
				<StackedBarChart data={chartData} bars={bars} />
			)}
		</StyledMembersSubscribersStackedBarChart>
	);
};

export default MembersSubscribersStackedBarChart;

