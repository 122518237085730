import React from "react";

const Categories = ({ values }) => {
	return (
		<div>
			{values.map((value, key) => {
				if (value !== " ") {
					return (
						<div className="tag" key={`categories-list-key-${key}`} style={{ margin: "2px" }}>
							{value}
						</div>
					);
				} else return null;
			})}
		</div>
	);
};

export default Categories;
