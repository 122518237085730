// -----------------------------------------------------------------------------
// Library
// -----------------------------------------------------------------------------
import React, { useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

// -----------------------------------------------------------------------------
// Store
// -----------------------------------------------------------------------------
import { editWorkout } from "store/slices";
import { selectSelectedWorkout } from "store/selectors";

// -----------------------------------------------------------------------------
// Components
// -----------------------------------------------------------------------------
import { Button } from "components/Common/Button";
import { GenreSelector } from "components/Common/GenreSelector";
import { Heading4, Paragraph2 } from "themes/default/_typography";

// -----------------------------------------------------------------------------
// Utils and Styles
// -----------------------------------------------------------------------------
import StyledWorkoutMusicForm from "./styles";
import { useEditWorkoutMutation, useGetDomainGymQuery } from "store/api/api";
import { useParams } from "react-router";

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
const WorkoutMusicForm = ({ nextStep, live }) => {
	const dispatch = useDispatch();
	const [selectedGenre, setSelectedGenre] = useState({});
	const selectedWorkout = useSelector(selectSelectedWorkout);
	const [editWorkoutTrigger, editWorkoutResponse] = useEditWorkoutMutation();
	const { gym } = useParams();
	const domainGymResponse = useGetDomainGymQuery(gym);
	const domainGymId = domainGymResponse.data?.id;
	const onSave = useCallback(() => {
		if (selectedGenre) {
			const { title, id } = selectedGenre;

			dispatch(
				editWorkout({
					workoutId: selectedWorkout.id,
					musicGenre: title,
					playlistId: id,
				})
			);
			editWorkoutTrigger({
				workoutId: selectedWorkout.id,
				gym_id: domainGymId,
				musicGenre: title,
				playlistId: id,
			});
		}

		nextStep();
	}, [dispatch, nextStep, selectedGenre, selectedWorkout]);

	return (
		<StyledWorkoutMusicForm>
			<div className="sb-workout-music-form">
				<div className="sb-workout-music-form__title">
					<Heading4>Choose a Playlist</Heading4>
				</div>
				<GenreSelector setSelectedGenre={setSelectedGenre} />
				<div className="sb-workout-music-form__actions">
					<Button type="button" className="primary-filled" saveIcon uppercase onClick={onSave}>
						<Paragraph2>{live ? "Next" : "Save"}</Paragraph2>
					</Button>
				</div>
			</div>
		</StyledWorkoutMusicForm>
	);
};

export default WorkoutMusicForm;

