import styled from "styled-components";

const StyledPaymentSettings = styled.div`
	.sb-payment-settings {
		&__memberships {
			margin-bottom: 40px;
		}

		&__payment-section {
			margin-bottom: 40px;

			&__payment-method {
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: center;

				.sb-payment-method-card {
					width: 100%;
				}
			}
		}

		&__navigation {
			margin-top: 60px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			gap: 32px;
		}
	}
`;

export default StyledPaymentSettings;
