export const required = (value) => (value ? undefined : "Required");

export const validateEmail = (email) => {
	if (!email) return "Email address cannot be empty";
	const re =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

	if (!re.test(String(email).toLowerCase())) {
		return "Invalid email entered";
	}

	return undefined;
};

export const validateEmailOrPhone = (input) => {
	const emailValidationResult = validateEmail(input);
	const phoneValidationResult = validatePhoneNumber(input);

	if (emailValidationResult === undefined || phoneValidationResult === undefined) {
		return undefined;
	}

	return "Input must be a valid email address or phone number";
};

export const verifyPassword = (password) => {
	if (!password) return "Password cannot be blank";
	if (password.length < 4) return "Password is too weak, you can do better";
	if (password.length > 4 && password.length < 8) return "Password should be at least 8 characters";
	if (!/\d/.test(password) || !/[a-zA-Z]/.test(password))
		return "Password must contain at least one number and one letter";
	return undefined;
};

export const confirmPassword = (confirmPassword, password) => {
	if (!confirmPassword) return "Confirmation Password cannot be blank";
	if (confirmPassword !== password) return "Passwords do not match";
	return undefined;
};

export const isValidPhoneNumber = (phone) => {
	const re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
	return re.test(String(phone).toLowerCase());
};

export const validatePhoneNumber = (phone) => {
	if (!isValidPhoneNumber(phone)) return "Format phone numbers using only numbers e.g. 1235556789";
	return undefined;
};

export const validateSubdomain = (subdomain) => {
	if (!subdomain) return "Subdomain cannot be empty";
	const re = /^[a-zA-Z0-9]+$/;
	if (!re.test(subdomain)) return "Please make sure your subdomain contains only alphanumeric characters";
	if (subdomain.length < 4) return "Subdomain must be at least 4 characters";
	if (subdomain.length > 25) return "Subdomain must be fewer than 25 characters";
	return undefined;
};
