// -------------------------------------------------------------------------------
// Libraries
// -------------------------------------------------------------------------------
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

// -------------------------------------------------------------------------------
// Component, selectors and others
// -------------------------------------------------------------------------------
import { Paragraph11 } from "themes/default/_typography";
import { PlanCard } from "components/Common/Cards/PlanCard";
import { BasicCard } from "components/Common/Cards/BasicCard";
import { LoadingMask } from "components/Common/LoadingMask";
import ContactSalesModal from "components/Modals/ContactSalesModal";
import { GearIcon } from "components/Icons";

// -------------------------------------------------------------------------------
// Store
// -------------------------------------------------------------------------------
import { selectProducts, selectProductsLoading, selectCreatorMemberships } from "store/selectors";
import { updateSelectedProduct } from "store/slices";

// -------------------------------------------------------------------------------
// Utils, Styles and Assets
// -------------------------------------------------------------------------------
import { paymentPlans, paymentPlansProd } from "helpers/constants";
import StyledPlanSelection from "./styles";
import {
	useGetCreatorMembershipsQuery,
	useGetCurrentUserQuery,
	useGetProductsQuery,
	useUpdateUserConnectedAccountMutation,
	useUpdateUserCustomerIdMutation,
} from "store/api/api";
import axios from "axios";
import AddPaymentMethodModal from "components/Modals/AddPaymentMethodModal";
import { useRecoilValue } from "recoil";
import { userAtom } from "globalAtoms";
import {
	StripeCreateConnectedAccount,
	StripeCreateCustomer,
	StripeCreateSetupIntent,
	StripeCreateSubscriptionToPlatform,
	StripeGetCustomerSubscriptions,
} from "constants/urls";

// -------------------------------------------------------------------------------
// Component
// -------------------------------------------------------------------------------
const PlanSelection = ({ selectedColor, className, handleCompleteSubscribe, ...other }) => {
	const plans = process.env.REACT_APP_API_ENV === "production" ? paymentPlansProd : paymentPlans;
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [clientSecret, setClientSecret] = useState(null);
	const [setupIntentId, setSetupIntentId] = useState(null);
	const [showPaymentMethodModal, setShowPaymentMethodModal] = useState(false);
	const [showContactSalesModal, setShowContactSalesModal] = useState(false);
	const creatorMemberships = useSelector(selectCreatorMemberships);
	const { data, isLoading, error } = useGetProductsQuery(selectProducts);
	const products = useSelector(selectProducts);
	const [connectCustomerIdTrigger, updateCustomerObj] = useUpdateUserCustomerIdMutation();
	const [currentSubscription, setCurrentSubscription] = useState(null);
	const [subscribing, setSubscribing] = useState(false);
	const [connectAcountIdTrigger, updateConnectedAccountObj] = useUpdateUserConnectedAccountMutation();
	const productsLoading = useSelector(selectProductsLoading);
	const user = useRecoilValue(userAtom);
	const token = localStorage.getItem("creator_jwt_token");
	const split = token.split(".")[1];
	const formatted = JSON.parse(atob(split));
	const JWTID = formatted.id;
	const { data: currentUserData, isError } = useGetCurrentUserQuery({ userId: user.id });
	const temp_price_id = "price_1McI8cAyyHgh37gnudhASB5Q";
	const { data: creatorMembershipsData, isError: creatorMembershipsError } = useGetCreatorMembershipsQuery();
	useEffect(() => {
		if (creatorMemberships && creatorMemberships.length) {
			//navigate("/dashboard/payment-settings");
		}
	}, [creatorMemberships, navigate, isLoading]);

	useEffect(() => {
		if (user) {
			fetchCustomerSubscriptions();
		}
	}, [user]);

	const fetchCustomerSubscriptions = async () => {
		try {
			const getSubscriptionsUrl =
				process.env.REACT_APP_API_ENV === "production"
					? StripeGetCustomerSubscriptions.prod
					: StripeGetCustomerSubscriptions.dev;
			const subscriptionsResponse = await axios.post(getSubscriptionsUrl, {
				customerId: user.customer_id,
			});
			if (subscriptionsResponse.data.data.length > 0) {
				var data = subscriptionsResponse.data.data[0].items.data[0];
				setCurrentSubscription(data.plan.id);
			}
		} catch (error) {
			console.error("ERROR: ", error);
		}
	};

	const createSetupIntent = async () => {
		if (!user.customer_id) {
			// Need to create a customer
			try {
				// ADDITIONAL PAYMENTS API CALL TO CREATE STRIPE CUSTOMER & CONNECTED ACCOUNT

				// Create Connected Account
				const createConnectedAccountUrl =
					process.env.REACT_APP_API_ENV === "production"
						? StripeCreateConnectedAccount.prod
						: StripeCreateConnectedAccount.dev;
				const connectedAccountResponse = await axios.get(createConnectedAccountUrl);
				// Response Object contains data.id which is the account id
				// TODO: should save this in the db customer object as well
				// Create Stripe Customer
				const createCustomerUrl =
					process.env.REACT_APP_API_ENV === "production" ? StripeCreateCustomer.prod : StripeCreateCustomer.dev;
				const customerResponse = await axios.post(createCustomerUrl, {
					email: formatted.email,
				});

				connectCustomerIdTrigger({ body: { stripe_customer_id: customerResponse.data.id } });
				connectAcountIdTrigger({ body: { stripe_account_id: connectedAccountResponse.data.id } });

				// Create Setup Intent

				const createSetupIntentUrl =
					process.env.REACT_APP_API_ENV === "production" ? StripeCreateSetupIntent.prod : StripeCreateSetupIntent.dev;

				const setupIntent = await axios.post(createSetupIntentUrl, {
					customerId: customerResponse.data.id,
				});
				setClientSecret(setupIntent.data.client_secret);
				setSetupIntentId(setupIntent.data.id);
				setShowPaymentMethodModal(true);
			} catch (error) {
				console.debug("error Creating Setup Intent: ", error);
			}
		} else {
			try {
				const createSetupIntentUrl =
					process.env.REACT_APP_API_ENV === "production" ? StripeCreateSetupIntent.prod : StripeCreateSetupIntent.dev;

				const setupIntent = await axios.post(createSetupIntentUrl, {
					customerId: user.customer_id,
				});
				setClientSecret(setupIntent.data.client_secret);
				setSetupIntentId(setupIntent.data.id);
				setShowPaymentMethodModal(true);
			} catch (error) {
				console.debug("error Creating Setup Intent: ", error);
			}
		}
	};

	const createSubscriptionToPlatform = async (price_id) => {
		try {
			const createSubscriptionUrl =
				process.env.REACT_APP_API_ENV === "production"
					? StripeCreateSubscriptionToPlatform.prod
					: StripeCreateSubscriptionToPlatform.dev;

			const createSubscription = await axios.post(createSubscriptionUrl, {
				accountId: user.connected_account_id,
				customerId: user.customer_id,
				priceId: price_id,
			});
			console.debug("createdSubscription: ", createSubscription);
			handleCompleteSubscribe();
			setSubscribing(false);
		} catch (error) {
			console.debug("error Creating Subscription: ", error);
			setSubscribing(false);

			// Show Error Message Here
		}
	};

	const handleSelectPlan = useCallback(
		(product, priceId) => {
			const prodPrice = { ...product, priceId };
			dispatch(updateSelectedProduct({ product: prodPrice }));
			if (product.type === "starter") {
				// Stripe Create Subscription to Platform Call Here
				setSubscribing(true);
				createSubscriptionToPlatform(priceId);
			} else {
				// Stripe Create Setup Intent Call Here
				createSetupIntent();
			}
		},
		[navigate, dispatch]
	);

	if (error) {
		console.debug("error: ", error);
	}
	if (data) {
		console.debug("Products Data: ", data);
	}
	return (
		<StyledPlanSelection className={`${className} sb-plan-selection`} {...other}>
			<div className="sb-plan-selection__plans-container">
				{isLoading || subscribing ? (
					<LoadingMask />
				) : (
					<>
						{data?.products?.map((product) =>
							plans[product.type] ? (
								<PlanCard
									isCurrentSubscription={currentSubscription === plans[product.type].price_id}
									type={product.type}
									onClick={() => handleSelectPlan(product, plans[product.type].price_id)}
									key={plans[product.type].title}
									icon={plans[product.type].icon}
									title={plans[product.type].title}
									price={plans[product.type].price}
									priceId={plans[product.type].price_id}
									priceDescription={plans[product.type].priceDescription}
									description={plans[product.type].description}
									featuresList={plans[product.type].featuresList}
									priceFrequency={plans[product.type].priceFrequency}
								/>
							) : null
						)}
						<PlanCard
							type="elite"
							onClick={() => setShowContactSalesModal(true)}
							key={plans["elite"].title}
							icon={plans["elite"].icon}
							title={plans["elite"].title}
							price={plans["elite"].price}
							priceDescription={plans["elite"].priceDescription}
							description={plans["elite"].description}
							featuresList={plans["elite"].featuresList}
							priceFrequency={plans["elite"].priceFrequency}
						/>
					</>
				)}
			</div>
			<div className="sb-plan-selection__disclaimer">
				<BasicCard icon={<GearIcon />}>
					<Paragraph11>
						Pro and Studio PRO tiers are designed for gyms and studios and allow you to include SWEATBASE as part of
						your membership, or charge separately. You can decide to charge as much or as little as you wish. These
						tiers include a bundle of song plays. These plays do not roll over from month to month and your account will
						be charged at $.03 per song per user once the included plays are exceeded.
					</Paragraph11>
				</BasicCard>
			</div>
			<ContactSalesModal visible={showContactSalesModal} setVisible={setShowContactSalesModal} />
			<AddPaymentMethodModal
				setupIntentId={setupIntentId}
				visible={showPaymentMethodModal}
				setVisible={setShowPaymentMethodModal}
				clientSecret={clientSecret}
			/>
		</StyledPlanSelection>
	);
};

export default PlanSelection;
