import React, { useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import { Button } from "components/Common/Button";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import InputField from "components/UI/Form/InputField";
import Label from "components/UI/Form/label";
import { required, verifyPassword, validateEmail, validatePhoneNumber } from "helpers/Form/commonFormValidations";
import { useNavigate, useParams } from "react-router-dom";
import TwoFaForm from "./TwoFaForm";
import { useGetDomainGymQuery, useSignUpConsumerAtGymMutation } from "store/api/api";
import { Paragraph9 } from "themes/default/_typography";
import { notification } from "antd";
import { useSetRecoilState } from "recoil";
import { userAtom } from "globalAtoms";
import { getUser } from "useInit";

function SignUpForm({ preview, match }) {
	const setUser = useSetRecoilState(userAtom);
	const navigate = useNavigate();
	const [loggingIn, updateLoggingIn] = useState(false);
	const [potentialUser, updatePotentialUser] = useState(null);
	const { gym } = useParams();
	const { data: domainGymData } = useGetDomainGymQuery(gym);
	const [signUpConsumerAtGymTrigger, signUpConsumerAtGymResponse] = useSignUpConsumerAtGymMutation();

	const handleFormSubmit = async (formObj) => {
		if (preview) return;

		const json = {
			email_address: formObj.email,
			first_name: formObj.firstName,
			last_name: formObj.lastName,
			password: formObj.password,
			phone_number: formObj.phoneNumber,
			pin: formObj.pin || null,
			user_type: "consumer",
			registration_path: "web",
			campaign: "organic",
		};

		signUpConsumerAtGymTrigger({ ...json, gymId: domainGymData?.id });
	};

	const handleNav = async () => {
		await getUser(setUser);
		navigate(`/../../${domainGymData?.subdomain}/dashboard/`);
	};

	useEffect(() => {
		if (signUpConsumerAtGymResponse.isSuccess) {
			const { auth_token } = signUpConsumerAtGymResponse.data;
			localStorage.setItem("creator_jwt_token", auth_token);
			notification.success({
				message: "Successfully signed up!",
			});
			handleNav();
		} else if (signUpConsumerAtGymResponse.isError) {
			if (signUpConsumerAtGymResponse.error.status === 409) {
				notification.error({
					message: "Email or phone are already in use. If you have an account, please log in.",
				});
			} else {
				notification.error({
					message: "Error signing up. Please try again later.",
				});
			}
		}
	}, [signUpConsumerAtGymResponse]);

	return (
		<div className="sb-signup-form">
			{potentialUser ? (
				<TwoFaForm
					updateLoggingIn={updateLoggingIn}
					updatePotentialUser={updatePotentialUser}
					potentialUser={potentialUser}
				/>
			) : (
				<Form onSubmit={handleFormSubmit}>
					{({ handleSubmit }) => {
						return (
							<form id="signup" onSubmit={handleSubmit}>
								{preview && <Label>This creator is still setting up their gym, check back soon!</Label>}
								{!preview && (
									<div>
										<Label>Phone Number</Label>
										<Field component="input" name="phoneNumber" validate={validatePhoneNumber}>
											{({ input, meta }) => (
												<InputField
													data-cy="sb-signup-page-phone-field"
													disabled={preview}
													type="text"
													input={input}
													meta={meta}
												/>
											)}
										</Field>
										<Row gutter={16}>
											<Col span={12}>
												<Label>First Name</Label>
												<Field component="input" name="firstName" validate={required}>
													{({ input, meta }) => (
														<InputField
															data-cy="sb-signup-page-first-name-field"
															disabled={preview}
															type="text"
															input={input}
															meta={meta}
														/>
													)}
												</Field>
											</Col>
											<Col span={12}>
												<Label>Last Name</Label>
												<Field component="input" name="lastName" validate={required}>
													{({ input, meta }) => (
														<InputField
															data-cy="sb-signup-page-last-name-field"
															disabled={preview}
															type="text"
															input={input}
															meta={meta}
														/>
													)}
												</Field>
											</Col>
										</Row>

										<Label>Email</Label>
										<Field component="input" name="email" validate={validateEmail}>
											{({ input, meta }) => (
												<InputField
													data-cy="sb-signup-page-email-field"
													disabled={preview}
													type="text"
													input={input}
													meta={meta}
												/>
											)}
										</Field>

										<Label>Password</Label>
										<Field component="input" name="password" validate={verifyPassword}>
											{({ input, meta }) => (
												<InputField
													data-cy="sb-signup-page-password-field"
													disabled={preview}
													type="password"
													input={input}
													meta={meta}
												/>
											)}
										</Field>

										<Label>PIN (optional)</Label>
										<Field component="input" name="pin">
											{({ input, meta }) => (
												<InputField
													data-cy="sb-signup-page-pin-field"
													disabled={preview}
													type="text"
													input={input}
													meta={meta}
												/>
											)}
										</Field>

										<Button
											data-cy="sb-signup-page-sign-up-button"
											className="primary-filled--branding-primary-color"
											uppercase
											disabled={signUpConsumerAtGymResponse.isLoading}
											loading={signUpConsumerAtGymResponse.isLoading}
											type="submit"
											style={{
												backgroundColor: domainGymData?.brand_color1 !== "" ? domainGymData?.brand_color1 : null,
											}}
										>
											<Paragraph9>Sign Up</Paragraph9>
										</Button>
									</div>
								)}
							</form>
						);
					}}
				</Form>
			)}
		</div>
	);
}

export default SignUpForm;
