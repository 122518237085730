// -------------------------------------------------------------------------------
// Libraries
// -------------------------------------------------------------------------------
import moment from "moment";
import { CSVLink } from "react-csv";
import React, { useState, useEffect, useCallback } from "react";
import { Table, Popover, Checkbox } from "antd";
import { DollarCircleOutlined, FilterOutlined } from "@ant-design/icons";

// -------------------------------------------------------------------------------
// Store
// -------------------------------------------------------------------------------
import { useGetGymMembersByDateQuery } from "store/api/api";

// -------------------------------------------------------------------------------
// Components
// -------------------------------------------------------------------------------
import { Paragraph30 } from "themes/default/_typography";
import { LoadingMask } from "components/Common/LoadingMask";
import { SearchInput } from "components/Common/Form/SearchInput";
import { Button } from "components/Common/Button";
import { Tag } from "components/Common/Tag";

// -------------------------------------------------------------------------------
// Utils, Styles and Assets
// -------------------------------------------------------------------------------
import StyledMembersTable from "./styles";

const plainOptions = ["Members", "Free"];
const CheckboxGroup = Checkbox.Group;

// -------------------------------------------------------------------------------
// Component
// -------------------------------------------------------------------------------
const MembersTable = (props) => {
	const { dateRange } = props;
	const [checkedList, setCheckedList] = useState([]);
	const [indeterminate, setIndeterminate] = useState(true);
	const [checkAll, setCheckAll] = useState(false);
	const [tableData, setTableData] = useState(null);
	const [tableColumns, setTableColumns] = useState(null);
	const gymMembersResponse = useGetGymMembersByDateQuery({
		startDate: dateRange.startDate.format("YYYY-MM-DD"),
		endDate: dateRange.endDate.format("YYYY-MM-DD"),
	});
	const members = gymMembersResponse?.data;
	const membersLoading = gymMembersResponse.isLoading;
  
	useEffect(() => {
		if (members?.subscribers) {
			setTableColumns([
				{
					title: "Member",
					dataIndex: "member",
					key: "member",
				},
				{
					title: "Email",
					dataIndex: "email",
					key: "email",
				},
				{
					title: "Member Type",
					dataIndex: "memberType",
					key: "memberType",
				},
				{
					title: "Last Active",
					dataIndex: "lastLoginDate",
					key: "lastLoginDate",
				},
				{
					title: "Joined",
					dataIndex: "joined",
					key: "joined",
				},
			]);

			setTableData(
				members.members.map((m) => ({
					key: m.user_id,
					member: `${m.first_name || ""} ${m.last_name || ""}`,
					email: m.email_address,
					memberType: renderMemberTypeComponent(m.type === "trainer" ? "trainer" : m.subscription_status),
					lastLoginDate: moment(m.last_login_at).format("MMM D, YYYY"),
					joined: moment(m.created_date).format("MMM D, YYYY"),
				}))
			);
		}
	}, [members]);

	useEffect(() => {
		if (members?.subscribers) {
			setTableData(
				members.subscribers
					.map((m) => {
						return {
							key: m.user_id,
							member: `${m.first_name || ""} ${m.last_name || ""}`,
							email: m.email_address || "",
							memberType: renderMemberTypeComponent(m.type === "trainer" ? "trainer" : m.subscription_status),
							memberTypeString: m.subscription_status,
							lastLoginDate: moment(m.last_login_at).format("MMM D, YYYY"),
							joined: moment(m.created_date).format("MMM D YYYY"),
						};
					})
					.filter((m) => (indeterminate ? true : checkedList.includes(m.memberTypeString)))
			);
		}
	}, [checkedList, members, indeterminate]);

	const renderMemberTypeComponent = (subscriptionStatus) => {
		if (subscriptionStatus === "trainer") {
			return <Tag text="Trainer" icon={<DollarCircleOutlined />} className="tag-filled-paid" />;
		} else if (subscriptionStatus === "ACTIVE") {
			return <Tag text="Member" icon={<DollarCircleOutlined />} className="tag-filled-paid" />;
		} else if (subscriptionStatus === "CANCELLED") {
			return <Tag text="Cancelled" icon={<DollarCircleOutlined />} className="tag-filled-cancelled" />;
		} else {
			return <Tag text="Trial" icon={<DollarCircleOutlined />} className="tag-filled-trial" />;
		}
	};

	const handleSearchTermEnter = useCallback(
		(value) => {
			setTableData(
				members.subscribers
					.map((m) => ({
						key: m.user_id,
						member: `${m.first_name || ""} ${m.last_name || ""}`,
						email: m.email_address || "",
						memberType:
							members.members && !members.members.find((mm) => mm.user_id === m.id) ? (
								<Tag text="Member" icon={<DollarCircleOutlined />} className="tag-filled-primary" />
							) : (
								<Tag text="Free" icon={<DollarCircleOutlined />} className="tag-filled-green" />
							),
						joined: moment(m.created_date).format("MMM D YYYY"),
					}))
					.filter((m) =>
						value.trim()
							? m.member.toLowerCase().includes(value.toLowerCase()) ||
							  m.email.toLowerCase().includes(value.toLowerCase())
							: true
					)
			);
		},
		[members]
	);

	const onFilterChange = useCallback(
		(list) => {
			setCheckedList(list);
			setCheckAll(list.length === plainOptions.length);

			if (indeterminate) {
				setIndeterminate(false);
			}
		},
		[indeterminate]
	);

	const onCheckAllChange = useCallback(
		(e) => {
			setCheckedList(e.target.checked ? plainOptions : []);
			setCheckAll(e.target.checked);

			if (indeterminate) {
				setIndeterminate(false);
			}
		},
		[indeterminate]
	);

	return (
		<StyledMembersTable className="sb-members-table">
			{membersLoading || !tableData || !tableColumns ? (
				<LoadingMask />
			) : (
				<>
					<div className="sb-members-table__actions">
						<div className="sb-members-table__actions__filters">
							<Button className="primary-filled" uppercase>
								Add Member
							</Button>

							<SearchInput placeholder="Search Members" onEnter={handleSearchTermEnter} />
							<Popover
								placement="bottomRight"
								title={
									<div className="sb-popover-filter-header">
										<Paragraph30>Filters</Paragraph30>
										<Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
											Check all
										</Checkbox>
									</div>
								}
								content={<CheckboxGroup options={plainOptions} value={checkedList} onChange={onFilterChange} />}
								trigger="click"
							>
								<button>
									<FilterOutlined />
									Filters
								</button>
							</Popover>
						</div>
						<div className="sb-members-table__actions__download">
							<CSVLink data={tableData} filename={`members-${new Date().getTime()}.csv`} target="_blank">
								<Button className="primary-filled" uppercase downloadIcon>
									Download
								</Button>
							</CSVLink>
						</div>
					</div>
					<Table dataSource={tableData} columns={tableColumns} />
				</>
			)}
		</StyledMembersTable>
	);
};

export default MembersTable;

