import styled from "styled-components";

const StyledDonutProgressChart = styled.div`
	.sb-donut-progress-chart {
		&__progress-label {
			font-family: "DM Sans", sans-serif;
			font-style: normal;
			font-weight: 400;
			font-size: 18px;
			line-height: 18px;
			letter-spacing: -0.4px;
			color: ${({ theme }) => theme.colors.trout};
		}
	}
`;

export default StyledDonutProgressChart;
