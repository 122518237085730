// -----------------------------------------------------------------------------
// Library
// -----------------------------------------------------------------------------
import React, { useEffect } from "react";
import { Field, Form } from "react-final-form";
import notification from "antd/lib/notification";

// -----------------------------------------------------------------------------
// Components and helpers
// -----------------------------------------------------------------------------
import InputField from "components/UI/Form/InputField";
import Label from "components/UI/Form/label";
import { CREATE_ACCOUNT_FORM_ID } from "../constants";
import {
	validateEmail,
	confirmPassword,
	verifyPassword,
	validatePhoneNumber,
} from "helpers/Form/commonFormValidations";
import { useCreateConnectedAccountMutation, useCreateCustomerMutation, useSignUpMutation } from "store/api/api";
import { useNavigate } from "react-router";
import { Button } from "components/Common/Button";
import { useDispatch } from "react-redux";
import { updateUserId } from "store/slices";

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
const CreateAccountForm = (props) => {
	const navigate = useNavigate();
	const [signUpTrigger, signUpResponse] = useSignUpMutation();
	const [createStripeCustomerTrigger, createStripeCustomerResponse] = useCreateCustomerMutation();
	const [createStripeConnectedAccountTrigger, createConnectedAccountObj] = useCreateConnectedAccountMutation();
	const dispatch = useDispatch();
	const addStripeCustomerId = async (auth_token) => {
		try {
			await createStripeCustomerTrigger({ auth_token });
		} catch (error) {
			console.error("Error Creating Customer: ");
		}
	};

	useEffect(() => {
		if (createStripeCustomerResponse.isSuccess) {
			notification.success({
				message: "Customer ID Created",
			});
		} else if (createStripeCustomerResponse.isError) {
			notification.error({
				message: "Error Creating Customer ID!",
			});
			console.error("Customer Create Error: ", createStripeCustomerResponse);
		}
	}, [createStripeCustomerResponse]);

	const addStripeConnectedAccountId = async (auth_token) => {
		try {
			await createStripeConnectedAccountTrigger({ auth_token });
		} catch (error) {
			console.error("Error Creating Customer: ");
		}
	};

	useEffect(() => {
		if (createConnectedAccountObj.isSuccess) {
			notification.success({
				message: "Connected Account Created",
			});
		} else if (createConnectedAccountObj.isError) {
			notification.error({
				message: "Error Creating Connected Account",
			});
			console.error("Connected Account Create Error: ", createConnectedAccountObj);
		}
	}, [createConnectedAccountObj]);

	const handleSignUp = async (formObj) => {
		const { email, password, phoneNumber } = formObj;
		try {
			// Trying to sign up should should also test login
			// Sign Up User
			const body = {
				email_address: email,
				password,
				phone_number: phoneNumber,
				first_name: "",
				last_name: "",
				registration_path: "web",
				campaign: "",
			};
			await signUpTrigger(body);
		} catch (e) {
			console.error("Sign Up Error: ", e);
			return notification.error({
				message: "An error occurred signing you up",
			});
		}
	};

	// After Successful Sign Up, Create Stripe Customer & Connected Account
	useEffect(() => {
		if (signUpResponse.isSuccess) {
			notification.success({
				message: "Account Created!",
			});
			const { auth_token, user } = signUpResponse.data;
			if (auth_token && user) {
				localStorage.setItem("creator_jwt_token", auth_token);
				dispatch(updateUserId(user.id));
			}
			addStripeCustomerId(auth_token);
			addStripeConnectedAccountId(auth_token);
		} else if (signUpResponse.isError) {
			notification.error({
				message: "Error Creating Stripe Account",
			});
			console.error("Sign Up Error: ", signUpResponse);
		}
	}, [signUpResponse]);

	const handleFormSubmit = async (formObj) => {
		handleSignUp(formObj);
	};

	const isLoading =
		signUpResponse.isLoading || createStripeCustomerResponse.isLoading || createConnectedAccountObj.isLoading;

	useEffect(() => {
		// If all is good, navigate to next page
		if (signUpResponse.isSuccess && createStripeCustomerResponse.isSuccess && createConnectedAccountObj.isSuccess) {
			navigate("/creator-creation/your-information");
		}
	}, [signUpResponse, createStripeCustomerResponse, createConnectedAccountObj]);

	return (
		<Form onSubmit={handleFormSubmit}>
			{({ handleSubmit, values }) => {
				return (
					<form id={CREATE_ACCOUNT_FORM_ID} onSubmit={handleSubmit}>
						<Label>Email</Label>
						<Field name="email" validate={validateEmail}>
							{({ input, meta }) => (
								<InputField type="email" placeholder="Enter Email Address..." input={input} meta={meta} />
							)}
						</Field>
						<Label>Phone Number</Label>
						<Field name="phoneNumber" validate={validatePhoneNumber}>
							{({ input, meta }) => (
								<InputField placeholder="Enter Phone Number..." type="text" input={input} meta={meta} phone />
							)}
						</Field>
						<Label>Password</Label>
						<Field type="password" name="password" validate={verifyPassword}>
							{({ input, meta }) => <InputField placeholder="Enter Password..." input={input} meta={meta} />}
						</Field>
						<Label>Confirm Password</Label>
						<Field name="confirmPassword" validate={(v) => confirmPassword(v, values.password)}>
							{({ input, meta }) => (
								<InputField placeholder="Enter Password Again..." type="password" input={input} meta={meta} />
							)}
						</Field>
						<Button
							className="primary-filled"
							chevronRight
							uppercase
							loading={isLoading}
							disabled={isLoading}
							onClick={handleFormSubmit}
						>
							Continue
						</Button>
					</form>
				);
			}}
		</Form>
	);
};

export default CreateAccountForm;
