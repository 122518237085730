import React from "react";
import AntDMenu from "antd/lib/menu";
import PropTypes from "prop-types";

function Menu(props) {
	const { options, defaultSelectedKeys, defaultOpenKeys, onClick } = props;

	const renderMenuItem = (option) => {
		return (
			<AntDMenu.Item
				data-cy={`menu-nav-link-${option.id}`}
				disabled={option.disabled}
				key={option.id}
				icon={option.icon}
			>
				{option.text}
			</AntDMenu.Item>
		);
	};

	return (
		<AntDMenu onClick={onClick} defaultOpenKeys={defaultOpenKeys} selectedKeys={defaultSelectedKeys} mode="inline">
			{options.map((option) => renderMenuItem(option))}
		</AntDMenu>
	);
}

Menu.propTypes = {
	options: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string,
			icon: PropTypes.any,
		})
	),
	defaultSelectedKeys: PropTypes.arrayOf(PropTypes.string),
};

export default Menu;
