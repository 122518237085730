import React from "react";
import PropTypes from "prop-types";
import { useRecoilValue } from "recoil";
import { creatorCreationFlowState } from "../atoms";
import { navigateBack } from "../helpers";
import { NAV_TYPE_PREV } from "../constants";
import { Button } from "components/Common/Button";
import { useNavigate } from "react-router";

function NavigationButton({ type, submitID, isSubmittingForm, history, location, clickCallback }) {
	const creatorCreationFlow = useRecoilValue(creatorCreationFlowState);
	const navigate = useNavigate();
	let text = type === "next" ? "Continue" : "Previous";

	if (type === "save") {
		text = "Save and Proceed";
	} else if (type === "refresh") {
		text = "Save and Preview Changes";
	} else if (type === "saveSetting") {
		text = "Save Changes";
	}

	const handleNavigation = () => {
		if (type === NAV_TYPE_PREV) {
			return navigate(-1);
			//return navigateBack(history, location.pathname);
		}

		if (type === "save") {
			document.getElementById(submitID).setAttribute("name", "save");
		} else if (type === "refresh") {
			document.getElementById(submitID).setAttribute("name", "refresh");
		}

		if (isSubmittingForm) {
			document.getElementById(submitID).dispatchEvent(
				new Event("submit", {
					bubbles: true,
					cancelable: true,
				})
			);
		}

		if (clickCallback) {
			clickCallback();
		}
	};

	if (type === NAV_TYPE_PREV && creatorCreationFlow.currentFormIndex < 2) return null;

	return (
		<Button
			className="primary-filled"
			saveIcon={type === "save" || type === "saveSetting" || type === "refresh"}
			chevronRight={type !== "save" && type !== "saveSetting" && type !== "refresh"}
			uppercase
			loading={creatorCreationFlow.saving}
			onClick={handleNavigation}
		>
			{text}
		</Button>
	);
}

NavigationButton.propTypes = {
	type: PropTypes.string,
	submitID: PropTypes.string,
	isSubmittingForm: PropTypes.bool,
};

export default NavigationButton;
