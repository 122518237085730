// -----------------------------------------------------------------------------
// Library
// -----------------------------------------------------------------------------
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { Form, Select, Switch, Tooltip } from "antd";
import moment from "moment";
import { Label2 } from "themes/default/_typography";

// -----------------------------------------------------------------------------
// Store
// -----------------------------------------------------------------------------
import { selectSelectedWorkout } from "store/selectors";
import {
	useEditWorkoutMutation,
	useGetCategoriesQuery,
	useGetDomainGymQuery,
	useGetGymWorkoutsQuery,
} from "store/api/api";

// -----------------------------------------------------------------------------
// Components
// -----------------------------------------------------------------------------
import { Button } from "components/Common/Button";
import { Paragraph2, Span4 } from "themes/default/_typography";
import { FormTagSelect } from "components/Common/Form/FormTagSelect";
import { FormInput } from "components/Common/Form/FormInput";
import { FormDateSelect } from "components/Common/Form/FormDateSelect";
import { FormTimeSelect } from "components/Common/Form/FormTimeSelect";
import { FormSelect } from "components/Common/Form/FormSelect";
import Toggle from "components/UI/Form/Toggle";

// -----------------------------------------------------------------------------
// Utils and Styles
// -----------------------------------------------------------------------------
import StyledWorkoutDetailsForm from "./styles";

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
const { Option } = Select;
const WorkoutDetailsForm = ({ nextStep, live }) => {
	const [form] = Form.useForm();
	const selectedWorkout = useSelector(selectSelectedWorkout);
	const { gym } = useParams();
	const domainGymResponse = useGetDomainGymQuery(gym);
	const domainGymId = domainGymResponse.data?.id;

	const getWorkoutsResponse = useGetGymWorkoutsQuery(domainGymId, { skip: !domainGymResponse.data });
	const [editWorkoutTrigger] = useEditWorkoutMutation();

	const [scheduleRelease, setScheduleRelease] = useState(false);
	const [active, setActive] = useState(true);
	const [isFree, setIsFree] = useState(false);

	const { data: categoriesData, refetch: refetchCategories } = useGetCategoriesQuery(domainGymId, {
		skip: !domainGymId,
	});

	const handleChangeActive = (checked, event) => {
		setActive(checked);
	};

	const handleChangeScheduling = (checked, event) => {
		setScheduleRelease(checked);
		if (checked && !active) {
			setActive(true);
		}
	};

	const convertStartTime = (workoutDate, workoutTime, workoutTimezone) => {
		const date = moment(workoutDate).format("YYYY-MM-DD");
		const time = moment(workoutTime).format("HH:mm");
		const startTime = moment.tz(`${date} ${time}`, workoutTimezone).format("YYYY-MM-DD HH:mm:ss");

		return startTime;
	};

	const onFinish = async ({
		workoutCategories,
		workoutEquipment,
		workoutLength,
		workoutDate,
		workoutTime,
		workoutTimezone,
	}) => {
		try {
			await editWorkoutTrigger({
				workoutId: selectedWorkout.id,
				categories: workoutCategories ? workoutCategories.join(",") : "",
				equipment: workoutEquipment ? workoutEquipment : "",
				// If not live, duration is null
				duration: !live ? null : workoutLength ? workoutLength : 0,
				// If live, leave draft status as 1
				draft: live ? null : active ? 0 : 1,
				// If scheduled release and not live, set start time
				start_time: scheduleRelease && !live ? convertStartTime(workoutDate, workoutTime, workoutTimezone) : null,
				is_free: +isFree,
			}).unwrap();
			form.resetFields();
			refetchCategories();
			getWorkoutsResponse.refetch();
			nextStep();
		} catch (error) {
			console.error("Error editing workout", error);
		}
	};

	return (
		<StyledWorkoutDetailsForm>
			<div className="sb-workout-details-form">
				<Form onFinish={onFinish} layout="vertical" form={form}>
					{categoriesData?.categories?.length > 0 && (
						<FormTagSelect
							name="workoutCategories"
							label="Workout Categories"
							placeholder="Enter Categories"
							options={categoriesData?.categories?.map((cat) => ({
								value: cat.name,
								id: cat.id,
							}))}
						/>
					)}
					{live && (
						<div className="sb-workout-details-form__form__field">
							<Form.Item name="workoutLength" label={<Label2>Workout Length</Label2>}>
								<Select placeholder="Workout Length" className="sb-select">
									<Option value={"30"}>30 Minutes</Option>
									<Option value={"45"}>45 Minutes</Option>
									<Option value={"60"}>60 Minutes</Option>
									<Option value={"90"}>90 Minutes</Option>
									<Option value={"120"}>120 Minutes</Option>
								</Select>
							</Form.Item>
						</div>
					)}
					<div className="sb-workout-details-form__form__field">
						<FormInput
							name="workoutEquipment"
							label={
								<span className="sb-workout-details-form__form__field__label">
									<span className="sb-workout-details-form__form__field__label__main">Equipment</span>
									<Span4 className="sb-workout-details-form__form__field__label__extra">(Separate With Commas)</Span4>
								</span>
							}
						/>
					</div>
					{!live && (
						<div className="sb-workout-details-form__form__activation-schedule">
							<div>
								<span
									className="sb-workout-details-form__form__field__label__main"
									style={{ color: scheduleRelease ? "grey" : "black" }}
								>
									Active
								</span>
								<Switch
									checked={active}
									disabled={scheduleRelease}
									onChange={(checked) => handleChangeActive(checked)}
								/>
							</div>
							{/* Hide Per Annas Request <div>
								<span className="sb-workout-details-form__form__field__label__main">Schedule For Later?</span>
								<Tooltip title="Your workout will be activated for your members at the selected time">
									<Switch checked={scheduleRelease} onChange={(checked) => handleChangeScheduling(checked)} />
								</Tooltip>
							</div> */}
						</div>
					)}
					{scheduleRelease && (
						<div style={{ paddingTop: "8px" }}>
							<FormDateSelect
								name="workoutDate"
								rules={[{ required: true, message: "This field is required" }]}
								label="Workout Date"
							/>
							<div className="sb-release-form-input-group" style={{ display: "flex", gap: "16px" }}>
								<FormTimeSelect
									name="workoutTime"
									rules={[{ required: true, message: "This field is required" }]}
									label="Time"
								/>{" "}
								<FormSelect
									name="workoutTimezone"
									placeholder="PST"
									rules={[{ required: true, message: "This field is required" }]}
									label="Timezone"
									options={[
										{ value: "America/New_York", label: "EST (Eastern)" },
										{ value: "America/Los_Angeles", label: "PST (Pacific)" },
										{ value: "America/Chicago", label: "CST (Central)" },
									]}
								/>
							</div>
						</div>
					)}
					{live && (
						<div className="sb-workout-details-form__form__field">
							<Toggle name="free" text="Free" updateToggles={setIsFree} />
						</div>
					)}
					<div className="sb-workout-details-form__form__actions">
						<Button type="submit" className="primary-filled" saveIcon uppercase>
							<Paragraph2>Next</Paragraph2>
						</Button>
					</div>
				</Form>
			</div>
		</StyledWorkoutDetailsForm>
	);
};

export default WorkoutDetailsForm;
