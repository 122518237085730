import { DiamondIcon, FlashIcon, CrossfitIcon, HotIcon } from "components/Icons";

export const paymentLevels = {
	price_1K6nO1Axa8hukBopaG388C2D: {
		icon: <HotIcon />,
		title: "Starter",
		description:
			"A revenue sharing option for startups and creators. Keep sixty percent of all revenues and we will handle all of the music rights for your content.",
		priceDescription: "Free Up to 1,000 subscribers",
		price: "Free",
		share: 0.6,
		freeSubs: 1000,
	},
	price_1K6nPxAxa8hukBopg7ti9Avf: {
		icon: <CrossfitIcon />,
		title: "Pro",
		description: "Offer digital workouts for free or charge your members. Good for up to 100 subscribers per month.",
		priceDescription: "Up to 100 subscribers",
		price: "$99",
		share: 0.85,
		freeSubs: 100,
		priceFrequency: "month",
	},
	price_1K6nRLAxa8hukBopbmiT79Yb: {
		icon: <FlashIcon />,
		title: "Pro Studio",
		description: "Offer digital workouts for free or charge your members. Good for up to 300 subscribers per month.",
		priceDescription: "Up to 300 subscribers",
		price: "$299",
		share: 0.9,
		freeSubs: 300,
	},
};

export const paymentLevelsProd = {
	price_1LaqkiAxa8hukBoppOPWn410: {
		icon: <HotIcon />,
		title: "Starter",
		description:
			"A revenue sharing option for startups and creators. Keep sixty percent of all revenues and we will handle all of the music rights for your content.",
		priceDescription: "Free Up to 1,000 subscribers",
		price: "Free",
		share: 0.6,
		freeSubs: 1000,
	},
	price_1Laqj6Axa8hukBop6AqxYLq4: {
		icon: <CrossfitIcon />,
		title: "Pro",
		description: "Offer digital workouts for free or charge your members. Good for up to 100 subscribers per month.",
		priceDescription: "Up to 100 subscribers",
		price: "$99",
		share: 0.85,
		freeSubs: 100,
		priceFrequency: "month",
	},
	price_1LaqhjAxa8hukBopOu33yk97: {
		icon: <FlashIcon />,
		title: "Pro Studio",
		description: "Offer digital workouts for free or charge your members. Good for up to 300 subscribers per month.",
		priceDescription: "Up to 300 subscribers",
		price: "$299",
		share: 0.9,
		freeSubs: 300,
	},
};

export const paymentPlans = {
	starter: {
		icon: <HotIcon />,
		title: "Starter",
		description:
			"A revenue sharing option for startups and creators. Keep sixty percent of all revenues and we will handle all of the music rights for your content.",
		priceDescription: "Free Up to 1,000 subscribers",
		price: "Free",
		share: 0.6,
		freeSubs: 1000,
		featuresList: [
			"Min user price of $9.99/mo",
			"On Demand Workouts",
			"Live Workouts",
			"Cross Platform Support",
			"Vanity URL",
			"Keep 60% of Sales",
		],
		price_id: "price_1K6nO1Axa8hukBopaG388C2D",
	},
	pro: {
		icon: <CrossfitIcon />,
		title: "Pro",
		description: "Offer digital workouts for free or charge your members. Good for up to 100 subscribers per month.",
		priceDescription: "Up to 100 subscribers",
		price: "$99",
		share: 0.85,
		freeSubs: 100,
		priceFrequency: "month",
		featuresList: [
			"You determine the price",
			"On Demand + Live",
			"Subscriber Analytics",
			"Cross Platform Support",
			"Vanity URL",
			"3000 song plays included",
			"Additional plays at $0.03 per song play",
			"Keep 85% of Sales",
		],
		price_id: "price_1K6nPxAxa8hukBopg7ti9Avf",
	},
	prostudio: {
		icon: <FlashIcon />,
		title: "Studio Pro",
		description: "Offer digital workouts for free or charge your members. Good for up to 300 subscribers per month.",
		priceDescription: "Up to 300 subscribers",
		price: "$299",
		share: 0.9,
		freeSubs: 300,
		priceFrequency: "month",
		featuresList: [
			"You determine the price",
			"On Demand + Live",
			"Subscriber Analytics",
			"Cross Platform Support",
			"Vanity URL",
			"10,000 song plays included",
			"Additional plays at $0.03 per song play",
			"Access to PRO Playlists",
			"Keep 90% of Sales",
		],
		price_id: "price_1K6nRLAxa8hukBopbmiT79Yb",
	},
	elite: {
		icon: <DiamondIcon />,
		title: "Elite",
		description:
			"Our highest level of service for 300+ subscribers per month or brands who wish to integrate SWEATBASE APIs into their native application.",
		priceDescription: "For a customized plan",
		price: "Contact Us",
		featuresList: [
			"You determine the price",
			"On Demand + Live",
			"Subscriber Analytics",
			"Cross Platform Support",
			"Vanity URL",
			"Access to PRO Playlists",
			"Unlimited plays",
			"API Integration",
		],
	},
};

export const paymentPlansProd = {
	starter: {
		icon: <HotIcon />,
		title: "Starter",
		description:
			"A revenue sharing option for startups and creators. Keep sixty percent of all revenues and we will handle all of the music rights for your content.",
		priceDescription: "Free Up to 1,000 subscribers",
		price: "Free",
		share: 0.6,
		freeSubs: 1000,
		featuresList: [
			"Min user price of $9.99/mo",
			"On Demand Workouts",
			"Live Workouts",
			"Cross Platform Support",
			"Vanity URL",
			"Keep 60% of Sales",
		],
		price_id: "price_1LaqkiAxa8hukBoppOPWn410",
	},
	pro: {
		icon: <CrossfitIcon />,
		title: "Pro",
		description: "Offer digital workouts for free or charge your members. Good for up to 100 subscribers per month.",
		priceDescription: "Up to 100 subscribers",
		price: "$99",
		share: 0.85,
		freeSubs: 100,
		priceFrequency: "month",
		featuresList: [
			"You determine the price",
			"On Demand + Live",
			"Subscriber Analytics",
			"Cross Platform Support",
			"Vanity URL",
			"3000 song plays included",
			"Additional plays at $0.03 per song play",
			"Keep 85% of Sales",
		],
		price_id: "price_1Laqj6Axa8hukBop6AqxYLq4",
	},
	prostudio: {
		icon: <FlashIcon />,
		title: "Studio Pro",
		description: "Offer digital workouts for free or charge your members. Good for up to 300 subscribers per month.",
		priceDescription: "Up to 300 subscribers",
		price: "$299",
		share: 0.9,
		freeSubs: 300,
		priceFrequency: "month",
		featuresList: [
			"You determine the price",
			"On Demand + Live",
			"Subscriber Analytics",
			"Cross Platform Support",
			"Vanity URL",
			"10,000 song plays included",
			"Additional plays at $0.03 per song play",
			"Access to PRO Playlists",
			"Keep 90% of Sales",
		],
		price_id: "price_1LaqhjAxa8hukBopOu33yk97",
	},
	elite: {
		icon: <DiamondIcon />,
		title: "Elite",
		description:
			"Our highest level of service for 300+ subscribers per month or brands who wish to integrate SWEATBASE APIs into their native application.",
		priceDescription: "For a customized plan",
		price: "Contact Us",
		featuresList: [
			"You determine the price",
			"On Demand + Live",
			"Subscriber Analytics",
			"Cross Platform Support",
			"Vanity URL",
			"Access to PRO Playlists",
			"Unlimited plays",
			"API Integration",
		],
	},
};

export const freePaymentPlans = {
	free: {
		icon: <HotIcon />,
		title: "Free",
		description: "Free plan for a year",
		priceDescription: "Totally free",
		price: "Free",
		share: 1,
		freeSubs: "No Limits",
		featuresList: [],
		price_id: "price_1MPE1mAyyHgh37gnjfYxwhAh",
	},
};

export const freePaymentPlansProd = {
	free: {
		icon: <HotIcon />,
		title: "Free",
		description: "Free plan for a year",
		priceDescription: "Totally free",
		price: "Free",
		share: 1,
		freeSubs: "No Limits",
		featuresList: [],
		price_id: "price_1LcetOAxa8hukBopfkTlFtv2",
	},
};

export const getApiHost = (apiEnv) => {
	switch (apiEnv) {
		case "local":
			return "http://localhost:3001";
		case "development":
			return "https://api-dev.sweatbase.com";
		case "staging":
			return "https://api-staging.sweatbase.com";
		case "production":
			return "https://api.sweatbase.com";
		default:
			return "https://api-dev.sweatbase.com";
	}
};

export const getClientHost = (reactEnv) => {
	switch (reactEnv) {
		case "local":
			return "http://localhost:3000";
		case "development":
			return "https://dev.sweatbase.com";
		case "staging":
			return "https://staging.sweatbase.com";
		case "production":
			return "https://www.sweatbase.com";
		default:
			return "https://www.sweatbase.com";
	}
};
