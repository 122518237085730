import styled from "styled-components";

const StyledButton = styled.button`
	width: 100%;
	max-width: 335px;
	cursor: pointer;
	border: none;
	border-radius: 100px;
	height: 50px;
	position: relative;
	min-width: 220px;

	.ant-spin {
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		left: 16px;
	}

	.sb-button {
		&__inner {
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 12px;
			padding: 0 48px;

			svg,
			p {
				margin: 0;
			}

			&--uppercase {
				text-transform: uppercase;
			}

			&--disabled {
				opacity: 0.8;
				cursor: not-allowed;
			}

			&__chevron-right {
				width: 40px;
				height: 40px;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 50%;
				background-color: ${({ theme }) => theme.colors.shark};
				position: absolute;
				top: 5px;
				right: 5px;

				> * {
					color: ${({ theme }) => theme.colors.white};
				}
			}

			&__chevron-left {
				width: 40px;
				height: 40px;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 50%;
				background-color: ${({ theme }) => theme.colors.shark};
				position: absolute;
				top: 5px;
				left: 5px;
				> * {
					color: ${({ theme }) => theme.colors.white};
				}
			}
		}
	}

	&.primary-filled {
		background-color: ${({ theme }) => theme.colors.black};

		.sb-button__inner {
			color: ${({ theme }) => theme.colors.white};

			> * {
				color: ${({ theme }) => theme.colors.white};
			}
		}

		&--branding-primary-color {
			background-color: ${({ theme }) => theme.colors.coral};

			&--white {
				background-color: ${({ theme }) => theme.colors.coral};
				color: ${({ theme }) => theme.colors.white};
			}
		}
	}

	&.primary-outlined-color {
		background-color: ${({ theme }) => theme.colors.white};
		border: 2px solid ${({ theme }) => theme.colors.coral};
		color: ${({ theme }) => theme.colors.coral};

		.sb-button__inner {
			color: ${({ theme }) => theme.colors.coral};

			> * {
				color: ${({ theme }) => theme.colors.white};
			}
		}

		&--branding-primary-color {
			background-color: ${({ theme }) => theme.colors.coral};
		}
	}

	&.primary-outlined {
		background-color: ${({ theme }) => theme.colors.white};
		border: 2px solid ${({ theme }) => theme.colors.black};

		.sb-button__inner {
			color: ${({ theme }) => theme.colors.black};

			> * {
				color: ${({ theme }) => theme.colors.black};
			}

			&__chevron-right {
				top: 3px;
				right: 4px;
			}
		}

		&__chevron-left {
			top: 3px;
			left: 4px;
		}

		&--delete {
			border: 2px solid ${({ theme }) => theme.colors.burntSienna};

			.sb-button__inner {
				color: ${({ theme }) => theme.colors.burntSienna};

				> * {
					color: ${({ theme }) => theme.colors.burntSienna};
				}
			}
		}
	}
`;

export default StyledButton;
