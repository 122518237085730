// -------------------------------------------------------------------------------
// Libraries
// -------------------------------------------------------------------------------
import React from "react";
import { SaveOutlined } from "@ant-design/icons";
import { Spin } from "antd";

// -------------------------------------------------------------------------------
// Component and others
// -------------------------------------------------------------------------------
import { ArrowRightIcon, PlusIcon, ChevronRightIcon, DownloadIcon, ChevronLeftIcon } from "components/Icons";

// -------------------------------------------------------------------------------
// Utils, Styles and Assets
// -------------------------------------------------------------------------------
import { bem } from "helpers/Bem";
import StyledButton from "./styles";
import { AutoRenewIcon } from "components/Icons/AutoRenewIcon";

// -------------------------------------------------------------------------------
// Component
// -------------------------------------------------------------------------------
const Button = ({
	children,
	type,
	className,
	arrowRightIcon,
	plusIcon,
	chevronRight,
	chevronLeft,
	uppercase,
	saveIcon,
	downloadIcon,
	loading,
	autoRenewIcon,
	style,
	disabled,
	...other
}) => {
	return (
		<StyledButton type={type} className={`${className} sb-button`} {...other} style={style}>
			<div
				className={bem`sb-button__inner--${{
					"icon-right": chevronRight || saveIcon,
					uppercase,
					loading,
					disabled,
				}} `}
			>
				{loading && <Spin />}
				{plusIcon && <PlusIcon />}
				{downloadIcon && <DownloadIcon />}
				{chevronLeft && (
					<div className="sb-button__inner__chevron-left">
						<ChevronLeftIcon />
					</div>
				)}
				{children}
				{arrowRightIcon && <ArrowRightIcon />}
				{autoRenewIcon && <AutoRenewIcon />}
				{(chevronRight || saveIcon) && (
					<div className="sb-button__inner__chevron-right">{saveIcon ? <SaveOutlined /> : <ChevronRightIcon />}</div>
				)}
			</div>
		</StyledButton>
	);
};

export default Button;
