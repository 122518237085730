import styled from "styled-components";

const StyledCategoriesFilter = styled.div`
	.sb-categories-filter {
		display: flex;

		&__category {
			border-radius: 100px;
			background-color: ${({ theme }) => theme.colors.iron};
			margin-right: 8px;
			padding: 8px 16px;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			transition-duration: 500ms;
			transition-property: all;

			&--active {
				background-color: ${({ theme }) => theme.colors.coral};

				p {
					color: ${({ theme }) => theme.colors.white};
				}
			}

			&:hover {
				box-shadow: 0 1px 2px -2px rgb(0 0 0 / 16%), 0 3px 6px 0 rgb(0 0 0 / 12%), 0 5px 12px 4px rgb(0 0 0 / 9%);
			}
		}
	}
`;

export default StyledCategoriesFilter;
