import React, { useState, useEffect, useCallback } from "react";
import { useSetRecoilState } from "recoil";
import Modal from "antd/lib/modal";
import Input from "antd/lib/input";
import notification from "antd/lib/notification";
import { categoriesAtom } from "globalAtoms";
import ajaxRoutes from "utils/ajaxRoutes";
import Ajax from "utils/api";
import { Button } from "components/Common/Button";
import { Paragraph3 } from "themes/default/_typography";
import { CloseIcon } from "components/Icons";
import { useGetCategoriesQuery, useGetDomainGymQuery, useGetS3SignedUrlMutation } from "store/api/api";
import { useParams } from "react-router";
import ImageUpload from "components/ImageUpload";
import axios from "axios";

const CategoryFormModal = ({ open, gymId, setShowCreateCategoryModal, categoryToEdit }) => {
	const [getS3SignedUrlTrigger] = useGetS3SignedUrlMutation();
	const setCategories = useSetRecoilState(categoriesAtom);
	const [name, setName] = useState("");
	const [imageUrl, setImageUrl] = useState("");
	const [newImageUrl, setNewImageUrl] = useState("");
	const [categoryType, setCategoryType] = useState(false);
	const { gym } = useParams();

	const domainGymResponse = useGetDomainGymQuery(gym);
	const categoriesResponse = useGetCategoriesQuery(domainGymResponse.data?.id, { skip: !domainGymResponse.data });

	//TODO CREATE CATEGORY MUTATION & EDIT CATEGORY MUTATION

	useEffect(() => {
		if (categoryToEdit) {
			setImageUrl(categoryToEdit.image_url);
			// it's a boolean on this form, though it's an int for partners
			let catType = categoryToEdit.type == 1 ? true : false;
			setCategoryType(catType);
			setName(categoryToEdit.name);
		} else {
			// if you just edited a category, this will make sure that when
			// you click "new category" again, it will be empty
			setImageUrl("");
			setCategoryType(false);
			setName("");
		}
	}, [categoryToEdit]);

	const onSaveThumbnail = useCallback(
		async (file) => {
			if (file) {
				const mimeMap = {
					"image/jpeg": "jpg",
					"image/png": "png",
				};
				let fileType = mimeMap[file.type];
				const s3KeyThumbnail = `assets/${gymId}/category-${new Date().getTime()}.${fileType}`;
				const s3SignedResponse = await getS3SignedUrlTrigger({ key: s3KeyThumbnail, type: "image" });
				await uploadAsset(s3SignedResponse.data.response, file);
				const fullS3ThumbNail = `${s3SignedResponse.data.response.url}/${s3KeyThumbnail}`;
				// setting a different param because I don't want the form to re-render
				setNewImageUrl(fullS3ThumbNail);
			}
		},
		[gymId]
	);

	const onCancelThumbnail = () => {
		setImageUrl("");
	};

	const uploadAsset = async (response, thumbnailFile) => {
		const formData = new FormData();

		const headers = {
			"Content-Type": "multipart/form-data",
		};

		for (const field in response.fields) {
			formData.append(field, response.fields[field]);
		}

		formData.append("file", thumbnailFile);

		try {
			await axios.post(response.url, formData, { headers });
		} catch (error) {
			console.debug("error", error);
		}
	};

	const createCategoryHandler = async () => {
		if (gymId && name) {
			const response = await Ajax.call(ajaxRoutes.CREATE_CATEGORY, "post", {
				gymId,
				name,
				type: categoryType,
				image_url: newImageUrl || imageUrl,
			});

			if (response?.message === "Category Created") {
				notification.success({ message: response.message });

				const categoriesResp = await Ajax.call(ajaxRoutes.GET_CATEGORIES(gymId), "get");

				if (categoriesResp.categories) {
					setCategories(categoriesResp.categories);
				}
				categoriesResponse.refetch();
			} else {
				notification.error({
					message: response?.message || "There was a problem creating the category",
				});
			}

			setName("");
			setImageUrl("");
			setCategoryType(false);
			setShowCreateCategoryModal(false);
		}
	};

	const editCategoryHandler = async () => {
		if (gymId && name) {
			// ...categoryToEdit,
			const response = await Ajax.call(ajaxRoutes.EDIT_CATEGORY, "put", {
				id: categoryToEdit.id,
				gymId,
				name,
				type: categoryType,
				image_url: newImageUrl || imageUrl,
			});

			if (response.categories) {
				notification.success({ message: response.message });

				setCategories(response.categories);
				categoriesResponse.refetch();
			} else {
				notification.error({
					message: "There was a problem creating the category",
				});
			}

			setShowCreateCategoryModal(false);
		}
	};

	const createEditActions = () => {
		if (!categoryToEdit) {
			createCategoryHandler();
		} else {
			editCategoryHandler();
		}
	};

	const handleDeleteCategory = async () => {
		const { id, gym_id } = categoryToEdit;
		try {
			const response = await Ajax.call(ajaxRoutes.DELETE_CATEGORY, "put", {
				gymId: gym_id,
				categoryId: id,
			});

			if (response.categories) {
				notification.success({ message: response.message });
				setCategories(response.categories);
				categoriesResponse.refetch();
			} else {
				notification.error({
					message: "There was a problem creating the category",
				});
			}
			categoriesResponse.refetch();
			setShowCreateCategoryModal(false);
		} catch (error) {
			console.error(error);
		}

		setShowCreateCategoryModal(false);
	};

	const buttonLabel = categoryToEdit ? "Save Changes" : "Create Category";

	if (open) {
		return (
			<Modal
				width={500}
				className="sb-modal"
				title="New Category"
				open={open}
				closeIcon={<CloseIcon data-cy="close-category-modal" />}
				onCancel={() => {
					setShowCreateCategoryModal(false);
					setName("");
					setCategoryType(false);
				}}
				footer={[
					<div key="create" className="sb-create-category-modal__footer">
						{categoryToEdit && (
							<Button className="primary-outlined primary-outlined--delete" onClick={() => handleDeleteCategory()}>
								<Paragraph3>Delete</Paragraph3>
							</Button>
						)}
						<Button className="primary-filled" onClick={() => createEditActions()}>
							{buttonLabel}
						</Button>
					</div>,
				]}
			>
				<div className="sb-create-category-modal" data-cy="category-modal">
					<label className="sb-create-category-modal__label">Category Name</label>
					<Input
						className="sb-create-category-modal__input"
						placeholder="Name"
						value={name}
						onChange={(e) => setName(e.target.value)}
					/>
					<div className="sb-create-category-modal__check">
						<label className="sb-create-category-modal__label">High Order Category</label>
						<Input checked={categoryType} type="checkbox" onChange={(e) => setCategoryType(e.target.checked)} />
					</div>
					<ImageUpload
						className="sb-edit-category-form__upload__input"
						aspectRatio={300 / 180}
						dimensions={{ width: "300px", height: "180px" }}
						onSave={onSaveThumbnail}
						onCancel={onCancelThumbnail}
						type="image"
						preImage={imageUrl}
						title="Upload Thumbnail"
					/>
				</div>
			</Modal>
		);
	} else {
		return null;
	}
};

export default CategoryFormModal;

