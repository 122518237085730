import styled from "styled-components";

const StyledStripeAccountTable = styled.div`
	margin-top: 32px;

	.sb-stripe-account-settings {
		&__item {
			display: flex;
			gap: 16px;
			align-items: center;
			justify-content: center;
		}

		.ant-card-body {
			display: flex;
			gap: 16px;
			align-items: center;
			justify-content: center;
		}
	}
`;

export default StyledStripeAccountTable;
