// -------------------------------------------------------------------------------
// Libraries
// -------------------------------------------------------------------------------
import React from "react";

// -----------------------------------------------------------------------------
// Components and helpers
// -----------------------------------------------------------------------------
import { Paragraph13 } from "themes/default/_typography";
import StyledTag from "./styles";

// -------------------------------------------------------------------------------
// Component
// -------------------------------------------------------------------------------
const Tag = ({ text, className, icon }) => {
	return (
		<StyledTag className={`sb-tag ${className}`}>
			{icon}
			<Paragraph13>{text}</Paragraph13>
		</StyledTag>
	);
};

export default Tag;
