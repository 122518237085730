import React from "react";
import Logo from "components/UI/Logo";

function Header() {
	return (
		<div className="side-panel-header">
			<Logo />
		</div>
	);
}

export default Header;
