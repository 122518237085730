// -----------------------------------------------------------------------------
// Library
// -----------------------------------------------------------------------------
import React from "react";
import message from "antd/lib/message";
import { Form, Input, Button } from "antd";
import notification from "antd/lib/notification";

// -----------------------------------------------------------------------------
// Components and helpers
// -----------------------------------------------------------------------------
import Logo from "../../images/logo.svg";
import { useParams } from "react-router";
import { useConfirmResetPasswordMutation } from "store/api/api";

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
function ResetPassword({ match }) {
	const [password, updatePassword] = React.useState("");
	const [passConfirm, updatePassConfirm] = React.useState("");
	const { userId, token } = useParams();
	const [confirmResetPasswordTrigger, confirmResetPasswordResponse] = useConfirmResetPasswordMutation();

	const handleChange = (e) => {
		updatePassword(e.target.value);
	};

	const submit = async () => {
		if (password === passConfirm) {
			try {
				const resetResponse = await confirmResetPasswordTrigger({ password, token, userId }).unwrap();
				message.success("Password reset successfully! Please return to your login page");
			} catch (error) {
				notification.error({
					message: "An unknown error occurred",
				});
				console.error("An unknown error occurred", error);
			}
		} else {
			message.error("Password does not match");
		}
	};

	return (
		<div className="sb-login">
			<div className="sb-login__container">
				<div className="sb-login__container__form-container">
					<div className="sb-login__container__form-container__header">
						<img className="sb-login__container__form-container__header__image" src={Logo} alt="logo" />
						<h1 className="sb-login__container__form-container__header__title">Reset Password</h1>
					</div>
					<div className="sb-login__container__form-container__form">
						<div className="sb-signup-form">
							<Form name="basic" layout="vertical" autoComplete="off">
								<Form.Item
									label="Password"
									name="password"
									rules={[
										{
											required: true,
											message: "Please input your new password!",
										},
									]}
								>
									<Input.Password value={password} onChange={handleChange} placeholder="password" />
								</Form.Item>

								<Form.Item
									label="Password Confirmation"
									name="password-confirmation"
									rules={[
										{
											required: true,
											message: "Please confirm your new password!",
										},
									]}
								>
									<Input.Password onChange={(e) => updatePassConfirm(e.target.value)} placeholder="confirm password" />
								</Form.Item>

								<Form.Item wrapperCol={{ offset: 8, span: 16 }}>
									<Button type="primary" htmlType="button" onClick={submit}>
										Submit
									</Button>
								</Form.Item>
							</Form>
						</div>
					</div>
				</div>
				<div className="sb-login__container__image-container">
					<div className="sb-login__container__image-container__inner">
						<div className="sb-login__container__image-container__inner__image" />
					</div>
				</div>
			</div>
		</div>
	);
}

export default ResetPassword;
