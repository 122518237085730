// -----------------------------------------------------------------------------
// Library
// -----------------------------------------------------------------------------
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Layout } from "antd";

// -----------------------------------------------------------------------------
// Components and helpers
// -----------------------------------------------------------------------------
import SidePanel from "components/SidePanel";
import { sidePanelsOptionsDisabledMap, getCurrentFormID, paths } from "./helpers";
import NavigationButton from "./components/NavigationButton";
import SwitchForm from "./components/SwitchForm";
import { NAV_TYPE_NEXT, NAV_TYPE_PREV } from "./constants";
import { useRecoilValue } from "recoil";
import { userAtom } from "../../globalAtoms";
import "./components/index.scss";

const { Sider } = Layout;

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
const CreatorCreationFlow = (props) => {
	const location = useLocation();
	const [defaultSelectedKey, updateDefaultSelectedKey] = useState("");
	const user = useRecoilValue(userAtom);

	useEffect(() => {
		const form = paths.find((p) => p.path === location);
		if (form?.id) {
			updateDefaultSelectedKey(form.id);
		}
	}, [location]);

	const onMenuItemClick = (e) => {
		const form = paths.find((path) => path.id === e.key);
		window.scrollTo(0, 0);
		if (form?.path) {
			return window.scrollTo(0, 0);
		}
	};

	return (
		<Layout className="sb-creator-creation-container sb-base-layout">
			<Sider breakpoint="lg" collapsedWidth="0" width="220" className="sb-base-layout__sider">
				<SidePanel
					options={sidePanelsOptionsDisabledMap(paths, user)}
					onClick={onMenuItemClick}
					defaultSelectedKeys={[defaultSelectedKey]}
				/>
			</Sider>
			<Layout>
				<div className="sb-creator-creation-container__body">
					<SwitchForm user={user} match={props.match} />
				</div>
			</Layout>
		</Layout>
	);
};

export default CreatorCreationFlow;
