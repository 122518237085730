// -----------------------------------------------------------------------------
// Library
// -----------------------------------------------------------------------------
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useRecoilState } from "recoil";

// -----------------------------------------------------------------------------
// Components and helpers
// -----------------------------------------------------------------------------
import { ImageInputCard } from "components/Common/Cards/ImageInputCard";
import { ColorPickerCard } from "components/Common/Cards/ColorPickerCard";
import { userAtom } from "../../../globalAtoms";
import returnIfNotNull from "../../../utils/checkNull";
import { useCreateGymBrandingColorsMutation, useUpdateGymBrandingColorsMutation } from "store/api/api";
import axios from "axios";
import { getApiHost } from "helpers/constants";

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
function SetupBranding({ updateHexValue, hexValue }) {
	const dispatch = useDispatch();
	const [user] = useRecoilState(userAtom);
	const gymId = user?.userAssociations?.gym_id;
	const [selectedColor, setSelectedColor] = useState(null);
	const [updateGymBrandingColorsTrigger, updateGymBrandingColorsResponse] = useUpdateGymBrandingColorsMutation();
	const [createGymBrandingColorsTrigger, createGymBrandingColorsResponse] = useCreateGymBrandingColorsMutation();

	const updateGymBrandingColors = async (brandColor) => {
		// NOTE: USING GYMID & GYM_ID BECAUSE OF A BUG IN THE API
		// SEE GYMS_SERVICE EDIT BRANDING LINE 50 ("edit_gym_branding("${json.gymId}"")
		// VS GYMS_CONTROLLER EDIT BRANDING LINE 208 (return Promise.resolve({ response: { gym: result.gym_id }, status: 200 });)
		setSelectedColor(brandColor);
		const update = await updateGymBrandingColorsTrigger({
			gymId: gymId,
			gym_id: gymId,
			color1: brandColor,
		}).unwrap();
	};

	const createGymBranding = async () => {
		await createGymBrandingColorsTrigger({
			gymId: gymId,
			gym_id: gymId,
		});
	};

	useEffect(() => {
		if (createGymBrandingColorsResponse.status === "uninitialized" && gymId) {
			createGymBranding();
		}
	}, [createGymBrandingColorsResponse, gymId]);

	const handleLogoUpload = async (file, type) => {
		const apiHost = getApiHost(process.env.REACT_APP_API_ENV);

		const assetUrl = `${apiHost}/gyms/assets`;

		const jwt = localStorage.getItem("creator_jwt_token");
		const formData = new FormData();

		formData.append("image", file);
		formData.append("gym_id", gymId);
		formData.append("type", type);

		const headers = {
			Authorization: jwt,
			"Content-Type": "multipart/form-data",
		};

		axios
			.post(assetUrl, formData, { headers })
			.then((response) => {
				console.debug("response: ", response);
			})
			.catch((error) => {
				console.error("error: ", error);
			});
	};

	return (
		<div className="sb-setup-branding">
			<ImageInputCard
				title="Logo dark version"
				subtitle="Your full logo appears on your website, app sign up pages, and more."
				description="PNG or JPEG Recommended size of 250x250 px | Max 1 MB"
				imageUrl={returnIfNotNull(user?.gymBranding?.dark_logo)}
				onSaveImage={(file) => handleLogoUpload(file, "dark")}
			/>
			<ImageInputCard
				title="Logo light version"
				subtitle="Your full logo appears on your website, app sign up pages, and more."
				description="PNG or JPEG Recommended size of 250x250 px | Max 1 MB"
				imageUrl={returnIfNotNull(user?.gymBranding?.light_logo)}
				onSaveImage={(file) => handleLogoUpload(file, "light")}
			/>
			<ColorPickerCard
				title="Brand Color"
				subtitle="Your brand color will be the accent color on your app and website. Appears on buttons, callouts, etc."
				selectedColor={selectedColor}
				onChangeColor={(v) => updateGymBrandingColors(v)}
			/>
		</div>
	);
}

export default SetupBranding;
