import Col from "antd/lib/col";
import Divider from "antd/lib/divider";
import Row from "antd/lib/row";
import Slider from "antd/lib/slider";
import React from "react";
import ReactPlayer from "react-player/lazy";
import { animated, Spring } from "react-spring";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { userAtom } from "../../../globalAtoms";
import CoachSVG from "../../../images/coachIcon.svg";
import highVolumeSVG from "../../../images/highVolume.svg";
import homeIcon from "../../../images/homeIcon.svg";
import lowVolumeSVG from "../../../images/lowVolume.svg";
import MusicSVG from "../../../images/music.svg";
import PauseSVG from "../../../images/pause.svg";
import PlaySVG from "../../../images/play.svg";
import RightArrow from "../../../images/rightArrowNotFilled.svg";
import skipNextIcon from "../../../images/skip-next-white.svg";
import ajaxRoutes from "../../../utils/ajaxRoutes";
import Ajax from "../../../utils/api";
import StyledFullScreenPlayer from "./styles";
import MuxPlayer from "@mux/mux-player-react";
import { useLocation, useNavigate, useParams } from "react-router";
import axios from "axios";
import AddPaymentMethodModal from "components/Modals/AddPaymentMethodModal";
import { StripeCreateSetupIntent, StripeGetSubscriptionsForGym } from "constants/urls";
import { useCreateCustomerMutation, useCreateSubscriptionMutation, useGetDomainGymQuery } from "store/api/api";
import { LoadingMask } from "components/Common/LoadingMask";
import mux from "mux-embed";

function FullScreenWorkoutVideoMusicPlayer({ match }) {
	const navigate = useNavigate();
	const defaultState = {
		playing: true,
		musicVolume: 0.06,
		coachVolume: 1,
		played: 0,
		videoUrl: null,
		audioUrl: null,
		showing: true,
		mixTracksSeconds: 0,
		totalSeconds: [],
	};
	const [state, setState] = React.useState(defaultState);
	const [live, setLive] = React.useState(false);
	const [clientSecret, setClientSecret] = React.useState(null);
	const [setupIntentId, setSetupIntentId] = React.useState(null);
	const [showPaymentMethodModal, setShowPaymentMethodModal] = React.useState(false);
	const [priceId, setPriceId] = React.useState(null);
	const [unitAmount, setUnitAmount] = React.useState(null);
	const [gymAccountId, setGymAccountId] = React.useState(null);
	// tokens for livestream
	const [playbackToken, setPlaybackToken] = React.useState(null);
	const [thumbnailToken, setThumbnailToken] = React.useState(null);

	const [playingMusic, setPlayingMusic] = React.useState(false);
	const [paymentRequired, setPaymentRequired] = React.useState(false);
	const [currentTrack, setCurrentTrack] = React.useState("");
	const [currentArtist, setCurrentArtist] = React.useState("");
	const [streamPlaybackId, setStreamPlaybackId] = React.useState(null);
	const user = useRecoilValue(userAtom);
	//const playerRef = React.useRef(null);
	const musicRef = React.useRef(null);
	const { workoutID } = useParams();
	const { gym } = useParams();
	const location = useLocation();
	const { data: domainGymData } = useGetDomainGymQuery(gym);
	const [createCustomerTrigger] = useCreateCustomerMutation();
	const [createSubscriptionTrigger, createSubscriptionResp] = useCreateSubscriptionMutation();
	const setUser = useSetRecoilState(userAtom);
	const internalPlayerRef = React.useRef(null);

	React.useEffect(() => {
		async function startWorkout() {
			const body = {
				workout_id: workoutID,
				user_id: user?.id,
				gym_id: domainGymData?.id,
			};
			try {
				const response = await axios.post(ajaxRoutes.START_WORKOUT, body);
				if (response?.data?.workout) {
					setLive(location.pathname.includes("live"));
					const { workout } = response.data;
					let videoUrl = workout?.video_url;
					setStreamPlaybackId(workout?.playback_id);
					const lengthSplit = workout?.mix?.length?.split(":");
					const totalSeconds =
						lengthSplit && lengthSplit.length >= 3
							? +lengthSplit[0] * 60 * 60 + +lengthSplit[1] * 60 + +lengthSplit[2]
							: 0;
					const mixTracksSeconds = workout?.mix?.tracks?.map((track) => {
						const a = track.time.split(":");
						const currentValue = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
						return currentValue;
					});

					if (!videoUrl) {
						const { token, thumbnailToken, url } = await Ajax.call(ajaxRoutes.GET_MUX_VIDEO_URL(workout?.playback_id));
						videoUrl = url;
						setPlaybackToken(token);
						setThumbnailToken(thumbnailToken);
					}

					setState((prevState) => ({
						...prevState,
						videoUrl: videoUrl || null,
						workout: workout,
						audioUrl: workout?.mix?.url,
						mix: workout?.mix,
						mixTracksSeconds,
						totalSeconds,
					}));
				}
			} catch (e) {
				console.debug("ERROR: ", e);
				if (e?.response?.status === 402) {
					await getSubscriptionInfo();
				}
			}
		}

		startWorkout();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const videoRef = React.useRef(null);

	React.useEffect(() => {
		if (state.workout && state.mixTracksSeconds) {
			let currentIndex = 0;

			for (let i = 0; i < state.mixTracksSeconds.length; i++) {
				if (state.played >= state.mixTracksSeconds[state.mixTracksSeconds.length - 1]) {
					currentIndex = state.mixTracksSeconds.length - 1;
					break;
				}

				if (state.mixTracksSeconds[i] > state.played && i > 0) {
					currentIndex = i - 1;
					break;
				}
			}

			setCurrentTrack(state.workout.mix.tracks[currentIndex].title);
			setCurrentArtist(state.workout.mix.tracks[currentIndex].artist);
		}
	}, [state?.played, state?.mixTracksSeconds, state.workout, state.workout?.mix?.tracks]);

	React.useEffect(() => {
		if (createSubscriptionResp?.data?.id !== undefined) {
			window.location.reload();
		}
	}, [createSubscriptionResp]);

	const handlePausePlay = () => {
		setState((prevState) => ({
			...prevState,
			playing: !state.playing,
			showing: state.playing,
		}));
	};

	const handleToggleMusic = () => {
		setPlayingMusic(!playingMusic);
	};

	const handleNativePlay = () => {
		if (!state.playing) {
			setState((prevState) => ({
				...prevState,
				playing: !state.playing,
				showing: state.playing,
			}));
		}
	};

	const handleNativePause = () => {
		if (state.playing) {
			setState((prevState) => ({
				...prevState,
				playing: !state.playing,
				showing: state.playing,
			}));
		}
	};

	const handleVolumeChange = (value, type) => {
		setState((prevState) => ({
			...prevState,
			[type === "music" ? "musicVolume" : "coachVolume"]: value / 100,
		}));
	};

	const handleSeekChange = (value) => {
		const seconds = state.totalSeconds * (value / 100);
		setState((prevState) => ({ ...prevState, played: seconds }));
	};

	const handleSeekMouseUp = (value) => {
		musicRef.current.seekTo(value / 100);
	};

	const handleTrackSkip = () => {
		let nextValue = -1;

		for (let i = 0; i < state.mixTracksSeconds.length; i++) {
			if (state.played >= state.mixTracksSeconds[state.mixTracksSeconds.length - 1]) {
				nextValue = state.mixTracksSeconds[0];
				break;
			}

			if (state.played < state.mixTracksSeconds[i]) {
				nextValue = state.mixTracksSeconds[i];
				break;
			}
		}

		if (nextValue !== -1) {
			setState((prevState) => ({ ...prevState, played: nextValue }));
			musicRef.current.seekTo(nextValue);
		}
	};

	const handleProgress = ({ played }) => {
		const seconds = state.totalSeconds * played;
		setState((prevState) => ({ ...prevState, played: seconds }));
	};

	const handleEnded = () => {
		setState((prevState) => ({
			...prevState,
			playing: false,
			seeking: false,
		}));
	};

	const showHidePane = () => {
		setState((prevState) => ({ ...prevState, showing: !state.showing }));
	};

	const playedToPercent = () => {
		return (state.played / state.totalSeconds) * 100;
	};

	const renderTags = (tags) => {
		if (tags) {
			return tags.map((tag, index) => {
				const renderComma = index !== tags.length - 1;

				return (
					<span key={`tag-key-${index}`}>
						{tag}
						{renderComma ? "," : ""}
					</span>
				);
			});
		}
	};

	// PAYMENTS:
	const getSubscriptionInfo = async () => {
		const getSubscriptionsForGymUrl =
			process.env.REACT_APP_API_ENV === "production"
				? StripeGetSubscriptionsForGym.prod
				: StripeGetSubscriptionsForGym.dev;
		const gymProductResponse = await axios.post(getSubscriptionsForGymUrl, {
			gymName: domainGymData?.name,
		});

		setPriceId(gymProductResponse?.data?.default_price);
		setUnitAmount(gymProductResponse?.data?.unit_amount);
		setGymAccountId(gymProductResponse?.data?.metadata?.accountId);
		var customerId = user?.customer_id;
		if (!user?.customer_id) {
			const token = localStorage.getItem("creator_jwt_token");
			const custResp = await createCustomerTrigger({
				body: { email: user.email_address, userId: user.id, auth_token: token },
			});
			customerId = custResp?.data?.response?.customerId;
			setUser({ ...user, customer_id: customerId });
		}
		if (gymProductResponse?.data?.unit_amount === 0) {
			// User create subscription trigger here instead of direct lambda
			const body = {
				customerId,
				priceId: gymProductResponse?.data?.default_price,
				accountId: gymProductResponse?.data?.metadata?.accountId,
				gymId: domainGymData?.id,
			};

			await createSubscriptionTrigger(body);
		} else {
			const createSetupIntentUrl =
				process.env.REACT_APP_API_ENV === "production" ? StripeCreateSetupIntent.prod : StripeCreateSetupIntent.dev;
			const setupIntent = await axios.post(createSetupIntentUrl, {
				customerId,
			});
			setClientSecret(setupIntent.data.client_secret);
			setSetupIntentId(setupIntent.data.id);
			setShowPaymentMethodModal(true);
			setPaymentRequired(true);
		}
	};

	if (paymentRequired) {
		return (
			<div>
				<AddPaymentMethodModal
					subscribingToGym={{
						currentCustomerId: user.customer_id,
						priceId,
						accountId: gymAccountId,
						gymName: domainGymData?.name,
						price: unitAmount,
					}}
					setupIntentId={setupIntentId}
					visible={showPaymentMethodModal}
					setVisible={setPaymentRequired}
					clientSecret={clientSecret}
				/>
			</div>
		);
	}

	if (!state.videoUrl) {
		return <LoadingMask />;
	}

	const onStart = () => {
		setPlayingMusic(true);
		const internalPlayer = videoRef.current.getInternalPlayer();
		internalPlayerRef.current = internalPlayer;
		const initTime = mux.utils.now();
		mux.monitor(internalPlayerRef.current, {
			debug: false,
			data: {
				env_key: "2pebb6mgljmruiglmbnr53ot6", // required
				// Metadata fields
				player_name: "Web Player", // any arbitrary string you want to use to identify this player
				player_init_time: initTime,
				// ...
				viewer_user_id: `${state?.workout?.userId || "Not Found"}`, // ex: '12345'
				experiment_name: "Dev Env Player", // ex: 'player_test_A'
				gym_id: `${state?.workout?.gym_id || "Not Found"}`, // ex: 'gym-1'
				workout_id: `${state?.workout?.id || "Not Found"}`, // ex: 'workout-1'
				sub_property_id: `${state?.workout?.id || "Not Found"}`, // ex: 'cus-1'
				video_id: `${state?.workout?.id || "No ID Found"}`, // ex: 'abcd123'
				video_title: `${state?.workout?.name || "No Title Found"}`, // ex: 'My Great Video'
				video_series: "Series", // ex: 'Weekly Great Videos'
				video_duration: `${state?.workout?.duration || "No Duration Found"}`, // in milliseconds, ex: 120000
				video_stream_type: "On Demand", // 'live' or 'on-demand'
			},
		});
	};

	return (
		<StyledFullScreenPlayer>
			<div className="full-screen-music">
				<ReactPlayer
					style={{ display: "none" }}
					ref={musicRef}
					playing={playingMusic}
					volume={state.musicVolume}
					url={state.audioUrl}
					onProgress={handleProgress}
				/>

				{live && streamPlaybackId && playbackToken && thumbnailToken && (
					<MuxPlayer
						streamType="on-demand"
						playbackId={streamPlaybackId}
						metadata={{
							video_id: streamPlaybackId,
							video_title: state?.workout.name || "No Title Found",
							viewer_user_id: user?.id || "Unknown User",
						}}
						tokens={{
							playback: playbackToken,
							thumbnail: thumbnailToken,
						}}
					/>
				)}
				{!live && (
					<ReactPlayer
						playing={state.playing}
						volume={state.coachVolume}
						url={state.videoUrl}
						ref={videoRef}
						controls={true}
						onStart={onStart}
						onPlay={handleNativePlay}
						onPause={handleNativePause}
						onEnded={handleEnded}
						height={"100vh"}
						width={"100vw"}
					/>
				)}
				<div className="play-pause-controls" onClick={handlePausePlay}>
					<div className="play-pause-controls__inner">
						{state.playing ? (
							<img src={PauseSVG} className="play-pause-controls__inner__pause" alt="pause" />
						) : (
							<img src={PlaySVG} className="play-pause-controls__inner__play" alt="play" />
						)}

						<div className="do-fullscreen">Fullscreen</div>
					</div>
				</div>
				<div className="workout-name">{!state.playing ? state?.workout?.name : ""}</div>
				<Spring transform={state.showing ? "translateX(0px)" : "translateX(400px)"}>
					{(styles) => (
						<animated.div style={styles} className="music-right-pane-controls">
							<div onClick={showHidePane} className="hide-and-show-button">
								<img src={RightArrow} alt="arrow" />
							</div>
							<div className="music-right-pane-title">
								<div
									className="image-container"
									style={{
										backgroundImage: `url(${state?.workout?.mix?.image_large})`,
									}}
								>
									<div
										className="image-container__inner"
										style={{
											backgroundImage: `url(${state?.workout?.mix?.dj?.image_large})`,
										}}
									/>
									<div className="image-container__text" dangerouslySetInnerHTML={{ __html: state?.mix?.dj?.name }} />
								</div>
								<h1 dangerouslySetInnerHTML={{ __html: state?.mix?.title }} />
								<div className="tags">{renderTags(state?.mix?.tags)}</div>
							</div>
							<Divider />
							<div className="music-right-pane-title">
								<h1 dangerouslySetInnerHTML={{ __html: currentTrack }} />
								<p dangerouslySetInnerHTML={{ __html: currentArtist }} />
							</div>
							<div className="music-controls">
								<div className="home-button" onClick={() => navigate("/dashboard")}>
									<img src={homeIcon} alt="home" />
								</div>
								<div className="play-button">
									<div onClick={handleToggleMusic}>
										{playingMusic ? (
											<img src={PauseSVG} alt="pause" />
										) : (
											<img src={PlaySVG} style={{ filter: "invert(100%)" }} alt="play" />
										)}
									</div>
								</div>
								<div className="skip-button" onClick={() => handleTrackSkip()}>
									<img src={skipNextIcon} alt="skip" />
								</div>
							</div>
							<Slider
								className="music-seek"
								value={playedToPercent()}
								onChange={handleSeekChange}
								onAfterChange={handleSeekMouseUp}
							/>
							<Divider />
							<div>
								<Row>
									<Col span={8}>
										<h1 className="music-seek-title">
											<img src={CoachSVG} alt="coach" /> COACH
										</h1>
									</Col>
									<Col span={8} offset={8}>
										<h1 style={{ float: "right" }} className="music-seek-title">
											{Math.round(state.coachVolume * 100)}%
										</h1>
									</Col>
								</Row>
								<div className="music-seek-container">
									<img src={lowVolumeSVG} alt="low" />
									<Slider
										value={state.coachVolume * 100}
										className="music-seek volume-slider"
										onChange={(e) => handleVolumeChange(e, "coach")}
									/>
									<img src={highVolumeSVG} alt="high" />
								</div>
							</div>
							<Divider />
							<div>
								<Row>
									<Col span={8}>
										<h1 className="music-seek-title">
											<img src={MusicSVG} alt="music" /> MUSIC
										</h1>
									</Col>
									<Col span={8} offset={8}>
										<h1 style={{ float: "right" }} className="music-seek-title">
											{Math.round(state.musicVolume * 100)}%
										</h1>
									</Col>
								</Row>
								<div className="music-seek-container">
									<img src={lowVolumeSVG} alt="low" />
									<Slider
										value={state.musicVolume * 100}
										className="music-seek volume-slider"
										onChange={(e) => handleVolumeChange(e, "music")}
									/>
									<img src={highVolumeSVG} alt="high" />
								</div>
							</div>
						</animated.div>
					)}
				</Spring>
			</div>
		</StyledFullScreenPlayer>
	);
}

export default FullScreenWorkoutVideoMusicPlayer;
