// -----------------------------------------------------------------------------
// Library
// -----------------------------------------------------------------------------
import React, { useEffect } from "react";
import { Field, Form } from "react-final-form";
import { useRecoilValue } from "recoil";
import notification from "antd/lib/notification";

// -----------------------------------------------------------------------------
// Components and helpers
// -----------------------------------------------------------------------------
import InputField from "components/UI/Form/InputField";
import Label from "components/UI/Form/label";
import { required, validatePhoneNumber } from "helpers/Form/commonFormValidations";
import { userAtom } from "../../../globalAtoms";
import { Button } from "components/Common/Button";
import { useUpdateUserProfileMutation } from "store/api/api";

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
const AccountSettingsForm = () => {
	const user = useRecoilValue(userAtom);
	const [updateUserTrigger, updateUserResponse] = useUpdateUserProfileMutation({});

	const handleFormSubmit = async (formObj) => {
		const { firstName, lastName, phone } = formObj;

		await updateUserTrigger({
			userId: user.id,
			first_name: firstName,
			last_name: lastName,
			phone_number: phone,
		});
	};

	// Monitor for loading state
	useEffect(() => {
		if (updateUserResponse.isSuccess) {
			notification.success({
				message: "Updated user info!",
			});
		}
		if (updateUserResponse.isError) {
			notification.error({
				message: "There was an error updating user info",
			});
		}
	}, [updateUserResponse]);

	return (
		<Form
			initialValues={{
				firstName: user?.first_name,
				lastName: user?.last_name,
				email: user?.email_address,
				phone: user?.phone_number,
			}}
			onSubmit={handleFormSubmit}
		>
			{({ handleSubmit }) => {
				return (
					<form onSubmit={handleSubmit}>
						<Label>First Name</Label>
						<Field component="input" name="firstName" validate={required}>
							{({ input, meta }) => (
								<InputField
									data-cy="sb-client-portal-account-settings-first-name-field"
									type="text"
									input={input}
									meta={meta}
								/>
							)}
						</Field>
						<Label>Last Name</Label>
						<Field component="input" name="lastName" validate={required}>
							{({ input, meta }) => (
								<InputField
									data-cy="sb-client-portal-account-settings-last-name-field"
									type="text"
									input={input}
									meta={meta}
								/>
							)}
						</Field>
						<Label>Phone</Label>
						<Field component="input" name="phone" validate={validatePhoneNumber}>
							{({ input, meta }) => (
								<InputField
									data-cy="sb-client-portal-account-settings-phone-field"
									type="text"
									input={input}
									meta={meta}
								/>
							)}
						</Field>
						{/* <Label>Email</Label>
						<Field component="input" name="email" validate={required} disabled>
							{({ input, meta }) => (
								<InputField type="text" disabled input={input} meta={meta} style={{ color: "red" }} />
							)}
						</Field> */}
						<Button
							loading={updateUserResponse.isLoading}
							uppercase
							data-cy="sb-client-portal-account-settings-save-button"
							type="submit"
							style={{
								alignSelf: "flex-end",
								backgroundColor: "black",
								color: "white",
								fontSize: "14px",
								fontWeight: "700",
							}}
						>
							save
						</Button>
					</form>
				);
			}}
		</Form>
	);
};

export default AccountSettingsForm;
