// -----------------------------------------------------------------------------
// Library
// -----------------------------------------------------------------------------
import { PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";

// -----------------------------------------------------------------------------
// Components
// -----------------------------------------------------------------------------
import { CategoriesCard } from "components/Categories/CategoriesCard";
import { CreateWorkoutModal } from "components/Modals/CreateWorkoutModal";
import { EditWorkoutModal } from "components/Modals/EditWorkoutModal";
import WorkoutsTable from "components/Workouts/WorkoutsTable";
import { updateSelectedMuxVideoUrl, updateSelectedWorkout } from "store/slices";

// -----------------------------------------------------------------------------
// Styles and utils
// -----------------------------------------------------------------------------

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
const Workouts = () => {
	const dispatch = useDispatch();
	const [showCreateWorkoutModal, setShowCreateWorkoutModal] = useState(false);
	const [showEditWorkoutModal, setShowEditWorkoutModal] = useState(false);

	const openWorkoutCreationModal = useCallback(() => {
		dispatch(updateSelectedWorkout({ workout: null }));
		dispatch(updateSelectedMuxVideoUrl({ url: null }));
		setShowCreateWorkoutModal(true);
	}, [dispatch]);

	const onCreateSetVisible = useCallback(
		(status) => {
			setShowCreateWorkoutModal(status);

			if (!status) {
				dispatch(updateSelectedWorkout({ workout: null }));
				dispatch(updateSelectedMuxVideoUrl({ url: null }));
			}
		},
		[dispatch]
	);

	const onEditSetVisible = (bool) => {
		setShowEditWorkoutModal(bool);

		if (!bool) {
			dispatch(updateSelectedWorkout({ workout: null }));
			dispatch(updateSelectedMuxVideoUrl({ url: null }));
		}
	};

	return (
		<div className="sb-creator-workouts">
			<div className="sb-creator-workouts__categories">
				<CategoriesCard />
			</div>
			<div className="sb-creator-workouts__body">
				<Button
					data-cy="workout-library-new-workout-button"
					icon={<PlusOutlined />}
					style={{ float: "right", marginBottom: 20 }}
					type="primary"
					onClick={openWorkoutCreationModal}
				>
					New Workout
				</Button>
				<WorkoutsTable setShowEditWorkoutModal={setShowEditWorkoutModal} />
			</div>
			<CreateWorkoutModal visible={showCreateWorkoutModal} setVisible={onCreateSetVisible} />
			<EditWorkoutModal visible={showEditWorkoutModal} setVisible={onEditSetVisible} />
		</div>
	);
};

export default Workouts;
