// -----------------------------------------------------------------------------
// Library
// -----------------------------------------------------------------------------
import React, { useEffect } from "react";
import { notification } from "antd";
import { useDispatch } from "react-redux";

// -----------------------------------------------------------------------------
// Store
// -----------------------------------------------------------------------------
import { updateSelectedWorkout } from "store/slices";

// -----------------------------------------------------------------------------
// Components
// -----------------------------------------------------------------------------
import { Button } from "components/Common/Button";
import { Paragraph2 } from "themes/default/_typography";

// -----------------------------------------------------------------------------
// Utils and Styles
// -----------------------------------------------------------------------------
import StyledCreateWorkoutForm from "./styles";
import {
	useCreateWorkoutMutation,
	useGetCurrentUserQuery,
	useGetDomainGymQuery,
	useGetGymWorkoutsQuery,
} from "store/api/api";
import { useRecoilValue } from "recoil";
import { userAtom } from "globalAtoms";
import { useParams } from "react-router";
import { required } from "helpers/Form/commonFormValidations";
import InputField from "components/UI/Form/InputField";
import Label from "components/UI/Form/label";
import { Field, Form } from "react-final-form";

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
const CreateWorkoutForm = ({ nextStep }) => {
	const dispatch = useDispatch();
	const user = useRecoilValue(userAtom);
	// const token = localStorage.getItem("creator_jwt_token");
	// const split = token.split(".")[1];
	// const formatted = JSON.parse(atob(split));
	// const JWTID = formatted.id;
	// const { data: currentUserData, isError } = useGetCurrentUserQuery({ userId: JWTID });
	const { gym } = useParams();
	const domainGymResponse = useGetDomainGymQuery(gym);
	const domainGymId = domainGymResponse.data?.id;
	const getWorkoutsResponse = useGetGymWorkoutsQuery(domainGymId, { skip: !domainGymResponse.data });
	const [createWorkoutTrigger, createWorkoutResponse] = useCreateWorkoutMutation();

	useEffect(() => {
		if (createWorkoutResponse.isSuccess) {
			dispatch(updateSelectedWorkout({ ...createWorkoutResponse.data }));
			getWorkoutsResponse.refetch();
			notification.success({
				message: "Workout draft created",
			});
			nextStep();
		} else if (createWorkoutResponse.isError) {
			notification.error({
				message: "Error creating workout",
			});
		}
	}, [createWorkoutResponse]);

	const handleFormSubmit = async (formObj) => {
		const { workoutTitle, workoutDescription, workoutCoach } = formObj;

		await createWorkoutTrigger({
			gym_id: domainGymId,
			user_id: user?.id,
			name: workoutTitle,
			coach: workoutCoach,
			description: workoutDescription,
			is_free: 0,
		});
	};

	return (
		<StyledCreateWorkoutForm>
			<div className="sb-create-workout-form">
				<Form onSubmit={handleFormSubmit} layout="vertical" className="sb-create-workout-form__form">
					{({ handleSubmit }) => {
						return (
							<form id="create-workout" onSubmit={handleSubmit}>
								<Label>Workout Title</Label>
								<Field component="input" name="workoutTitle" validate={required}>
									{({ input, meta }) => (
										<InputField data-cy="sb-create-workout-title-field" type="text" input={input} meta={meta} />
									)}
								</Field>
								<Label>Workout Description</Label>
								<Field component="input" name="workoutDescription" validate={required}>
									{({ input, meta }) => (
										<InputField
											textarea={true}
											data-cy="sb-create-workout-description-field"
											type="text"
											input={input}
											meta={meta}
										/>
									)}
								</Field>
								<Label>Workout Coach</Label>
								<Field component="input" name="workoutCoach" validate={required}>
									{({ input, meta }) => (
										<InputField data-cy="sb-create-workout-coach-field" type="text" input={input} meta={meta} />
									)}
								</Field>

								<Button
									type="submit"
									className="sb-create-workout-form__form__button primary-filled"
									saveIcon
									uppercase
									data-cy="sb-create-workout-submit-workout-info"
								>
									<Paragraph2>Next</Paragraph2>
								</Button>
							</form>
						);
					}}
				</Form>
			</div>
		</StyledCreateWorkoutForm>
	);
};

export default CreateWorkoutForm;

