// -----------------------------------------------------------------------------
// Library
// -----------------------------------------------------------------------------
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Dropdown from "rc-dropdown";
import Menu, { Item as MenuItem } from "rc-menu";
import { SearchOutlined } from "@ant-design/icons";
import Input from "antd/lib/input";

// -----------------------------------------------------------------------------
// Components
// -----------------------------------------------------------------------------
import GymBanner from "../../../components/UI/GymBanner";
import WorkoutsList from "../../../components/Workouts/WorkoutsList";

// -----------------------------------------------------------------------------
// Actions and helpers
// -----------------------------------------------------------------------------
import { CategoriesFilter } from "components/Categories/CategoriesFilter";
import { updateMembersWorkoutsSearchTerm } from "store/slices";
import chevronDown from "../../../images/chevron-down.svg";

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
const OnDemand = () => {
	const dispatch = useDispatch();
	const [selectedOption, setSelectedOption] = useState("Year");
	const [optionsVisibility, setOptionsVisibility] = useState(false);

	const handleSearch = (e) => {
		const { value } = e.target;

		dispatch(updateMembersWorkoutsSearchTerm(!value || value.trim() === "" ? "" : value.toLowerCase()));
	};

	const handleOptionsStateChange = (visible) => {
		setOptionsVisibility(visible);
	};

	const handleOptionChange = ({ key }) => {
		setSelectedOption(key);
	};

	const renderOptions = () => {
		return (
			<Menu onSelect={handleOptionChange} className="sb-on-demand-container__body__head__options__menu">
				<MenuItem key="Year">This Year</MenuItem>
				<MenuItem key="Month">This Month</MenuItem>
				<MenuItem key="Day">This Day</MenuItem>
			</Menu>
		);
	};

	return (
		<div className="sb-on-demand-container">
			<GymBanner />
			<div className="sb-on-demand-container__body">
				<div className="sb-on-demand-container__body__head">
					<h1 className="sb-on-demand-container__body__head__title">Explore Videos</h1>
					<Dropdown
						trigger={["click"]}
						overlay={renderOptions}
						onVisibleChange={handleOptionsStateChange}
						animation="slide-up"
						className="sb-on-demand-container__body__head__options"
					>
						<button className="sb-on-demand-container__body__head__options__button">
							<span className="sb-on-demand-container__body__head__options__button__first-text">Show:</span>
							<span className="sb-on-demand-container__body__head__options__button__second-text">{`This ${selectedOption}`}</span>
							<img
								className={`sb-on-demand-container__body__head__options__button__arrow ${
									optionsVisibility ? "open" : "close"
								}`}
								src={chevronDown}
								alt="chevron"
							/>
						</button>
					</Dropdown>
				</div>
				<div className="sb-on-demand-container__body__filters">
					<CategoriesFilter />
					<Input
						className="sb-on-demand-container__body__filters__search-input"
						prefix={<SearchOutlined />}
						onChange={handleSearch}
						placeholder="Search Workouts..."
					/>
				</div>
				<WorkoutsList />
			</div>
		</div>
	);
};

export default OnDemand;
