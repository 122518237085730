// -----------------------------------------------------------------------------
// Library
// -----------------------------------------------------------------------------
import { Image } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// -----------------------------------------------------------------------------
// Store
// -----------------------------------------------------------------------------
import { selectGenres, selectGenresLoading } from "store/selectors";
import { getGenres } from "store/slices";

// -----------------------------------------------------------------------------
// Components
// -----------------------------------------------------------------------------
import { LoadingMask } from "components/Common/LoadingMask";
import { CheckIcon } from "components/Icons";
import { Heading14 } from "themes/default/_typography";

// -----------------------------------------------------------------------------
// Styles and Utils
// -----------------------------------------------------------------------------
import { bem } from "helpers/Bem";
import StyledGenreSelector from "./styles";
import { useGetGenresQuery } from "store/api/api";

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
const GenreSelector = ({ setSelectedGenre, initialSelectedGenreId }) => {
	const [localSelectedGenre, setLocalSelectedGenre] = useState({});
	const dispatch = useDispatch();
	const { data, isLoading: genresLoading, error } = useGetGenresQuery();

	const onGenreClick = useCallback(
		(genre) => {
			setLocalSelectedGenre(genre);
			setSelectedGenre(genre);
		},
		[setSelectedGenre]
	);

	return (
		<StyledGenreSelector>
			{genresLoading && <LoadingMask />}
			{!genresLoading && (
				<div className="sb-genre-selector__genres">
					{data?.genres?.length > 0 &&
						data?.genres.map(({ imageUrl, title, id }) => (
							<div
								className={bem`sb-genre-selector__genres__genre--${{
									selected: localSelectedGenre.id ? localSelectedGenre.id === id : initialSelectedGenreId === id,
								}}`}
								key={`genre-${id}`}
								onClick={() => onGenreClick({ title, id })}
							>
								<div className="sb-genre-selector__genres__genre__inner">
									<div className="sb-genre-selector__genres__genre__inner__front">
										<Image preview={false} src={imageUrl} />
									</div>
									<div className="sb-genre-selector__genres__genre__inner__back">
										<Heading14>{title}</Heading14>
									</div>
									<div className="sb-genre-selector__genres__genre__inner__check">
										<CheckIcon />
									</div>
								</div>
							</div>
						))}
				</div>
			)}
		</StyledGenreSelector>
	);
};

export default GenreSelector;

