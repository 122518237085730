import styled from "styled-components";

const StyledMembersSubscribersDonutChart = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	background: ${({ theme }) => theme.colors.white};
	border: 1px solid ${({ theme }) => theme.colors.iron};
	border-radius: 6px;
	padding: 16px;
	gap: 60px;
	width: 100%;
	height: 274px;
	position: relative;

	.sb-members-subscribers-donut-chart {
		&__chart {
		}

		&__cards {
		}
	}
`;

export default StyledMembersSubscribersDonutChart;
