// -----------------------------------------------------------------------------
// Library
// -----------------------------------------------------------------------------
import Table from "antd/lib/table";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// -----------------------------------------------------------------------------
// Components
// -----------------------------------------------------------------------------

// -----------------------------------------------------------------------------
// Store
// -----------------------------------------------------------------------------
import { selectMembersWorkoutsSelectedCategories } from "store/selectors";

// -----------------------------------------------------------------------------
// Style, utils and assets
// -----------------------------------------------------------------------------
import { useGetDomainGymQuery, useGetGymWorkoutsQuery, useGetStatsForGymQuery } from "store/api/api";
import { useParams } from "react-router";
import VideoPreview from "components/UI/Video/VideoPreview";
import VideoPlayerModal from "components/UI/VideoPlayer/VideoPlayerModal";

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
const AnalyticsTable = ({ timeFrame }) => {
	let { gym } = useParams();
	const domainGymResponse = useGetDomainGymQuery(gym);
	const domainGymId = domainGymResponse?.data?.id;

	const workoutsResponse = useGetGymWorkoutsQuery(domainGymId, { skip: !domainGymResponse.data });
	const [workouts, setWorkouts] = useState([]);
	const { data: statsData } = useGetStatsForGymQuery(domainGymId, {
		skip: !domainGymResponse.data,
	});

	const [isShowingVideoPlayer, updateIsShowingVideoPlayer] = useState(false);
	const [fileSelected, updateFileSelected] = useState(null);
	const categoriesFilters = useSelector(selectMembersWorkoutsSelectedCategories);

	useEffect(() => {
		if (workoutsResponse.data) {
			setWorkouts(workoutsResponse.data.workouts);
		}
	}, [workoutsResponse]);

	useEffect(() => {
		if (statsData && workoutsResponse.data) {
			const updatedWorkouts = workoutsResponse.data.workouts.map((workout) => {
				//Filter Stats by Time Frame:
				// Filter Stats Data By Time Frame
				const statsInTimeFrame = statsData.filter((stat) => {
					if (timeFrame === "Last 30 Days") {
						return new Date(stat.createdDate) > new Date(new Date().setDate(new Date().getDate() - 30));
					} else if (timeFrame === "Last Week") {
						return new Date(stat.createdDate) > new Date(new Date().setDate(new Date().getDate() - 7));
					} else {
						return true;
					}
				});
				// Filter the stats to find matching 'target' and 'type' == 'workout_start'/'type' == 'workout_user_start'
				const matchingStats = statsInTimeFrame.filter(
					(stat) => stat.target === workout.key && (stat.type === "workout_start" || stat.type === "workout_user_start")
				);

				// Count the number of matching stats
				const workoutStartCount = matchingStats.length;
				// Use a Set to store unique user IDs from matching stats
				const uniqueUserIds = new Set(matchingStats.map((stat) => stat.userId));

				// Count the number of unique user IDs
				const uniqueUserCount = uniqueUserIds.size;

				// Return a new object based on the workout, including the new property
				return {
					...workout,
					total_workout_start_stats: workoutStartCount, // Count of workout_start events
					total_unique_users: uniqueUserCount, // Count of unique users who started the workout
				};
			});
			setWorkouts(updatedWorkouts);
		}
	}, [statsData, workoutsResponse, timeFrame]);

	const columns = [
		{
			title: "Rank",
			dataIndex: "rank",
			key: "rank",
			render: (_, record, index) => {
				return (
					<div>
						<span>{index + 1}</span>
					</div>
				);
			},
		},
		{
			title: "Workout",
			dataIndex: "workout",
			key: "workout",
			render: (_, record) => {
				return (
					<VideoPreview
						updateIsShowingVideoPlayer={updateIsShowingVideoPlayer}
						updateFileSelected={updateFileSelected}
						record={record}
					/>
				);
			},
		},
		{
			title: "Total Views",
			dataIndex: "views",
			key: "views",
			responsive: ["lg"],
			render: (_, record) => {
				return <p>{record.total_workout_start_stats}</p>;
			},
		},
		{
			title: "Total Users",
			dataIndex: "users",
			key: "users",
			responsive: ["lg"],
			render: (_, record) => {
				return <span>{record.total_unique_users}</span>;
			},
		},
		{
			title: "Type",
			dataIndex: "type",
			key: "type",
			responsive: ["lg"],
			render: (_, record) => {
				return record.start_time === null ? <span>Workout</span> : <span>Live Workout</span>;
			},
		},
	];

	return (
		<div className="sb-workouts-table">
			{workouts && (
				<Table
					columns={columns}
					dataSource={workouts
						.filter(
							(workout) =>
								categoriesFilters.includes(0) ||
								workout.categories.some((c) => categoriesFilters.includes(c.category_id))
						)
						.sort((a, b) => b.total_workout_start_stats - a.total_workout_start_stats)}
					rowKey="id"
				/>
			)}
			<VideoPlayerModal
				file={fileSelected}
				isShowing={isShowingVideoPlayer}
				updateIsShowingVideoPlayer={updateIsShowingVideoPlayer}
			/>
		</div>
	);
};

export default AnalyticsTable;

