// -----------------------------------------------------------------------------
// Library
// -----------------------------------------------------------------------------
import React from "react";
import { useSelector } from "react-redux";

// -----------------------------------------------------------------------------
// Store
// -----------------------------------------------------------------------------
import { selectDomainGym } from "store/selectors";

// -----------------------------------------------------------------------------
// Components
// -----------------------------------------------------------------------------
import { Heading3 } from "themes/default/_typography";

// -----------------------------------------------------------------------------
// Utils and Styles
// -----------------------------------------------------------------------------
import StyledEarningsTable from "./styles";
import { variables } from "themes/default/_variables";
import moment from "moment";

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
const EarningsTable = (props) => {
	return (
		<StyledEarningsTable className="sb-earnings-table">
			<Heading3>Payouts</Heading3>
			<table className="sb-earnings-table__card">
				<thead>
					<tr>
						<th>Date</th>
						<th>Amount</th>
						<th>Status</th>
					</tr>
				</thead>
				<tbody>
					{props.transfers.map((transfer, index) => {
						return (
							<tr key={transfer.id}>
								<td>{moment.unix(transfer.arrival_date).format("MMM Do YYYY")}</td>
								<td>${(transfer.amount / 100).toFixed(2)}</td>
								<td>{transfer.status}</td>
							</tr>
						);
					})}
				</tbody>
			</table>
		</StyledEarningsTable>
	);
};

export default EarningsTable;

