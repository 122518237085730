// -------------------------------------------------------------------------------
// Libraries
// -------------------------------------------------------------------------------
import React, { useState, useEffect } from "react";

// -------------------------------------------------------------------------------
// Store
// -------------------------------------------------------------------------------
import { useGetGymMembersByDateQuery } from "store/api/api";

// -------------------------------------------------------------------------------
// Components
// -------------------------------------------------------------------------------
import { DonutChart } from "components/Common/Charts/DonutChart";
import { LegendCard } from "components/Common/Cards/LegendCard";
import { LoadingMask } from "components/Common/LoadingMask";

// -------------------------------------------------------------------------------
// Utils, Styles and Assets
// -------------------------------------------------------------------------------
import StyledMembersSubscribersDonutChart from "./styles";

// -------------------------------------------------------------------------------
// Component
// -------------------------------------------------------------------------------
const MembersSubscribersDonutChart = (props) => {
	const { dateRange } = props;
	const [donutChartData, setDonutChartData] = useState(null);

	const gymMembersResponse = useGetGymMembersByDateQuery({
		startDate: dateRange.startDate.format("YYYY-MM-DD"),
		endDate: dateRange.endDate.format("YYYY-MM-DD"),
	});
	const activeMembers = gymMembersResponse?.data?.subscribers?.filter(
		(member) => member.subscription_status === "ACTIVE"
	);
	const trialMembers = gymMembersResponse?.data?.subscribers?.filter((member) => member.subscription_status === "TRIAL");
	const cancelledMembers = gymMembersResponse?.data?.subscribers?.filter(
		(member) => member.subscription_status === "CANCELLED"
	);

	useEffect(() => {
		if (gymMembersResponse.data) {
			setDonutChartData([
				{
					name: "Active Subscribers",
					value: activeMembers.length || 0,
					fill: "#00008b",
				},
				{
					name: "Free Members",
					value: trialMembers.length || 0,
					fill: "#6fa2f2",
				},
				{
					name: "Cancelled Members",
					value: cancelledMembers.length || 0,
					fill: "#d3d3d3",
				},
			]);
		}
	}, [gymMembersResponse]);

	return (
		<StyledMembersSubscribersDonutChart className="sb-members-subscribers-donut-chart">
			{gymMembersResponse.isLoading || !donutChartData ? (
				<LoadingMask />
			) : (
				<>
					<div className="sb-members-page__subscribers__charts__donut__chart__chart">
						<DonutChart size={200} labelSize={20} data={donutChartData} />
					</div>
					<div className="sb-members-page__subscribers__charts__donut__chart__cards">
						<LegendCard amount={activeMembers.length || 0} label="Total Subscribed Members" color={"#00008b"} />
						<LegendCard amount={trialMembers.length || 0} label="Total Trial Members" color="#6fa2f2" />
						<LegendCard amount={cancelledMembers.length || 0} label="Cancelled Members" color="#d3d3d3" />
					</div>
				</>
			)}
		</StyledMembersSubscribersDonutChart>
	);
};

export default MembersSubscribersDonutChart;

