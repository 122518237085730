// -----------------------------------------------------------------------------
// Library
// -----------------------------------------------------------------------------
import React from "react";
import { useRecoilValue } from "recoil";

// -----------------------------------------------------------------------------
// Components and helpers
// -----------------------------------------------------------------------------
import { userAtom } from "../../../globalAtoms";
import ProfileBanner from "../../../components/UI/ProfileBanner";
import defaultHeader from "../../../images/default-header.jpeg";
import { useParams } from "react-router";
import { useGetDomainGymQuery } from "store/api/api";

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
const MyGym = () => {
	let { gym } = useParams();
	const { data: domainGym } = useGetDomainGymQuery(gym);
	const domainGymId = domainGym?.data?.id;
	const user = useRecoilValue(userAtom);
	const headerImage = user?.pages?.[0]?.header_image;
	//const gym = user?.gym;
	const replacementString =
		process.env.REACT_APP_API_ENV === "production" ? "cdn.sweatbase.com" : "sweatbase-staging.s3.amazonaws.com";

	return (
		<div className="sb-my-gym">
			<ProfileBanner title="My Gym" picture={false} />
			<div className="sb-my-gym__body">
				<div className="sb-my-gym__body__inner">
					<div className="sb-my-gym__body__inner__picture">
						<img
							src={
								domainGym?.pages[0]?.header_image
									? domainGym?.pages[0]?.header_image.replace("cdn.sweatbase.com", replacementString)
									: defaultHeader
							}
							alt="header"
						/>
					</div>
					<div className="sb-my-gym__body__inner__first-column">
						<div className="sb-my-gym__body__inner__first-column__about">
							<h1 className="sb-my-gym__body__inner__first-column__about__title">About {domainGym?.name}</h1>
							<p className="sb-my-gym__body__inner__first-column__about__description">{domainGym?.description}</p>
						</div>
						{/* <div className="sb-my-gym__body__inner__first-column__philosophy">
							<h1 className="sb-my-gym__body__inner__first-column__philosophy__title">Our Philosophy</h1>
							<p className="sb-my-gym__body__inner__first-column__philosophy__description">{`“${domainGym?.philosophy}”`}</p>
						</div> */}
					</div>
					<div className="sb-my-gym__body__inner__second-column">
						<div className="sb-my-gym__body__inner__second-column__location">
							<h1 className="sb-my-gym__body__inner__second-column__location__title">PRIMARY LOCATION</h1>
							<p className="sb-my-gym__body__inner__second-column__location__address">{`${domainGym?.address1}, ${domainGym?.city}, ${domainGym?.state}`}</p>
							<p className="sb-my-gym__body__inner__second-column__location__phone">{`${domainGym?.phone_number}`}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default MyGym;
