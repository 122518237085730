import styled from "styled-components";

const StyledScheduleLiveForm = styled.div`
	padding: 0 32px;
	.sb-schedule-live-form {
		&__upload {
			margin: 32px 0;
			&__input {
				margin-bottom: 8px;
			}
		}
		&__form {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			gap: 8px;
			&__button {
				margin: 8px 0;
			}
		}
		&__actions {
			width: 100%;
			display: flex;
			flex-grow: 1;
			align-items: start;
			justify-content: space-between;
			flex-direction: row;
			gap: 18px;
		}
	}
`;

export default StyledScheduleLiveForm;

