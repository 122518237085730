// -------------------------------------------------------------------------------
// Libraries
// -------------------------------------------------------------------------------
import React, { useState } from "react";
import { Select, Form, Tooltip } from "antd";
import { TimePicker } from "antd";
import dayjs from "dayjs";

// -------------------------------------------------------------------------------
// Utils, Styles and Assets
// -------------------------------------------------------------------------------
import StyledFormTimeSelect from "./styles";
import { ClockIcon } from "components/Icons/ClockIcon";
import { InfoCircleOutlined } from "@ant-design/icons";

// -------------------------------------------------------------------------------
// Component
// -------------------------------------------------------------------------------

const FormTimeSelect = ({ name, label, placeholder, rules, options, className, disabled }) => {
	const { Option } = Select;
	const format = "h:mm A";
	const [value, setValue] = useState(dayjs("00:00:00", "H:mm A"));
	const onChange = (time) => {
		console.log("onChange", dayjs(time, "H:mm A"));
		setValue(dayjs(time, "H:mm A"));
	};

	return (
		<StyledFormTimeSelect className={`${className} sb-form-select`}>
			<Form.Item name={name} label={label} rules={rules}>
				<TimePicker
					disabled={disabled}
					use12Hours
					showNow={false}
					changeOnBlur
					style={{ width: "100%", height: "46px", borderRadius: "100px" }}
					minuteStep={15}
					format={format}
					value={value}
					onChange={onChange}
					onSelect={onChange}
					suffixIcon={<ClockIcon />}
					prefix={<ClockIcon />}
				/>
			</Form.Item>
		</StyledFormTimeSelect>
	);
};

export default FormTimeSelect;

