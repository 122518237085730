import styled from "styled-components";

const StyledMembershipSettingsPage = styled.div`
	.sb-membership-settings-page {
		&__inner {
			&__heading {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				justify-content: center;
				gap: 16px;
				margin-bottom: 16px;
			}
		}
	}
`;

export default StyledMembershipSettingsPage;

