import styled from "styled-components";

const StyledEarningsStackedBarChart = styled.div`
	.sb-earnings-stacked-bar-chart {
		&__card {
			width: 100%;
			height: 455px;
			border: 1px solid ${({ theme }) => theme.colors.iron};
			border-radius: 6px;
			padding: 40px 36px 24px 36px;
			background-color: ${({ theme }) => theme.colors.white};
		}
	}
`;

export default StyledEarningsStackedBarChart;
