// -------------------------------------------------------------------------------
// Libraries
// -------------------------------------------------------------------------------
import React from "react";
import { InputNumber, Form } from "antd";

// -------------------------------------------------------------------------------
// Utils, Styles and Assets
// -------------------------------------------------------------------------------
import { Paragraph10 } from "themes/default/_typography";
import { FormErrorTag } from "components/Common/Form/FormErrorTag";
import { bem } from "helpers/Bem";
import StyledFormInputNumber from "./styles";

// -------------------------------------------------------------------------------
// Component
// -------------------------------------------------------------------------------
const FormInputNumber = ({ name, label, rules, addonRight, type, errors, defaultValue }) => {
	return (
		<StyledFormInputNumber
			className={bem`sb-form-input-number--${{
				addonRight: !!addonRight,
			}}`}
		>
			<Form.Item name={name} label={label} rules={rules}>
				{type === "money" ? (
					<InputNumber
						onKeyPress={(event) => {
							if (!/[0-9\.]/.test(event.key)) {
								event.preventDefault();
							}
						}}
						defaultValue={defaultValue}
						formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
						parser={(value) => value.replace(/[^\d.]|\.(?=.*\.)/g, "")}
					/>
				) : (
					<InputNumber />
				)}
			</Form.Item>
			{addonRight && <Paragraph10 className="sb-form-input-number__addon-right">{addonRight}</Paragraph10>}
			{errors && errors[name] && <FormErrorTag text={errors[name].message} />}
		</StyledFormInputNumber>
	);
};

export default FormInputNumber;

