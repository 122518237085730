// -----------------------------------------------------------------------------
// Libraries
// -----------------------------------------------------------------------------
import React from "react";
import cx from "classnames";
import { Modal } from "antd";
import { loadStripe } from "@stripe/stripe-js/pure";
import { Elements } from "@stripe/react-stripe-js";

// -----------------------------------------------------------------------------
// Components and helpers
// -----------------------------------------------------------------------------
import { Heading2 } from "themes/default/_typography";
import { StripeForm } from "components/Common/Form/StripeForm";
import { useParams } from "react-router";
import { useGetDomainGymQuery } from "store/api/api";

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
const AddPaymentMethodModal = ({
	visible,
	setVisible,
	paymentIntent,
	className,
	clientSecret,
	setupIntentId,
	subscribingToGym,
	currentCustomerId,
}) => {
	const STRIPE_KEY =
		process.env.REACT_APP_API_ENV === "production"
			? "pk_live_51IQxHwAxa8hukBopuq1F3ybNNjXJAQw3fy04ybBgQftHhoUqL0CvuJmhSxgli04gZydtJV4y96sEl5YcJ25RYlA400dnTrIuMY"
			: "pk_test_51IQxHwAxa8hukBopWs2y6ywCBtDvo3nMJeQ0S3kDO54ElR94jL8uLu4upadMnXUmfaCAbS58Hnl9cqn1sxdoG5Bm006xAvTajH";

	const stripePromise = loadStripe(STRIPE_KEY);
	const { gym } = useParams();
	const domainGymResponse = useGetDomainGymQuery(gym);

	return (
		<Modal
			className={cx(className, "sb-add-payment-method-modal")}
			title={
				subscribingToGym ? (
					<Heading2>Subscribe to {domainGymResponse?.data?.name}</Heading2>
				) : (
					<Heading2>Add Payment Method</Heading2>
				)
			}
			centered
			visible={visible}
			closable={false}
			onOk={() => setVisible(false)}
			onCancel={() => setVisible(false)}
			width={656}
			footer={null}
		>
			<Elements
				stripe={stripePromise}
				options={{
					clientSecret: paymentIntent ? paymentIntent.client_secret : clientSecret,
					appearance: {
						theme: "night",
						labels: "floating",
					},
				}}
			>
				<StripeForm
					origin="creator"
					buttonText="Start My Subscription"
					setupIntentId={setupIntentId}
					clientSecret={clientSecret}
					subscribingToGym={subscribingToGym}
					currentCustomerId={currentCustomerId}
				/>
			</Elements>
		</Modal>
	);
};

export default AddPaymentMethodModal;

