// -------------------------------------------------------------------------------
// Libraries
// -------------------------------------------------------------------------------
import React from "react";
import { Input, Form } from "antd";

// -------------------------------------------------------------------------------
// Components
// -------------------------------------------------------------------------------
import { Label2 } from "themes/default/_typography";

// -------------------------------------------------------------------------------
// Styles
// -------------------------------------------------------------------------------
import StyledFormTextArea from "./styles";

// -------------------------------------------------------------------------------
// Component
// -------------------------------------------------------------------------------
const { TextArea } = Input;

const FormTextArea = ({ name, label, rules, placeholder, rows }) => {
	return (
		<StyledFormTextArea className="sb-form-text-area">
			<Form.Item name={name} label={<Label2>{label}</Label2>} rules={rules}>
				<TextArea rows={rows} placeholder={placeholder} />
			</Form.Item>
		</StyledFormTextArea>
	);
};

export default FormTextArea;
