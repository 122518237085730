import styled from "styled-components";

const StyledEditWorkoutForm = styled.div`
	.sb-edit-workout-form {
		&__upload {
			margin: 32px 0;

			&__input {
				margin-bottom: 8px;
			}
		}

		&__form {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			gap: 8px;

			&__genres {
				width: 100%;

				&__title {
					margin-bottom: 24px;
				}
			}

			&__actions {
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 32px;
				margin-top: 50px;
			}
		}
	}
`;

export default StyledEditWorkoutForm;
