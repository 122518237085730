// -----------------------------------------------------------------------------
// Library
// -----------------------------------------------------------------------------
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Dropdown from "rc-dropdown";
import Menu, { Item as MenuItem } from "rc-menu";
import { SearchOutlined } from "@ant-design/icons";
import Input from "antd/lib/input";

// -----------------------------------------------------------------------------
// Components
// -----------------------------------------------------------------------------
import GymBanner from "../../../components/UI/GymBanner";
import WorkoutsList from "../../../components/Workouts/WorkoutsList";

// -----------------------------------------------------------------------------
// Actions and helpers
// -----------------------------------------------------------------------------
import { CategoriesFilter } from "components/Categories/CategoriesFilter";
import { updateMembersWorkoutsSearchTerm } from "store/slices";
import chevronDown from "../../../images/chevron-down.svg";
import { CalendarCard } from "components/Live/CalendarCard";
import { UpcomingClasses } from "components/Live/UpcomingClasses";
import { SignUpForLiveModal } from "components/Modals/SignUpForLiveModal";
import { JoinLiveStreamModal } from "components/Modals/JoinLiveStreamModal";

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
const Live = () => {
	const dispatch = useDispatch();
	const [signingUp, setSigningUp] = useState(false);
	const [viewingStream, setViewingStream] = useState(false);
	const [playbackId, setPlaybackId] = useState(null);

	return (
		<div className="sb-live-container">
			<SignUpForLiveModal visible={signingUp} setVisible={setSigningUp} />
			<JoinLiveStreamModal visible={viewingStream} setVisible={setViewingStream} playbackId={playbackId} />
			<GymBanner />
			<div className="sb-live-container__body">
				<CalendarCard joinStream={setViewingStream} setPlaybackId={setPlaybackId} />
				{/* <UpcomingClasses signingUp={signingUp} setSigningUp={setSigningUp} /> */}
			</div>
		</div>
	);
};

export default Live;

