// -----------------------------------------------------------------------------
// Library
// -----------------------------------------------------------------------------
import React, { useCallback, useState, useEffect } from "react";
import { Form } from "antd";
import { useSelector, useDispatch } from "react-redux";

// -----------------------------------------------------------------------------
// Store
// -----------------------------------------------------------------------------
import { selectAssetUploadId, selectCurrentUser, selectDomainGymId } from "store/selectors";
import { uploadAssetToS3, uploadAssetToMux, ScheduleLive } from "store/slices";

// -----------------------------------------------------------------------------
// Components
// -----------------------------------------------------------------------------
import { Button } from "components/Common/Button";
import { Paragraph2 } from "themes/default/_typography";
import { FormInput } from "components/Common/Form/FormInput";
import { FormTextArea } from "components/Common/Form/FormTextArea";
import { FormErrorTag } from "components/Common/Form/FormErrorTag";

// -----------------------------------------------------------------------------
// Utils and Styles
// -----------------------------------------------------------------------------
import { getVideoMetadata } from "helpers/generic";
import StyledScheduleLiveForm from "./styles";
import { useCreateWorkoutMutation, useGetDomainGymQuery, useGetGymWorkoutsQuery } from "store/api/api";
import { updateSelectedWorkout } from "store/slices";
import { useRecoilValue } from "recoil";
import { userAtom } from "globalAtoms";
import { useParams } from "react-router";

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
const ScheduleLiveForm = ({ nextStep }) => {
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const user = useRecoilValue(userAtom);
	const [workoutData, setWorkoutData] = useState(null);
	const [creatingWorkout, setCreatingWorkout] = useState(false);
	const currentUser = useSelector(selectCurrentUser);
	const [createWorkoutTrigger, createWorkoutResponse] = useCreateWorkoutMutation();
	const { gym } = useParams();
	const domainGymResponse = useGetDomainGymQuery(gym);
	const domainGymId = domainGymResponse.data?.id;
	const getWorkoutsResponse = useGetGymWorkoutsQuery(domainGymId, { skip: !domainGymResponse.data });

	useEffect(() => {
		if (createWorkoutResponse.data) {
			dispatch(updateSelectedWorkout({ ...createWorkoutResponse.data }));
			getWorkoutsResponse.refetch();
			form.resetFields();
			nextStep();
		}
	}, [createWorkoutResponse]);

	useEffect(() => {
		if (workoutData && creatingWorkout) {
			const { formData } = workoutData;
			const { workoutCoach, workoutDescription, workoutTitle } = formData;

			// Schedule Live
			createWorkoutTrigger({
				gym_id: domainGymId,
				user_id: user?.id,
				name: workoutTitle,
				coach: workoutCoach,
				description: workoutDescription,
				is_free: 0,
			});
		}
	}, [nextStep, domainGymId, creatingWorkout, currentUser, workoutData]);

	const onFinish = useCallback(
		async (formData) => {
			setWorkoutData({
				formData,
			});
			setCreatingWorkout(true);
		},
		[domainGymId]
	);

	return (
		<StyledScheduleLiveForm>
			<div className="sb-schedule-live-form">
				<Form onFinish={onFinish} layout="vertical" className="sb-schedule-live-form__form" form={form}>
					<FormInput
						name="workoutTitle"
						rules={[{ required: true, message: "This field is required" }]}
						label="Workout Title"
					/>
					<FormTextArea
						name="workoutDescription"
						label="Workout Description"
						rules={[{ required: true, message: "This field is required" }]}
						rows={4}
					/>
					<FormInput
						name="workoutCoach"
						rules={[{ required: true, message: "This field is required" }]}
						label="Workout Coach"
					/>
					<Button
						type="submit"
						onClick={onFinish}
						className="sb-schedule-live-form__form__button primary-filled"
						saveIcon
						uppercase
					>
						<Paragraph2>Next</Paragraph2>
					</Button>
				</Form>
			</div>
		</StyledScheduleLiveForm>
	);
};

export default ScheduleLiveForm;

