// -----------------------------------------------------------------------------
// Library
// -----------------------------------------------------------------------------
import React, { useEffect, useState } from "react";
import { Route, Redirect, redirect, Routes, Navigate, useParams } from "react-router-dom";

// -----------------------------------------------------------------------------
// Components and pages
// -----------------------------------------------------------------------------
import ResetPassword from "components/ResetPassword/ResetPassword";
import { MembershipSettings } from "pages/Dashboard/MembershipSettings";
import { PaymentSettings } from "pages/Dashboard/PaymentSettings";
import EarningsPage from "pages/Dashboard/Earnings";
import { ClientMembership } from "pages/ClientPortal/ClientMembership";
import { AccountSettings } from "pages/ClientPortal/AccountSettings";
import ViewWorkout from "pages/ClientPortal/ViewWorkout";
import MyGym from "pages/ClientPortal/MyGym";
import OnDemand from "pages/ClientPortal/OnDemand";
import Live from "pages/ClientPortal/Live";
import Profile from "pages/ClientPortal/Profile";
import Activity from "pages/ClientPortal/Activity";
import LandingPage from "pages/Landing";
import Home from "./Home";
import Workouts from "./Workouts";
import Members from "./Members";
import Analytics from "./Analytics";
import Settings from "./Settings";
import { PricingPlan } from "./PricingPlan";

// -----------------------------------------------------------------------------
// Utils
// -----------------------------------------------------------------------------
import { isUserCreator } from "../../constants/ui";
import NotFound from "pages/NotFound/NotFound";
import useLogout from "utils/useLogout";
import { useGetDomainGymQuery } from "store/api/api";

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
const SwitchRoute = ({ match, user }) => {
	let { gym } = useParams();
	const logoutHook = useLogout(null, gym);
	const { data: gymData } = useGetDomainGymQuery(gym, { skip: !gym });
	const [userType, setUserType] = useState(null);

	useEffect(() => {
		if (user && user.userAssociations && gym && gymData) {
			const associationsGymIds = user?.userAssociations?.map((a) => a.gym_id);
			const association = user?.userAssociations?.find((a) => a.gym_id === gymData?.id);
			setUserType(association?.type);
		}
	}, [user, gymData, gym]);

	if (!user) {
		/* return <LandingPage />; */
	} else {
		return (
			<div className="sb-switch-route">
				<Routes>
					<Route exact path={"/"} element={isUserCreator(userType) ? <Home /> : <OnDemand />} />

					{/* Creator Portal */}
					<>
						<Route path={`workouts`} element={<Workouts />} />
						<Route path={`members`} element={<Members />} />
						<Route path={`earnings`} element={<EarningsPage />} />
						<Route path={`membership-settings`} element={<MembershipSettings />} />
						{/* <Route path={`payment-settings`} element={<PaymentSettings />} /> */}
						<Route path={`pricing-plan`} element={<PricingPlan />} />
						<Route path={`analytics`} element={<Analytics />} />
						<Route path={`settings`} element={<Settings />} />
					</>
					{/* {isUserCreator(userType) && (
						
					)} */}

					{/* Consumer Portal */}
					<Route path={`view-workout/:workoutID`} element={<ViewWorkout />} />
					<Route path={`view-live-workout/:workoutID`} element={<ViewWorkout />} />

					<Route path={`on-demand`} element={<OnDemand />} />
					<Route path={`live`} element={<Live />} />
					<Route path={`profile`} element={<Profile />} />
					<Route path={`account-settings`} element={<AccountSettings />} />
					<Route path={`consumer-membership`} element={<ClientMembership />} />
					<Route path={`reset-password/:token`} element={<ResetPassword />} />
					<Route path={`my-gym`} element={<MyGym />} />
					<Route path={`activity`} element={<Activity />} />

					{/* Creator Portal */}
					<Route path={`workouts`} element={<Workouts />} />
					<Route path={`members`} element={<Members />} />
					<Route path={`earnings`} element={<EarningsPage />} />
					<Route path={`consumer`} element={<OnDemand />} />
					<Route path={`consumer/on-demand`} element={<OnDemand />} />
					<Route path={`consumer/live`} element={<Live />} />
					<Route path={`membership-settings`} element={<MembershipSettings />} />
					{/* <Route path={`payment-settings`} element={<PaymentSettings />} /> */}
					<Route path={`pricing-plan`} element={<PricingPlan />} />
					<Route path={`analytics`} element={<Analytics />} />
					<Route path={`settings`} element={<Settings />} />

					{/* Matches not found, then go back to default home route */}
					<Route path="*" element={<Navigate to="/" replace />} />
					{/* {!user && redirect("/")} */}
				</Routes>
			</div>
		);
	}
};

export default SwitchRoute;
