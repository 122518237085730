import styled from "styled-components";

const StyledPaymentIntentCard = styled.div`
	.sb-payment-intent-card {
		&__plan {
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
			gap: 12px;
			margin-bottom: 16px;

			&__left {
				height: 100%;
				display: flex;
				flex-direction: column;
				gap: 4px;
				align-items: center;
				justify-content: center;
				width: 245px;
				border-radius: 8px;
				background: #f4f4f6;
				&__icon {
					width: 48px;
					height: 48px;
					display: flex;
					align-items: center;
					justify-content: center;
					border-radius: 5px;
					background: #000000;
					margin-top: -8px;
					color: #ff865c;
				}
				&__title {
					font-size: 20px;
					font-weight: 700;
					color: #000000;
				}
				&__subtitle {
					font-size: 14px;
					font-weight: 400;
					color: #000000;
				}
				&__disclaimer {
					font-style: italic;
					font-size: 10px;
					font-weight: 400;
					color: #000000;
					padding-bottom: 16px;
				}
			}
		}
		&__inner {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: flex-start;
			height: 100%;
			border: 1px solid #f4f4f6;
			border-radius: 8px;

			&__icon {
				width: 72px;
				height: 72px;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 50%;
				background-color: ${({ theme }) => theme.colors.athensGray};
			}

			&__header {
				padding: 16px;
				padding-bottom: 4px;
				display: flex;
				flex-direction: row;
				align-items: center;
				width: 100%;
				height: 100%;
				justify-content: space-between;
				&__title {
					font-size: 20px;
					font-weight: 700;
					color: #000000;
				}

				&__divider {
					background: #e3e5e7;
					height: 1px;
					width: 90%;
					margin-bottom: 8px;
				}

				&__info {
					height: 100%;
					width: 100%;
					gap: 8px;
					flex-wrap: wrap;
					padding: 8px;
					display: flex;
					flex-direction: row;
					align-items: flex-start;
					justify-content: space-between;
					&__row {
						width: 45%;
						display: flex;
						flex-direction: row;
						align-items: flex-start;
						justify-content: flex-start;
						flex-wrap: wrap;
						&__title {
							font-size: 14px;
							font-weight: 400;
							color: #000000;
							display: flex;
							flex-direction: row;
							align-items: flex-start;
							justify-content: flex-start;
						}
					}
				}

				&__price {
					display: flex;
					flex-direction: row;
					align-items: center;
					font-size: 24px;
					font-weight: 700;
					color: #000000;
					&__sub {
						font-size: 14px;
						font-weight: 400;
						color: #000000;
					}
				}
			}

			&__column {
				display: flex;
				flex-direction: column;
				gap: 12px;
			}
		}
	}

	.ant-card {
		.ant-card-body {
			padding: 16px 24px;
		}
	}
`;

export default StyledPaymentIntentCard;

