import React from "react";

const ClockIcon = (params) => (
	<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g opacity="0.5">
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M6.99998 12.6C10.0928 12.6 12.6 10.0928 12.6 7C12.6 3.90721 10.0928 1.4 6.99998 1.4C3.90719 1.4 1.39998 3.90721 1.39998 7C1.39998 10.0928 3.90719 12.6 6.99998 12.6ZM7 0C8.85652 0 10.637 0.737498 11.9497 2.05025C13.2625 3.36301 14 5.14348 14 7C14 10.866 10.866 14 7 14C3.129 14 0 10.85 0 7C0 3.13401 3.13401 0 7 0ZM7.35001 3.5V7.175L10.5 9.044L9.97501 9.905L6.30001 7.7V3.5H7.35001Z"
				fill="black"
			/>
		</g>
	</svg>
);

export default ClockIcon;

