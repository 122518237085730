// -----------------------------------------------------------------------------
// Libraries
// -----------------------------------------------------------------------------
import React, { useCallback, useState, useEffect, useRef } from "react";
import Modal from "antd/lib/modal";
import axios from "axios";
import { message } from "antd";

// -----------------------------------------------------------------------------
// Store
// -----------------------------------------------------------------------------

// -----------------------------------------------------------------------------
// Components
// -----------------------------------------------------------------------------
import { ScheduleLiveForm } from "components/Forms/Workout/ScheduleLiveForm";
import { WorkoutDetailsForm } from "components/Forms/Workout/WorkoutDetailsForm";
import { WorkoutMusicForm } from "components/Forms/Workout/WorkoutMusicForm";
import { ReleaseScheduleForm } from "components/Forms/Workout/ReleaseScheduleForm";
import { ChevronLeftIcon, ChevronRightIcon, CloseIcon, LiveIcon } from "components/Icons";
import { Steps } from "components/Common/Steps";
import { Heading10, Paragraph1, Paragraph2 } from "themes/default/_typography";

// -----------------------------------------------------------------------------
// Styles and utils
// -----------------------------------------------------------------------------
import StyledGoLiveModal from "./styles";
import Demo from "pages/NotFound/Demo";
import { Button } from "components/Common/Button";
import {
	useEditScheduledStreamMutation,
	useEditWorkoutMutation,
	useGetDomainGymQuery,
	useGetScheduledStreamsQuery,
	useGetStreamKeysQuery,
	useSetStreamKeysMutation,
} from "store/api/api";
import { useSelector } from "react-redux";
import { selectSelectedWorkout } from "store/selectors";
import { MUXCreateLiveStream } from "constants/urls";
import { DividerHeader } from "components/Common/DividerHeader";
import { CopyOutlined } from "@ant-design/icons";
import { CopyIcon } from "components/Icons/CopyIcon";
import { useParams } from "react-router";

const CAMERA_CONSTRAINTS = {
	audio: true,
	video: true,
};

const getRecorderSettings = () => {
	const settings = {};
	if (MediaRecorder.isTypeSupported("video/mp4")) {
		settings.format = "mp4";
		settings.video = "h264";
		settings.audio = "aac";
	} else {
		settings.format = "webm";
		settings.audio = "opus";
		settings.video = MediaRecorder.isTypeSupported("video/webm;codecs=h264") ? "h264" : "vp8";
	}
	return settings;
};

const getRecorderMimeType = () => {
	const settings = getRecorderSettings();
	const codecs = settings.format === "webm" ? `;codecs="${settings.video}, ${settings.audio}"` : "";
	return `video/${settings.format}${codecs}`;
};

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
const GoLiveModal = ({ visible, setVisible, liveStream }) => {
	const [connected, setConnected] = useState(false);
	const [cameraEnabled, setCameraEnabled] = useState(false);
	const [textOverlay, setTextOverlay] = useState("Live from the browser!");
	const [FAQStep, setFAQStep] = useState(0);
	const { gym } = useParams();
	const domainGymResponse = useGetDomainGymQuery(gym);
	const domainGymId = domainGymResponse.data?.id;

	const { data: liveStreamKeysData, refetch: refetchStreamKeys } = useGetStreamKeysQuery(domainGymId, {
		skip: !domainGymResponse.data,
	});
	const [showFAQ, setShowFAQ] = useState(true);
	const [setStreamKeysTrigger, setStreamKeysResponse] = useSetStreamKeysMutation();
	const [editScheduledStreamTrigger, editScheduledStreamResponse] = useEditScheduledStreamMutation();
	const { refetch: refetchStreams } = useGetScheduledStreamsQuery(domainGymId, { skip: !domainGymResponse.data });
	const [messageApi, contextHolder] = message.useMessage();
	const success = () => {
		messageApi.open({
			type: "success",
			content: "Text copied to your clipboard!",
		});
	};

	const faqImages = [
		"https://s3.amazonaws.com/sweatbase.com/assets/FAQ/livestreamStep1.png",
		"https://s3.amazonaws.com/sweatbase.com/assets/FAQ/livestream2.png",
		"https://s3.amazonaws.com/sweatbase.com/assets/FAQ/livestream3.png",
		"https://s3.amazonaws.com/sweatbase.com/assets/FAQ/livestream4.png",
		"https://s3.amazonaws.com/sweatbase.com/assets/FAQ/livestream5.png",
		"https://s3.amazonaws.com/sweatbase.com/assets/FAQ/obs1.png",
		"https://s3.amazonaws.com/sweatbase.com/assets/FAQ/obsSettings.png",
		"https://s3.amazonaws.com/sweatbase.com/assets/FAQ/obsStreamTab.png",
		"https://s3.amazonaws.com/sweatbase.com/assets/FAQ/obsCustom.png",
		"https://s3.amazonaws.com/sweatbase.com/assets/FAQ/obsServerKey.png",
		"https://s3.amazonaws.com/sweatbase.com/assets/FAQ/obsStartStream.png",
	];

	const inputStreamRef = useRef();
	const videoRef = useRef();
	const canvasRef = useRef();
	const wsRef = useRef();
	const mediaRecorderRef = useRef();
	const requestAnimationRef = useRef();
	const nameRef = useRef();
	const [editWorkoutTrigger, editWorkoutResponse] = useEditWorkoutMutation();

	const selectedWorkout = useSelector(selectSelectedWorkout);

	useEffect(() => {
		nameRef.current = textOverlay;
	}, [textOverlay]);

	useEffect(() => {
		return () => {
			cancelAnimationFrame(requestAnimationRef.current);
		};
	}, []);

	const toggleFAQ = () => {
		setShowFAQ(!showFAQ);
	};

	const createNewStreamKey = async () => {
		// Make the lambda call here
		try {
			const createStreamUrl =
				process.env.REACT_APP_API_ENV === "production" ? MUXCreateLiveStream.prod : MUXCreateLiveStream.dev;
			const response = await axios.post(createStreamUrl);
			// Set Playback ID & livestream id as upload id to workout on database as well
			editWorkoutTrigger({
				workoutId: selectedWorkout.id,
				gym_id: domainGymId,
				playback_id: response.data.playback_ids[0].id,
				upload_id: response.data.id,
			});
			const createdStreamKeys = await setStreamKeysTrigger({
				gym_id: domainGymId,
				stream_key: response.data.stream_key,
				live_id: response.data.id,
			}).unwrap();
			refetchStreamKeys();
		} catch (error) {
			console.error("ERROR FETCHING STREAM: ", error);
		}
	};

	const handleCloseModal = useCallback(() => {
		setVisible(false);
	}, [setVisible]);

	const handleCopyText = (text) => {
		navigator.clipboard.writeText(text);
		success();
	};

	const handleStartStreaming = async () => {
		// Close The Modal and editScheduledStream's currently_streaming to true
		if (selectedWorkout?.upload_id) {
			//if the worout doesn't have an upload_id we need to update it
			editWorkoutTrigger({
				workoutId: selectedWorkout.id,
				gym_id: domainGymId,
				upload_id: liveStreamKeysData?.mux_stream_key,
			});
		}
		const scheduleLiveResponse = await editScheduledStreamTrigger({
			workoutId: liveStream.workout_id,
			gym_id: liveStream.gym_id,
			start_time: liveStream.start_time,
			time_zone: liveStream.time_zone,
			currently_streaming: 1,
		}).unwrap();
		handleCloseModal();
		refetchStreams();
	};

	return (
		<Modal
			width={656}
			closeIcon={<CloseIcon data-cy="close-go-live-modal" />}
			visible={visible}
			onCancel={handleCloseModal}
			footer={null}
		>
			<StyledGoLiveModal data-cy="go-live-modal" className="sb-go-live-modal">
				<div className="sb-go-live-modal__header">
					<Heading10 className="sb-go-live-modal__header__title">
						{liveStreamKeysData?.mux_stream_key ? "Start Streaming" : "First Time Streaming?"}
					</Heading10>
					{liveStreamKeysData?.mux_stream_key && (
						<Button onClick={handleStartStreaming} className="primary-filled--branding-primary-color">
							<Paragraph2 style={{ color: "white", fontWeight: "700" }}>GO LIVE</Paragraph2>
						</Button>
					)}
					{liveStreamKeysData?.mux_stream_key && (
						<Paragraph1> Enter the streaming settings below to get connected</Paragraph1>
					)}

					{!liveStreamKeysData?.mux_stream_key && (
						<Button onClick={createNewStreamKey} className="primary-filled--branding-primary-color">
							<Paragraph2 style={{ color: "white", fontWeight: "700" }}>CREATE NEW STREAM</Paragraph2>
						</Button>
					)}

					{liveStreamKeysData?.mux_stream_key && (
						<div style={{ width: "90%", marginBottom: "8px" }}>
							{contextHolder}
							<Paragraph1> BROADCAST SERVER</Paragraph1>
							<div
								style={{
									width: "100%",
									borderRadius: "100px",
									border: "1px solid #e3e5e7",
									display: "flex",
									alignItems: "center",
									justifyContent: "space-between",
								}}
							>
								<Paragraph1 style={{ padding: "16px", marginBottom: "0px", color: "black" }}>
									rtmp://global-live.mux.com:5222/app
								</Paragraph1>
								<div
									style={{ display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }}
									onClick={() => handleCopyText("rtmp://global-live.mux.com:5222/app")}
								>
									<CopyIcon />
									<Paragraph1 style={{ padding: "8px", paddingRight: "16px", marginBottom: "0px" }}>Copy</Paragraph1>
								</div>
							</div>
							<Paragraph1 style={{ paddingTop: "16px" }}> STREAM KEY</Paragraph1>
							<div
								style={{
									width: "100%",
									borderRadius: "100px",
									border: "1px solid #e3e5e7",
									display: "flex",
									alignItems: "center",
									justifyContent: "space-between",
								}}
							>
								<Paragraph1 style={{ padding: "16px", marginBottom: "0px", color: "black" }}>
									{liveStreamKeysData.mux_stream_key}
								</Paragraph1>
								<div
									style={{ display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }}
									onClick={() => handleCopyText(liveStreamKeysData.mux_stream_key)}
								>
									<CopyIcon />
									<Paragraph1 style={{ padding: "8px", paddingRight: "16px", marginBottom: "0px" }}>Copy</Paragraph1>
								</div>
							</div>
							<Paragraph1 style={{ paddingTop: "16px" }}> LIVE STREAM ID</Paragraph1>
							<div
								style={{
									width: "100%",
									borderRadius: "100px",
									border: "1px solid #e3e5e7",
									display: "flex",
									alignItems: "center",
									justifyContent: "space-between",
								}}
							>
								<Paragraph1 style={{ padding: "16px", marginBottom: "0px", color: "black" }}>
									{liveStreamKeysData.mux_live_id}
								</Paragraph1>
								<div
									style={{ display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }}
									onClick={() => handleCopyText(liveStreamKeysData.mux_live_id)}
								>
									<CopyIcon />
									<Paragraph1 style={{ padding: "8px", paddingRight: "16px", marginBottom: "0px" }}>Copy</Paragraph1>
								</div>
							</div>
						</div>
					)}
					<div
						style={{
							width: "90%",
							display: "flex",
						}}
					>
						<div
							style={{
								height: "1px",

								flexGrow: 1,
								backgroundColor: "#e3e5e7",

								marginTop: "8px",
								marginRight: "16px",
							}}
						></div>
						<div>
							<ChevronLeftIcon
								onClick={toggleFAQ}
								style={{
									transform: showFAQ ? "rotate(90deg)" : "rotate(180deg)",
									transition: "all .2s",
									cursor: "pointer",
								}}
							/>
						</div>
					</div>

					{showFAQ && (
						<div style={{ width: "90%" }}>
							<p style={{ fontWeight: 400, fontSize: "14px", color: "#4F5661", marginBottom: "16px" }}>
								Begin your streaming journey by following our guide on how to go live.
							</p>
							<DividerHeader text="Step 1 - Download OBS" uppercase style={{ marginBottom: "16px" }} />
							<Paragraph1>
								For free live streaming software, we highly recommend Open Broadcaster Software (OBS) which can be
								downloaded for free from{" "}
								<a
									href="https://obsproject.com/"
									target="_blank"
									rel="noopen norefferer"
									style={{ color: "#FF865c", marginBottom: "0px", textDecoration: "underline" }}
								>
									https://obsproject.com
								</a>{" "}
							</Paragraph1>
							<Paragraph1>
								And following their initial{" "}
								<a
									href="https://obsproject.com/wiki/OBS-Studio-Quickstart"
									target="_blank"
									rel="noopen norefferer"
									style={{ color: "#FF865c", marginBottom: "0px", textDecoration: "underline" }}
								>
									quick start guide
								</a>{" "}
								to get setup.
							</Paragraph1>
							<div style={{ justifyContent: "center", display: "flex", marginBottom: "24px", width: "100%" }}>
								<img src={faqImages[0]} style={{ width: "100%" }} />
							</div>
							<DividerHeader text="Step 2 - Find Stream Settings" uppercase style={{ marginBottom: "16px" }} />
							<p style={{ fontWeight: 400, fontSize: "14px", color: "#4F5661", marginBottom: "0px" }}>
								1. Click <b style={{ color: "black" }}>"Settings"</b> on the bottom right hand corner{" "}
							</p>
							<p style={{ fontWeight: 400, fontSize: "14px", color: "#4F5661" }}>
								2. From settings window click on <b style={{ color: "black" }}>"Stream"</b> located on the sidebar
							</p>
							<div
								style={{ justifyContent: "center", display: "flex", marginBottom: "24px", gap: "8px", width: "100%" }}
							>
								<img src={faqImages[1]} style={{ width: "100%" }} />
								<img src={faqImages[2]} style={{ width: "100%" }} />
							</div>
							<DividerHeader text="Step 3 - Enter Server + Stream Key URL" uppercase style={{ marginBottom: "16px" }} />
							<p style={{ fontWeight: 400, fontSize: "14px", color: "#4F5661", marginBottom: "0px" }}>
								1. Change the service to <b style={{ color: "black" }}>"Custom..."</b>
							</p>
							<p style={{ fontWeight: 400, fontSize: "14px", color: "#4F5661", marginBottom: "0px" }}>
								2. Enter the following Server <b style={{ color: "black" }}>"rtmp://global-live.mux.com:5222/app"</b>{" "}
							</p>
							<p style={{ fontWeight: 400, fontSize: "14px", color: "#4F5661", marginBottom: "0px" }}>
								3. Enter the following Stream Key{" "}
								<b style={{ color: "black" }}>"{liveStreamKeysData?.mux_stream_key}"</b>{" "}
							</p>
							<p style={{ fontWeight: 400, fontSize: "14px", color: "#4F5661" }}>
								4. To save changes press <b style={{ color: "black" }}>Apply</b>{" "}
							</p>
							<div
								style={{
									justifyContent: "center",
									display: "flex",
									marginBottom: "24px",
									width: "100%",
								}}
							>
								<img src={faqImages[3]} style={{ width: "100%" }} />
							</div>
							<DividerHeader text="Step 4 - start streaming!" uppercase style={{ marginBottom: "16px" }} />
							<p style={{ fontWeight: 400, fontSize: "14px", color: "#4F5661" }}>
								Click <b style={{ color: "black" }}>"Start Streaming"</b> when you're ready!
							</p>
							<div
								style={{
									justifyContent: "center",
									display: "flex",
									marginBottom: "24px",
									width: "100%",
								}}
							>
								<img src={faqImages[4]} style={{ width: "100%" }} />
							</div>
							<div style={{ display: "flex", justifyContent: "center" }}>
								<Button
									className="primary-outlined--delete"
									uppercase
									style={{ width: "100%", backgroundColor: "white" }}
									onClick={handleCloseModal}
								>
									<Paragraph2>Close</Paragraph2>
								</Button>
							</div>
						</div>
					)}
				</div>
			</StyledGoLiveModal>
		</Modal>
	);
};

export default GoLiveModal;

// additional livestreaming resources

/* const enableCamera = async () => {
		inputStreamRef.current = await navigator.mediaDevices.getUserMedia(CAMERA_CONSTRAINTS);

		videoRef.current.srcObject = inputStreamRef.current;

		await videoRef.current.play();

		// We need to set the canvas height/width to match the video element.
		canvasRef.current.height = videoRef.current.clientHeight;
		canvasRef.current.width = videoRef.current.clientWidth;

		requestAnimationRef.current = requestAnimationFrame(updateCanvas);

		setCameraEnabled(true);
	};
 */

/* const updateCanvas = () => {
		if (videoRef.current.ended || videoRef.current.paused) {
			return;
		}

		const ctx = canvasRef.current.getContext("2d");

		ctx.drawImage(videoRef.current, 0, 0, videoRef.current.clientWidth, videoRef.current.clientHeight);

		ctx.fillStyle = "#FB3C4E";
		ctx.font = "50px Akkurat";
		const date = new Date();
		const dateText = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}.${date
			.getMilliseconds()
			.toString()
			.padStart(3, "0")}`;
		ctx.fillText(`${nameRef.current}${dateText}`, 10, 50, canvasRef.current.width - 20);

		requestAnimationRef.current = requestAnimationFrame(updateCanvas);
	}; */

/* const stopStreaming = () => {
		if (mediaRecorderRef.current.state === "recording") {
			mediaRecorderRef.current.stop();
		}

		setStreaming(false);
	}; */

/* const startStreaming = () => {
		setStreaming(true);
		const settings = getRecorderSettings();
		const protocol = window.location.protocol.replace("http", "ws");
		const wsUrl = new URL(`${protocol}//${window.location.host}/rtmp/`);
		wsUrl.searchParams.set("video", settings.video);
		wsUrl.searchParams.set("audio", settings.audio);
		if (streamUrl) {
			wsUrl.searchParams.set("url", streamUrl);
		} else {
			wsUrl.searchParams.set("url", "rtmp://global-live.mux.com:5222/app");
		}
		if (streamKey) {
			wsUrl.searchParams.set("key", streamKey);
		}
		wsRef.current = new WebSocket(wsUrl);

		wsRef.current.addEventListener("open", function open() {
			setConnected(true);
		});

		wsRef.current.addEventListener("close", () => {
			setConnected(false);
			stopStreaming();
		});

		const videoOutputStream = canvasRef.current.captureStream(30); // 30 FPS

		// Let's do some extra work to get audio to join the party.
		// https://hacks.mozilla.org/2016/04/record-almost-everything-in-the-browser-with-mediarecorder/
		const audioStream = new MediaStream();
		const audioTracks = inputStreamRef.current.getAudioTracks();
		audioTracks.forEach(function (track) {
			audioStream.addTrack(track);
		});

		const outputStream = new MediaStream();
		[audioStream, videoOutputStream].forEach(function (s) {
			s.getTracks().forEach(function (t) {
				outputStream.addTrack(t);
			});
		});

		mediaRecorderRef.current = new MediaRecorder(outputStream, {
			mimeType: getRecorderMimeType(),
			videoBitsPerSecond: 3000000,
			audioBitsPerSecond: 64000,
		});

		mediaRecorderRef.current.addEventListener("dataavailable", (e) => {
			wsRef.current.send(e.data);
		});

		mediaRecorderRef.current.addEventListener("stop", () => {
			stopStreaming();
			wsRef.current.close();
		});

		mediaRecorderRef.current.start(1000);
	}; */

/* {cameraEnabled &&
						(streaming ? (
							<div>
								<span className={"z"}>{connected ? "Connected" : "Disconnected"}</span>
								<input
									placeholder="Text Overlay"
									type="text"
									value={textOverlay}
									onChange={(e) => setTextOverlay(e.target.value)}
								/>
								<Button
									onClick={stopStreaming}
									className="sb-schedule-live-form__form__button primary-filled"
									uppercase
								>
									<Paragraph2>Stop Streaming</Paragraph2>
								</Button>
							</div>
						) : (
							<>
								<Button
									onClick={startStreaming}
									className="sb-schedule-live-form__form__button primary-filled"
									uppercase
								>
									<Paragraph2>Start Streaming</Paragraph2>
								</Button>
							</>
						))} */

/* {!cameraEnabled && streamKey && (
						<Button onClick={enableCamera} className="sb-schedule-live-form__form__button primary-filled" uppercase>
							<Paragraph2>Enable Camera</Paragraph2>
						</Button>
					)} */

// Show FAQ
/* {showFAQ && (
						<div>
							{renderFaqStepText()}
							<div style={{ justifyContent: "center", alignItems: "center", display: "flex" }}>
								<img src={faqImages[FAQStep]} style={{ maxWidth: "100%" }} />
							</div>
							<div
								style={{
									justifyContent: "space-around",
									alignItems: "center",
									display: "flex",
									padding: "16px",
									gap: "16px",
								}}
							>
								<Steps
									current={FAQStep}
									className="sb-schedule-live-modal__header__steps"
									steps={[
										{ value: "", active: FAQStep === 0 },
										{ value: "", active: FAQStep === 1 },
										{ value: "", active: FAQStep === 2 },
										{ value: "", active: FAQStep === 3 },
										{ value: "", active: FAQStep === 4 },
										{ value: "", active: FAQStep === 5 },
									]}
								/>
							</div>
							<div
								style={{
									justifyContent: "space-around",
									alignItems: "center",
									display: "flex",
									padding: "16px",
									gap: "16px",
								}}
							>
								<Button
									onClick={() => {
										if (FAQStep > 0) {
											setFAQStep(FAQStep - 1);
										}
									}}
									className="sb-schedule-live-form__form__button primary-filled"
									uppercase
								>
									<Paragraph2>Previous</Paragraph2>
								</Button>
								<Button
									onClick={() => {
										if (FAQStep < 5) {
											setFAQStep(FAQStep + 1);
										}
									}}
									className="sb-schedule-live-form__form__button primary-filled"
									uppercase
								>
									<Paragraph2>Next</Paragraph2>
								</Button>
							</div>
						</div>
					)} */

// VIDEO AND CANVAS
/* <div className={"d"}>
						<video ref={videoRef} muted playsInline></video>
					</div>
					<div className={"e"}>
						<canvas ref={canvasRef}></canvas>
					</div> */

