import styled from "styled-components";

const StyledChangeIndicator = styled.div`
	.sb-change-indicator {
		&__inner {
			height: 26px;
			border-radius: 100px;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			padding: 0 10px;
			color: ${({ theme }) => theme.colors.white};
			background: ${({ theme }) => theme.colors.burntSienna};

			&__text {
				margin-right: 6px;
			}

			&--possitive {
				background: ${({ theme }) => theme.colors.jungleGreen};
			}
		}
	}
`;

export default StyledChangeIndicator;
