// -----------------------------------------------------------------------------
// Library
// -----------------------------------------------------------------------------
import React, { useCallback, useState, useEffect } from "react";
import { Form } from "antd";
import { useSelector, useDispatch } from "react-redux";

// -----------------------------------------------------------------------------
// Store
// -----------------------------------------------------------------------------
import { selectAssetUploadId, selectCurrentUser, selectDomainGymId, selectSelectedWorkout } from "store/selectors";
import { uploadAssetToS3, uploadAssetToMux, ReleaseSchedule } from "store/slices";

// -----------------------------------------------------------------------------
// Components
// -----------------------------------------------------------------------------
import { Button } from "components/Common/Button";
import { Paragraph2 } from "themes/default/_typography";
import { FormInput } from "components/Common/Form/FormInput";
import { FormTextArea } from "components/Common/Form/FormTextArea";
import { FormErrorTag } from "components/Common/Form/FormErrorTag";
import { LiveIcon } from "components/Icons";

// -----------------------------------------------------------------------------
// Utils and Styles
// -----------------------------------------------------------------------------
import { getVideoMetadata } from "helpers/generic";
import StyledReleaseScheduleForm from "./styles";
import { FormSelect } from "components/Common/Form/FormSelect";
import { FormTimeSelect } from "components/Common/Form/FormTimeSelect";
import { FormDateSelect } from "components/Common/Form/FormDateSelect";
import {
	useEditWorkoutMutation,
	useGetDomainGymQuery,
	useGetGymWorkoutsQuery,
	useGetScheduledStreamsQuery,
	useScheduleWorkoutMutation,
} from "store/api/api";
import moment from "moment-timezone";
import { useParams } from "react-router";

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
const ReleaseScheduleForm = ({ nextStep }) => {
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const [workoutData, setWorkoutData] = useState(null);
	const [creatingWorkout, setCreatingWorkout] = useState(false);
	const currentUser = useSelector(selectCurrentUser);
	const [editWorkoutTrigger, editWorkoutResponse] = useEditWorkoutMutation();
	const [scheduleLiveTrigger, scheduleLiveResponse] = useScheduleWorkoutMutation();

	const selectedWorkout = useSelector(selectSelectedWorkout);
	const { gym } = useParams();
	const domainGymResponse = useGetDomainGymQuery(gym);
	const domainGymId = domainGymResponse.data?.id;
	const getWorkoutsResponse = useGetGymWorkoutsQuery(domainGymId, { skip: !domainGymResponse.data });
	const { refetch: refetchStreams } = useGetScheduledStreamsQuery(domainGymId, { skip: !domainGymResponse.data });

	useEffect(() => {
		if (editWorkoutResponse.data) {
			getWorkoutsResponse.refetch();
			refetchStreams();
			form.resetFields();
			nextStep();
		}
	}, [dispatch, nextStep, editWorkoutResponse]);

	const onFinish = async (values) => {
		// get ant design form fields
		// format date fields
		const { workoutDate, workoutTime, workoutTimezone } = values;
		if (!workoutDate || !workoutTime || !workoutTimezone) {
			console.debug("missing fields");
			return;
		}
		console.debug("workoutTime: ", workoutTime);
		const day = moment(workoutDate).startOf("day").format("MM/DD/YYYY");
		const time = moment(workoutTime).format("HH:mm");
		console.debug("time: ", time);
		const dayTime = moment(day + " " + time, "MM/DD/YYYY HH:mm");
		const iso = dayTime.format("YYYY-MM-DDTHH:mm:ss");
		const scheduleLiveResponse = await scheduleLiveTrigger({
			workoutId: selectedWorkout.id,
			gym_id: domainGymId,
			start_time: iso,
			timezone: workoutTimezone,
			currently_streaming: false,
		}).unwrap();
		const resp = await editWorkoutTrigger({
			workoutId: selectedWorkout.id,
			gym_id: domainGymId,
			start_time: iso,
		}).unwrap();
	};

	return (
		<StyledReleaseScheduleForm>
			<div className="sb-release-schedule-form">
				<Form layout="vertical" className="sb-release-schedule-form__form" onFinish={onFinish} form={form}>
					<FormDateSelect
						name="workoutDate"
						rules={[{ required: true, message: "This field is required" }]}
						label="Workout Date"
					/>
					<div className="sb-release-form-input-group">
						<FormTimeSelect
							name="workoutTime"
							rules={[{ required: true, message: "This field is required" }]}
							label="Time"
						/>{" "}
						<FormSelect
							name="workoutTimezone"
							placeholder="PST"
							rules={[{ required: true, message: "This field is required" }]}
							label="Timezone"
							options={[
								{ value: "America/New_York", label: "EST (Eastern)" },
								{ value: "America/Los_Angeles", label: "PST (Pacific)" },
								{ value: "America/Chicago", label: "CST (Central)" },
							]}
						/>
					</div>

					<div className="sb-release-schedule-form__notice">
						<LiveIcon color="#FF865C" />
						<Paragraph2>
							Live workouts require you to log in and start the workout at the selected time and date.
						</Paragraph2>
					</div>
					<div className="sb-release-schedule-form__actions">
						<Button className="sb-release-schedule-form__form__button secondary-filled" chevronLeft uppercase>
							<Paragraph2>Back</Paragraph2>
						</Button>
						<Button
							type="submit"
							className="sb-release-schedule-form__form__button primary-filled"
							chevronRight
							uppercase
						>
							<Paragraph2>Schedule</Paragraph2>
						</Button>
					</div>
				</Form>
			</div>
		</StyledReleaseScheduleForm>
	);
};

export default ReleaseScheduleForm;

