// -----------------------------------------------------------------------------
// Library
// -----------------------------------------------------------------------------
import React, { useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import { Modal, Input } from "antd";
import notification from "antd/lib/notification";

// -----------------------------------------------------------------------------
// Components and helpers
// -----------------------------------------------------------------------------
import InputField from "components/UI/Form/InputField";
import Label from "components/UI/Form/label";
import TwoFaForm from "./TwoFaForm";
import { validateEmailOrPhone, verifyPassword } from "helpers/Form/commonFormValidations";
import { isValidPhoneNumber } from "../../helpers/Form/commonFormValidations";
import { Paragraph9 } from "themes/default/_typography";
import { Button } from "components/Common/Button";
import { useNavigate, useParams } from "react-router";
import { useForgotPasswordMutation, useGetDomainGymQuery, useLoginMutation } from "store/api/api";
import { getClientHost } from "helpers/constants";
import { useSetRecoilState } from "recoil";
import { userAtom } from "globalAtoms";
import { getUser } from "useInit";

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
const LoginForm = ({ preview, gymId }) => {
	const navigate = useNavigate();
	const { gym } = useParams();
	const [loggingIn, updateLoggingIn] = useState(false);
	const [email, setEmail] = useState("");
	const [displayModal, setDisplayModal] = useState(false);
	const [potentialUser, updatePotentialUser] = useState(null);
	//TDO should be gym domain
	const { data: domainGymData, isLoading: domainGymisLoading, error: domainGymError } = useGetDomainGymQuery(gym);
	const [resetPasswordTrigger, resetPasswordResponse] = useForgotPasswordMutation();
	const [loginTrigger, loginResponse] = useLoginMutation();
	const setUser = useSetRecoilState(userAtom);

	const handleFormSubmit = async (formObj) => {
		updateLoggingIn(true);
		const keyName = isValidPhoneNumber(formObj.email) ? "phone_number" : "email_address";
		loginTrigger({
			[keyName]: formObj.email,
			password: formObj.password,
			domain: domainGymData.subdomain,
		});
	};

	const handleNav = async () => {
		await getUser(setUser);
		navigate(`/../../${domainGymData?.subdomain}/dashboard/`);
	};

	useEffect(() => {
		if (loginResponse.isSuccess) {
			const { user, skip_2fa, auth_token } = loginResponse.data;
			if (user) {
				updatePotentialUser({ ...user, skip2fa: skip_2fa });
				if (skip_2fa) {
					localStorage.setItem("creator_jwt_token", auth_token);
					notification.success({
						message: "Login successful!",
					});
					handleNav();
				}
			}
		} else if (loginResponse.isError) {
			notification.error({
				message: "Incorrect email/phone or password entered",
			});
		}
	}, [loginResponse]);

	const onKeyUp = (e) => {
		if (e.key === "Enter") {
			return handleFormSubmit();
		}
	};

	const handleForgotPassword = () => {
		setDisplayModal(true);
	};

	const handleSendPassword = async () => {
		try {
			const domain = getClientHost(process.env.REACT_APP_API_ENV);
			await resetPasswordTrigger({ email, domain }).unwrap();
		} catch (error) {
			console.log("could not reset password", error);
		}
	};

	useEffect(() => {
		if (resetPasswordResponse.isSuccess) {
			notification.success({
				message: "Password reset email sent",
			});
			setDisplayModal(false);
		} else if (resetPasswordResponse.isError) {
			notification.error({
				message: "Sorry, we were unable to reset your password at this moment. Please try again later.",
			});
		}
	}, [resetPasswordResponse]);

	return (
		<div className="sb-login-form">
			{potentialUser ? (
				<TwoFaForm
					updateLoggingIn={updateLoggingIn}
					updatePotentialUser={updatePotentialUser}
					potentialUser={potentialUser}
					gymId={gymId}
				/>
			) : (
				<Form onSubmit={handleFormSubmit}>
					{({ handleSubmit }) => {
						return (
							<form id="login" onSubmit={handleSubmit}>
								<Label className="sb-login-form__forgot">Phone Number Or Email Address</Label>
								<Field component="input" name="email" validate={validateEmailOrPhone}>
									{({ input, meta }) => (
										<InputField
											onKeyUp={onKeyUp}
											disabled={preview}
											type="text"
											input={input}
											meta={meta}
											data-cy="sb-login-email-field"
										/>
									)}
								</Field>
								<Label>Password</Label>
								<Field component="input" name="password" validate={verifyPassword}>
									{({ input, meta }) => (
										<InputField
											onKeyUp={onKeyUp}
											disabled={preview}
											type="password"
											input={input}
											meta={meta}
											data-cy="sb-login-password-field"
										/>
									)}
								</Field>
								<label
									className="sb-login-form__forgot"
									data-cy="sb-login-forgot-password"
									onClick={() => handleForgotPassword()}
								>
									Forgot Password?
								</label>
								<div className="sb-login-form__buttons">
									<Button
										disabled={loginResponse.isLoading}
										loading={loginResponse.isLoading}
										className="primary-filled"
										data-cy="sb-login-button"
										uppercase
										type="submit"
									>
										<Paragraph9>{loggingIn ? "Logging In" : "Login"}</Paragraph9>
									</Button>
									<Button
										disabled={loginResponse.isLoading}
										loading={loginResponse.isLoading}
										data-cy="login-page-sign-up-button"
										className="primary-filled--branding-primary-color"
										uppercase
										type="button"
										onClick={() => navigate(`../signup/${domainGymData?.subdomain}`)}
										style={{ backgroundColor: domainGymData?.brand_color1 !== "" ? domainGymData?.brand_color1 : null }}
									>
										<Paragraph9>Sign Up</Paragraph9>
									</Button>
								</div>
							</form>
						);
					}}
				</Form>
			)}
			<Modal
				title="Forgot Password"
				style={{ display: "flex", justifyContent: "center" }}
				visible={displayModal}
				onCancel={() => setDisplayModal(false)}
				width={500}
				footer={null}
			>
				<div
					style={{
						display: "flex",
						justifyContent: "space-between",
						flexDirection: "column",
						alignItems: "center",
						width: "35em",
						gap: "1em",
					}}
				>
					<Input
						data-cy="sb-forgot-password-email-input"
						placeholder="Email"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
					/>
					<Button
						data-cy="sb-forgot-password-send-button"
						className="primary-filled"
						key="Send"
						onClick={() => handleSendPassword()}
					>
						Send
					</Button>
				</div>
			</Modal>
		</div>
	);
};

export default LoginForm;
