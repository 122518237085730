import { useNavigate } from "react-router";
import { userAtom } from "../globalAtoms";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { isUserCreator } from "constants/ui";

function useLogout(history, gym) {
	const navigate = useNavigate();
	const user = useRecoilValue(userAtom);
	const setUser = useSetRecoilState(userAtom);

	const logout = async () => {
		const trainer = isUserCreator(user.user_type);

		await setUser(null);
		localStorage.removeItem("creator_jwt_token");
		window.location.href = `/login/${gym}`;

		//await setUser(null);
		//localStorage.removeItem("creator_jwt_token");

		//if (history) {
		//	navigate(`/login/${user?.gym?.subdomain}`);
		//}
		//window.location.href = `/login/${user?.gym?.id}`;
	};

	return {
		logout,
	};
}

export default useLogout;
