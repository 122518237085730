import styled from "styled-components";

const StyledEarningsTable = styled.div`
	.sb-earnings-table {
		&__card {
			width: 100%;
			height: 455px;
			border-radius: 10px;
			padding: 40px 36px 24px 36px;
			background-color: ${({ theme }) => theme.colors.white};
			thead {
				border-radius: 16px;
				th {
					text-align: left;
					padding: 8px;
					background-color: transparent;
					border-bottom: 2px solid ${({ theme }) => theme.colors.trout};
				}
			}
			td {
				padding: 8px;
			}
			tr:nth-child(even) {
				background-color: ${({ theme }) => theme.colors.iron};
			}
		}
	}
`;

export default StyledEarningsTable;

